<div class="container">
  <h2><font color="secondary">{{ "GENERAL.UPLOAD_PHOTOS" | translate }}</font></h2>
  <hr>
  <span style="width: 100%; word-break: break-word;">
    <b><p style="color: red;">We accept the following formats for images: JPG, JPEG, PNG, GIF, TIF, SVG</p></b>
  </span>
      <table width=98%>
        <tr>
          <td class="drag-section" valign=top>
            <div class="uploadfilecontainer" *ngIf='!uploading' (click)="fileInput.click()" appDragDrop (onFileDropped)="dragAndDropFile($event)">
                <label class="heading-label">Drag and drop or Upload Photo Here</label>
                <input hidden multiple type="file" accept="image/*,.HEIC" #fileInput (change)="uploadInputFile($event.target.files)">
            </div>
            <div *ngIf="uploading">
              <div *ngFor="let file of files;let i=index" class="progress-section">
                <mat-progress-bar
                  color="primary"
                  [mode]="determinate"
                  [value]="progressInfo[file.name]"
                  [bufferValue]="bufferValue">
                </mat-progress-bar>
                <span class="progressText">{{ file.name }} -- {{progressInfo[file.name]}}%</span>
              </div>
              <div *ngIf='!complete'>
                <mat-spinner diameter='20'></mat-spinner>
                <span><font size=+1 color=green>{{uploadMessage}}</font></span> 
              </div>
            </div>
          </td>
          <td width="40%" valign=top>
            <mat-dialog-content class="files-section">
              <table width=99%  class="files-list" *ngFor="let file of files;let i=index">
                <tr>
                  <td>{{ file.name }}</td>
                  <td width=30><button class="delete-file" (click)="deleteAttachment(i)">
                    <img src="/assets/images/Recycle_Bin_Full.png">
                </button></td>
                </tr>
              </table>
            </mat-dialog-content>
          </td>
      </tr>
      <tr>
        <tr style="text-align: center;">
            <td>
                <div class="uploadbtn">
                        <button type="button" [disabled]="files.length == 0 || uploading" mat-raised-button color="accent" (click)="uploadPhoto()">
                          Upload
                        </button>
                </div>
            </td>
            <td>
              <div class="uploadbtn">
              <button mat-raised-button (click)="closeme()" color="warn" type="button">Cancel</button>
             </div>
            </td>
        </tr>
    </table>
</div>
