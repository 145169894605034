import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { DataService, RPErrorHandler } from '@app/services/dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService} from "../../core/services";
import { environment } from '@environments/environment';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-edapprovalview',
  templateUrl: './approvalview.component.html',
  styleUrls: ['./approvalview.component.scss']
})
export class EDApprovalViewComponent implements OnInit {
  form:FormGroup;
  acknowledge=false;
  submitnow=false;
  firstName="";
  casedata:any={status:"prescanning-approved"};
  inviteurl="";
  final=false;
  reasons=[];
  submitted=false;
  constructor(private route: ActivatedRoute,private router:Router,private service:DataService, public dialogRef: MatDialogRef<EDApprovalViewComponent>, private session: SessionService){

    this.service.edformdata.subscribe(data=>{
       this.casedata=data;
       console.log(this.casedata);
     });
     
  }
  ngOnInit(): void {
    console.log(this.casedata);
    const emailaddress=this.casedata.emailId;
    this.initForm(emailaddress);
    this.getFirstName();
    if(this.casedata.denialReason){
      this.reasons=eval(this.casedata.denialReason);
      this.buildReasons();
      console.log(this.reasons);
    } else{
      // fbq('track', 'Lead');
      // const str_appType = this.session.get('rp_AppType');
      // if (str_appType == "GC") {
      //   gtag('event', 'conversion', {'send_to': 'AW-948883577/16uxCIrIrdICEPmgu8QD'});
      // }      
    }
    this.inviteurl=this.casedata.invitationUrl;
    if (this.casedata.status=='prescanning-approved') {
      this.submitNow();
    }
  }

  buildReasons(){
    if(this.reasons && this.reasons.length>0){
      this.reasons.forEach(ele=>{
        ele.questionId=this.getQuestionID(ele.Key);
      });
    }
  }

  getQuestionID(key){
    key=key.toLowerCase();
    let question="";
    if(key=='birthdate'){question=question+" Question 7"; }
    else if (key=='height_feet'){question=question+" Question 10";}
    else if (key=='bmi'){question=question+" BMI";}
    else if (key=='highest_level_education'){question=question+" Question 15";}
    else if (key=='pregnant'){ question=question+" Question 26"; }
    else if (key=='breastfeeding'){ question=question+" Question 27"; }
    else if (key=='felony'){question=question+" Question 29"; }
    // else if (key=='used'){ question=question+" Question 24";}
    // else if (key=='tobacco'){question=question+" Question 23"; }
    else if (key=='agree'){question=question+" Agreement";  }
    // else if (key=='ovaries'){question=question+" Question 19"; }
    // else if (key=='parentage'){question=question+" Question 18"; }
    return question;
  }
  initForm(emailid){
    this.form = new FormGroup ({
      email: new FormControl(emailid, [Validators.required, Validators.email])
    });
  }

  submitNow(){
    this.service.submitDonorForm2(this.casedata.caseId).subscribe(resp=>{
        console.log(resp);
    });
    this.submitnow=true;
  }

  sendInvite(){
    const caseId=this.casedata.caseId;
    const elemail=this.form.get("email").value;
    this.submitted=true;
    this.service.sendEDInvite(caseId,elemail).subscribe(res=>{
        console.log(res);
        this.acknowledge=true;
        this.inviteurl=(<any>res).data.invitationUrl;
    });

    if (document.getElementById('rp-fbfbq-id')) {       
      fbq('track', 'CompleteRegistration');
    }

    const str_appType = this.session.get('rp_AppType');
    if (str_appType == "GC") {
      if (document.getElementById('rp-gadsgtag-id')) { 
        gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_THANK_YOU_EVENT});
      }
    }
  }

  getFirstName(){
    if(this.casedata.pages && this.casedata.pages[0].questions)
    {
      this.casedata.pages[0].questions.forEach(qq=>{
        if(qq.questionControlId=="firstName"){
          this.firstName=qq.answer;
        }
      });
    }
  }

  continueForm2(){
    const caseId=this.casedata.caseId;
    this.service.donorShareMore(caseId).subscribe((res:any)=>{
      console.log(res);
      this.inviteurl=res.data.invitationUrl;
      console.log(this.inviteurl);
      window.open(this.inviteurl, "_self");
    })
  
  }

  onClose(): void {
    self.close();
  }

  onReview(): void {
    this.dialogRef.close();
  }

  finalizeDeny(){
    const caseId=this.casedata.caseId;
    this.service.finalizedDonorDeny(caseId).subscribe(resp=>{
      console.log(resp);
      this.final=true;
    })
    /*
    setTimeout(() => {
      this.final=true;
    }, (300));
    */
  }
  // not for deny cases.
  finalize(){
    const caseId=this.casedata.caseId;
    const elemail=this.casedata.emailId;
    this.service.sendEDInvite(caseId,elemail).subscribe(res=>{
      console.log(res);
    //  this.acknowledge=true;
    //  this.inviteurl=(<any>res).data.invitationUrl;
    //console.log("sending deny email");
  });
    setTimeout(() => {
      this.final=true;
    }, (500));

    if (document.getElementById('rp-fbfbq-id')) {  
        fbq('track', 'Lead');
    }
    const str_appType = this.session.get('rp_AppType');
    if (str_appType == "GC") {
      if (document.getElementById('rp-gadsgtag-id')) { 
          gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});
      }
    }      
  }
}