import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthService } from '@app/core/services';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class DataService {

    private edFormDataSubject: BehaviorSubject<any>;
    public edformdata: Observable<any>;
    private gcFormDataSubject: BehaviorSubject<any>;
    public gcformdata: Observable<any>;
    constructor(
        private router: Router,
        private http: HttpClient,
        public authservice:AuthService
    ) {
        //this.initFormControl();
        this.edFormDataSubject = new BehaviorSubject<any>({status:"empty"});
        this.edformdata=this.edFormDataSubject.asObservable();
        this.gcFormDataSubject = new BehaviorSubject<any>({status:"empty"});
        this.gcformdata=this.gcFormDataSubject.asObservable();
    }

    public get edFormValue(): any{
        return this.edFormDataSubject.value;
    }

    public pushEDformvalue(nextvalue) {
         this.edFormDataSubject.next(nextvalue);
    }

    public get gcFormValue(): any{
        return this.gcFormDataSubject.value;
    }

    public pushGCformvalue(nextvalue){
        return this.gcFormDataSubject.next(nextvalue);
    }

    pullExistringPrescreeningDonorCase(emailId,phonelast4){
      //  // /api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/validate/phonenumber
      const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/validate/phonenumber/";
      const json={
        "emailId": emailId,
        "answer":phonelast4
      };
      return this.http.post(url,json);
    }
    checkExistingPrescreeningDonorCase(emailId){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/validate/case
      const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/validate/case/";
      const json={
        "emailId": emailId
      };
      return this.http.post(url,json);
    }
    getPrescreeningDonorVideoData(caseId){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/videos
      const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/videos";
      console.log(url);
      return this.http.get(url);
    }
    deletePrescreeningDonorVideo(caseId,seq){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/video/{seqNo}
      const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/video/"+seq;
      console.log(url);
      return this.http.delete(url);
    }
    getPrescreeningDonorVideoURL(caseId,seq){
     // /api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/video/{seqNo}
     const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/video/"+seq;
     console.log(url);
     return url;
    }
    uploadPresceeningDonorVideo(caseId,emailId,videoFiles):Observable<HttpEvent<any>>{
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/video
      const url = this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/video/"
      const formData: FormData = new FormData();
      formData.append("EmailId", emailId);
      formData.append("CaseId", caseId);
      videoFiles.forEach(ff=>{
        formData.append("videos", ff, ff.name);
      });
      //console.log(formData.getAll("CaseId"));
      //console.log(options);
      const req = new HttpRequest('POST', url, formData);
      return this.http.request(req);
    }
    donorShareMore(caseId){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/pre-approved/share-more
      const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/pre-approved/share-more";
      console.log(url);
      return this.http.post(url,{});
    }
    submitDonorForm2(caseId){
      // /api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/submitform2
      const url = this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/submitform2";
      return this.http.put(url,{});
    }
    getPrescreeningDonorImageData(caseId){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/images
      const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/images";
      console.log(url);
      return this.http.get(url);
    }
    deletePrescreeningDonorImage(caseId, imageSeq){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/image/{seqNo}
      const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/image/"+imageSeq;
      console.log(url);
      return this.http.delete(url);
    }
   
    uploadPrescreenEDImages(caseId,emailId,imageFiles){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/images
      const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/images";
      console.log(url);
          const formData: FormData = new FormData();
          formData.append("EmailId", emailId);
          formData.append("CaseId", caseId);
          imageFiles.forEach(imgfile => {
            formData.append("file", imgfile, imgfile.name);
          })
          // formData.append("images",imageFiles);
          console.log(formData.getAll("CaseId"));
          console.log(formData);
          console.log(url);
          const req = new HttpRequest('POST', url, formData);
          return this.http.request(req);
    }

    getEDPrescreenForm(){
        ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor
        //Prescanning/user/{userId}/form/eggdonor/config
        const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor";
       // return this.http.get(url,this.authservice.getRPHeaders());
       return this.http.get(url);
    }

    saveEDPresceeningForm(json){
        ///api/v1/Prescanning/tenant/{tenantName}/user/{userId}/form/eggdonor
        ///api​/v1​/Prescanning​/tenant​/{tenantName}​/form​/eggdonor
        const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor";
        console.log(url);
        console.log(json);
        return this.http.post(url,json);
    }

    finalizeGCDeny(caseId){
      // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/deny
      const url=this.authservice.apiPS+"Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/case/"+caseId+"/deny";
      return this.http.put(url,{});
    }
    finalizedEDPrescreening(json){
        ///api/v1/Prescanning/tenant/{tenantName}/user/{userId}/form/eggdonor/submit
        const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/submit";
        console.log(url);
        return this.http.post(url,json);
    }

    finalizedGCPrescreening(json){
        ///api/v1/Prescanning/tenant/{tenantName}/user/{userId}/form/eggdonor/submit
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/submit";
        console.log(url);
        console.log(json);
        return this.http.post(url,json);
    }


    getGCPrescreenForm(){
        ///api/v1/Prescanning/tenant/{tenantName}/user/{userId}/form/surrogate
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate";
        return this.http.get(url);
    }
 
    saveGCPresceeningForm(json){
        ///api/v1/Prescanning/tenant/{tenantName}/form/surrogate/submit
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate";
       // const url=this.authservice.apiBase + "Prescanning/tenant/"+this.authservice.getOrgName()+"/form/surrogate/submit";
        console.log(url);
        console.log(json);
        return this.http.post(url,json);
    }

    finalizedDonorDeny(caseId){
      ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/deny/submit-right-now
      const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/deny/submit-right-now";
      return this.http.post(url,{});
    }
    sendEDInvite(caseId,elemail){
        ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/email
        const url=this.authservice.apiBase+"Prescanning/tenant/"+this.authservice.getOrgName()+"/form/eggdonor/case/"+caseId+"/email";
        return this.http.put(url,{"elEmailId":elemail});
    }

    sendGCInvite(caseId,elemail){
        ///api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/email
        const url=this.authservice.apiPS+"form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/case/"+caseId+"/email";
        return this.http.put(url,{"elEmailId":elemail});
    }

    uploadPSAuthForm(tenantName,caseId, doctype, pdfdoc:any){
        const url = this.authservice.apiPS + "Form1/tenant/" + tenantName + "/form/surrogate/case/" + caseId + "/doc";
        const formData: FormData = new FormData();
        formData.append("doctype", doctype);
        formData.append("files",pdfdoc.output("blob"),"authform.pdf");
        let options: any = this.authservice.getUploadOptions();
        return this.http.post(url,formData,options);
    }

    uploadPSDocument(tenantName, caseId, doctype, file): Observable<HttpEvent<any>> {
        ///api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/doc
        const url = this.authservice.apiPS + "Form1/tenant/" + tenantName + "/form/surrogate/case/" + caseId + "/doc";
        const formData: FormData = new FormData();
        formData.append("doctype", doctype);
        file.forEach(ff => {
          formData.append("files", ff, ff.name);
        });
        console.log(formData.getAll("CaseId"));
        let options: any = this.authservice.getUploadOptions();
        options.reportProgress = true;
        options.responseType = 'json';
        console.log(options);
        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
      }
    
      // this api return the documnet attribute and metadata
      getPSDocInfo(tenantName, caseId, doctype){
        const url=this.authservice.apiPS+"form1/tenant/"+tenantName+"/form/surrogate/case/"+caseId+"/doc/"+doctype+"/attributes";
        console.log(url);
        return this.http.get(url);  
      }

      displayPSDocument(tenantName, caseId, docId, type) {
          ///api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/doc/{docId}
        const url = this.authservice.apiPS + "form1/tenant/" + tenantName + "/form/surrogate/case/" + caseId
          + "/doc/" + docId ;
          console.log(url);
        let mime = "application/pdf";
        type = type.toLowerCase();
        if (type.indexOf("jpg") != -1 || type.indexOf("jpeg") != -1 || type.indexOf("png") != -1 || type.indexOf("jfif") != -1) {
          mime = "image/jpeg";
        } else if (type.indexOf("doc") != -1) {
          mime = "application/msword";
        } else if (type.indexOf("pdf") != -1) {
          mime = "application/pdf";
        }
        this.getPDFBlob(url).subscribe(resp => {
          const file = new Blob([<any>resp], { type: mime });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        });
      }

      getPDFBlob(url: string) {
        const opps = this.authservice.getUploadOptions();
        opps['responseType'] = 'arraybuffer';
        console.log(opps);
        console.log('get content url:' + url);
        return this.http.get(url, opps);
      }

      deletePSDoc(tenantName,caseId, docId){
        const url = this.authservice.apiPS + "form1/tenant/" + tenantName + "/form/surrogate/case/" + caseId
        + "/doc/" + docId ;
        console.log(url);
        return this.http.delete(url);
      }

      getSignatureImageDataURL(tenant,patientName) {
        ///api/v1/Form1/tenant/{tenantName}/form/surrogate/signaturefonts
        const url=this.authservice.apiPS+"form1/tenant/"+tenant+"/form/surrogate/signaturefonts";
        console.log(url);
        const json={
            "userName":patientName
        };
        console.log(json);
        const opps = this.authservice.getUploadOptions(); // this is for binary content
        console.log('get content url:' + url);
        return this.http.post(url,json, opps);
      }

      submitGCForm(json){
        ///api/v1/Form1/tenant/{tenantName}/form/surrogate/submit
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/submit";
        console.log(url);
        return this.http.post(url,json);
      }

      checkExistingCase(emailId){
        // /api/v1/Form1/tenant/{tenantName}/form/surrogate/validate/case
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/validate/case";
        const json={
          "emailId": emailId
        };
        console.log(url);
        console.log(json);
        return this.http.post(url,json);
      }

      fetchExistingCase(emailId,last4){
        // /api/v1/Form1/tenant/{tenantName}/form/surrogate/validate/phonenumber
        
        const url=this.authservice.apiPS + "Form1/tenant/"+this.authservice.getOrgName()+"/form/surrogate/validate/phonenumber";
      
        const json={
            "emailId": emailId,
         //   "questionId": "telephone_number",
            "answer": last4
        };
        console.log(url);
        console.log(json);
        return this.http.post(url,json);
      }

      uploadGCPrescreenImage(tenantName,caseId,emailId,imageFiles){
          // /api/v1/Form1/tenant/{tenantName}/form/surrogate/images
         
          console.log(imageFiles.length);
          const url = this.authservice.apiPS + "Form1/tenant/" + tenantName + "/form/surrogate/images";
          const formData: FormData = new FormData();
     
          formData.append("EmailId", emailId);
          formData.append("CaseId", caseId);
          imageFiles.forEach(imgfile => {
            formData.append("file", imgfile, imgfile.name);
          })
          // formData.append("images",imageFiles);
          console.log(formData.getAll("CaseId"));
          console.log(formData);
          console.log(url);
          const req = new HttpRequest('POST', url, formData);
          return this.http.request(req);
          // return this.http.post(url,formData,options);
      }
        
      getImageData(tenantName, caseId){
        // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/images
        const url = this.authservice.apiPS + "Form1/tenant/" + tenantName + "/form/surrogate/case/"+caseId+"/images";
        console.log(url);
        return this.http.get(url);
      }

      _getImageBlob(url) {
        const opps = this.getToolsUploadOptions(); // this is for binary content
        opps['responseType'] = 'arraybuffer';
        console.log('get content url:' + url);
        return this.http.get(url, opps);
      }

      removePhoto(tenantName,caseId,seqNo){
        // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/image/{seqNo}
        let imgapiurl=this.authservice.apiPS+"Form1/tenant/"+tenantName+"/form/surrogate/case/"+caseId+"/image/"+seqNo;
        return this.http.delete(imgapiurl);
      }

      getToolsUploadOptions() {
      //  const authdata: any = this.elstate.getAuthData();
        let header = new HttpHeaders();
       // header = header.append('Authorization', 'Bearer ' + authdata.accessToken);
       // console.log(header);
        return { 'headers': header };
      }
}

@Injectable({ providedIn: 'root' })
export class RPErrorHandler {
    private duration=3000; // 3 seconds
    constructor(
      private alertcontrol:MatSnackBar
    ) {
        //this.initFormControl();
    }
    
    handle(err:Error){
        console.log("handling ---->",err);
        this.alertcontrol.open(err.message, "close", {
            duration: this.duration,
          });
    }

    
}