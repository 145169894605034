<br>
<div #goUp></div>
<div class="container" *ngIf="!serviceapierror">
<br><br>
<!-- <div class="logo_container">
  <img class="logo_top" src="../../../assets/images/ps.png">
</div>
<br><br> -->
<p style="visibility: hidden; margin-bottom: -30px;">TOP PAGE</p>
<h1 *ngIf="!page_two"><b>{{"GC.PS_TITLE" | translate}} </b></h1>
<div *ngIf="!page_two" class="second-title">You can complete the entire application as well as the intake process from home</div>

<div align="center">
  <span *ngIf="lastsaveTS"><font size=-1 color=green><i>last saved at {{lastsaveTS}}</i></font></span>
</div>
<!-- <table> -->
    <!-- <td><tr><mat-icon style="display: inline-flex; vertical-align: top;">information</mat-icon></tr></td>
    <td><tr><p>Welcome to {{organization.tenantName.toUpperCase()}}. Thank you for taking the first step in making a difference! Surrogate mothers are very special. We are very excited to review your initial application. Once submitted, you will be contacted by a Surrogate Specialist who will guide you through the intake process. If you have any questions, please feel free to contact us at 858-299-4540.</p></tr></td> -->
<!-- </table> -->
<br>
<form [formGroup]="gcform" (ngSubmit)="onSubmit()">
  <div style="text-align: left" *ngIf="page_one">
    <div class="page-one">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <table class="time-table">
            <td class="time-img"><img style="width: 50px;margin-bottom: -20px" src="../../../assets/images/clockwise.png"></td>
            <td class="time">
              <tr><b>Estimated completion time:</b></tr>
              <tr><span style="font-size: 24px;"><b>5 minutes</b></span></tr>
            </td>
          </table>
        </div>
      </div>
      <br>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <div>
            <button style="background-color: #8961a7 !important;" onclick="style.display = 'none'" mat-raised-button class="next_button" type="button" (click)="returnUser()">Returning user?</button>
          </div>
          <div class="return_user" *ngIf="returnuser">
            <mat-label><b>Enter your email: </b></mat-label><br>
            <mat-form-field style="margin-left: 0px;" appearance="outline">
              <input matInput formControlName="returning_user" maxlength="50" placeholder="pat@example.com" (keydown.enter)="checkCase()">
              <button type="button" mat-icon-button matSuffix (click)="checkCase()">
                <mat-icon style="color: #8a61a8; margin-top: 15px;">search</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="50" class="phone" *ngIf="appload">
          <div *ngIf="checkCaseStatus=='SUCCESS'" class="digit_box">
            <mat-label><b>Please enter the last 4 digits of your phone number: </b></mat-label><br>
            <mat-form-field style="margin-left: 0px;" appearance="outline">
              <input matInput formControlName="enter_digits" maxlength="50" (keydown.enter)="fetchCase()">
              <button type="button" mat-icon-button matSuffix (click)="fetchCase()">
                <mat-icon style="color: #8a61a8; margin-top: 15px;">arrow_forward_ios</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
      <br>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex>
          <div *ngIf="showCheckCaseMessage" class="message">
            <span *ngIf="checkCaseStatus=='SUCCESS'" style="color: green;"><b>{{checkCaseMessage}}</b></span>
            <span *ngIf="checkCaseStatus=='FAIL'" style="color: red;"><b>{{checkCaseMessage}}</b></span>
          </div>
          <div *ngIf="showFetchMessage" class="message">
            <span style="color: red;"><b>{{fetchMessage}}</b></span>
          </div>
        </div>
      </div>
      <br>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="33.33">
          <mat-label><b>1. {{ "GENERAL.FIRST_NAME" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput id="firstName_field" formControlName="firstName" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.firstName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['firstName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>2. {{ "GENERAL.LAST_NAME" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="lastName" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.lastName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['lastName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>3. {{ "GC.PRIMARY_PHONE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput id="telephone_number_field" mask="(000) 000-0000" placeholder="Ex. (000) 000-0000" formControlName="telephone_number" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.telephone_number.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['telephone_number'].invalid">{{ "GENERAL.PHONE_INVALID" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="33.33">
          <mat-label><b>4. {{ "GENERAL.EMAIL" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput id="email_field" formControlName="email" maxlength="50" placeholder="pat@example.com" (change)="checkEmailMatch()" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="0" class="hide">
          <mat-label><b> {{ "GENERAL.EMAIL_CONFIRM" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="confirm_email" maxlength="50" value="{{this.gcform.controls['confirm_email']}}">
          </mat-form-field>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>5. {{ "GENERAL.DATE_OF_BIRTH" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="birth_date" id="birth_date_field" formControlName="birthDate" placeholder="mm/dd/yyyy" matInput [matDatepicker]="picker" [min]="minDate" [max]="today" required>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error class="custom-msg" *ngIf="gcform.controls['birthDate'].invalid">{{ "GENERAL.INVALID_DATE" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>6. {{ "GC.HEAR.TITLE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="hear_about_us" placeholder="Select how you heard about us" required>
              <mat-option value="A Friend (Provide Name)">{{ "GC.HEAR.FRIEND" | translate }}</mat-option>
              <mat-option value="Advocate (Provide Name)">{{ "GC.HEAR.ADVOCATE" | translate }}</mat-option>
              <mat-option value="Car Ad">{{ "GC.HEAR.CAR_AD" | translate }}</mat-option>
              <mat-option value="Craigslist">{{ "GC.HEAR.CRAIGSLIST" | translate }}</mat-option>
              <mat-option value="Doctor’s Office (Provide Name)">{{ "GC.HEAR.DOCTOR" | translate }}</mat-option>
              <mat-option value="Facebook">{{ "GC.HEAR.FACEBOOK" | translate }}</mat-option>
              <mat-option value="Family Building Partner (Provide Name)">{{ "GC.HEAR.FAMILY" | translate }}</mat-option>
              <mat-option value="Flyer">{{ "GC.HEAR.FLYER" | translate }}</mat-option>
              <mat-option value="Google Ad">{{ "GC.HEAR.GOOGLE_AD" | translate }}</mat-option>
              <mat-option value="Image Ad on a Website">{{ "GC.HEAR.IMAGE_AD" | translate }}</mat-option>
              <mat-option value="Instagram">{{ "GC.HEAR.INSTAGRAM" | translate }}</mat-option>
              <mat-option value="Internet Research">{{ "GC.HEAR.INTERNET_RESEARCH" | translate }}</mat-option>
              <mat-option value="JobGet">{{ "GC.HEAR.JOBGET" | translate }}</mat-option>
              <mat-option value="Podcast">{{ "GC.HEAR.PODCAST" | translate }}</mat-option>
              <mat-option value="Postcard">{{ "GC.HEAR.POSTCARD" | translate }}</mat-option>
              <mat-option value="Recruiter (Provide Name)">{{ "GC.HEAR.RECRUITER" | translate }}</mat-option>
              <mat-option value="TV Ad">{{ "GC.HEAR.TV" | translate }}</mat-option>
              <mat-option value="Other (Specify)">{{ "GC.HEAR.OTHER" | translate }}</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['hear_about_us'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" *ngIf="gcform.controls.hear_about_us.value =='A Friend (Provide Name)' || gcform.controls.hear_about_us.value =='Advocate (Provide Name)' || gcform.controls.hear_about_us.value =='Doctor’s Office (Provide Name)' || gcform.controls.hear_about_us.value =='Family Building Partner (Provide Name)' || gcform.controls.hear_about_us.value =='Recruiter (Provide Name)' || gcform.controls.hear_about_us.value =='Other (Specify)'">
        <div fxFlex>
          <mat-label class="line-height-33"><b>{{ "GENERAL.PROVIDE_NAMES" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="provide_names" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.provide_names.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['provide_names'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
    
        <div *ngIf="recordexists">
          <font size=+1 color=green>{{message}}</font> <br>
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <!-- <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button> -->
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" [(disabled)]='!tab1valid' (click)='saveTab1()'>See if you qualify <mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
    <img #trackingPixel style='display: none;' width='1px' height='1px' [src]="trackingPixelUrl" *ngIf="pageOneSubmitted">
  </div>
  <div style="text-align: left" *ngIf="page_two">
    <div class="page-two">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div class="congrats">
          <p style="font-size: 24px">Congratulations! You've Pre-Qualified.</p>
          <!-- <p>You are one step closer to becoming a Surrogate with us!</p> -->
          <p>Complete The Full Application To Find Out If You’re Eligible! </p>
          <br>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab1()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab2()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div> 
      <br>-->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <!-- <div fxFlex="50">
          <mat-label><b>7. Maiden Name/Other Names Used </b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="maiden_name" maxlength="50">
            <mat-error class="custom-msg" *ngIf="gcform.controls.maiden_name.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div> -->
        <div fxFlex="100">
          <mat-label><b>7. {{ "GC.CITIZENSHIP_STATUS.TITLE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="citizen" placeholder="Select citizenship status" required>
              <mat-option value="US Citizen">{{ "GC.CITIZENSHIP_STATUS.US_CITIZEN" | translate }}</mat-option>
              <mat-option value="Green Card/Permanent Resident">{{ "GC.CITIZENSHIP_STATUS.PERMANENT_RESIDENT" | translate }}</mat-option>
              <mat-option value="Work Visa">{{ "GC.CITIZENSHIP_STATUS.WORK_VISA" | translate }}</mat-option>
              <mat-option value="Other">{{ "GC.CITIZENSHIP_STATUS.OTHER" | translate }}</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['citizen'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="33.33">
          <mat-label><b>8. {{ "GENERAL.HEIGHT" | translate }}</b></mat-label><font color=red>*</font><br>
          <div fxLayout="row">
          <div fxFlex="50">
            <mat-form-field class="height" appearance="outline">
            <input matInput name="htf" type="number" min="1" maxlength="50" formControlName="height_feet" required>
            <span matSuffix> {{ "GENERAL.FEET" | translate }}</span>
            <mat-error class="custom-msg" *ngIf="gcform.controls.height_feet.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['height_feet'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls.height_feet.hasError('min') || gcform.controls.height_feet.hasError('pattern')">Enter a positive integer</mat-error>
          </mat-form-field>
          </div>
          <div fxFlex="50">
          <mat-form-field class="height" appearance="outline">
            <input matInput name="hti" type="number" min="1" maxlength="50" formControlName="height_in" required>
            <span matSuffix> {{ "GENERAL.INCHES" | translate }}</span>
            <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['height_in'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('min')">Enter a positive number</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('max')">Enter a number between 0 and 12</mat-error>
          </mat-form-field>
          </div>
          </div>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>9. {{ "GENERAL.WEIGHT" | translate }}</b></mat-label><font color=red>*</font><br>
          <div fxLayout="row">
            <div fxFlex="50">
            <mat-form-field class="weight" appearance="outline">
            <input matInput name="wt" type="number" min="0" formControlName="weight" maxlength="50" required>
            <span matSuffix> {{ "GENERAL.POUNDS" | translate }}</span>
            <mat-error class="custom-msg" *ngIf="gcform.controls.weight.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['weight'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls.weight.hasError('min')">Enter a positive number</mat-error>
          </mat-form-field>
          </div>
          <div fxFlex="50">
          <mat-form-field class="weight" appearance="outline">
            <mat-label>{{Bmi()}}</mat-label>
            <input matInput type="number" readonly>
            <span matSuffix>{{ "GENERAL.BMI" | translate }}</span>
          </mat-form-field>
          </div>
        </div>
        </div>
        <div fxFlex="33.33">
          <mat-label class="label-height"><b>10. {{ "GC.OCCUPATION" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="occupation" maxlength="50">
            <mat-error class="custom-msg" *ngIf="gcform.controls.occupation.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <!-- <div fxFlex="33.33">
          <mat-label><b>11. What is your ethnic heritage?</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="ethnic_heritage" placeholder="Select ethnic heritage">
              <mat-option value="Caucasian">Caucasian</mat-option>
              <mat-option value="Asian">Asian</mat-option>
              <mat-option value="Hispanic or Latina">Hispanic or Latina</mat-option>
              <mat-option value="Black or African American">Black or African American</mat-option>
              <mat-option value="Mixed race">Mixed race</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="33.33" style="display: inline-table;" class="add-bottom-padding">
          <mat-label><b>11. {{ "GC.SURROGATE_BEFORE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="surrogate_before" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>12. {{ "GENERAL.STATE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="state" placeholder="Select state" required>
              <mat-option value="Alabama">Alabama</mat-option>
              <mat-option value="Alaska">Alaska</mat-option>
              <mat-option value="Arizona">Arizona</mat-option>
              <mat-option value="Arkansas">Arkansas</mat-option>
              <mat-option value="California">California</mat-option>
              <mat-option value="Colorado">Colorado</mat-option>
              <mat-option value="Connecticut">Connecticut</mat-option>
              <mat-option value="Delaware">Delaware</mat-option>
              <mat-option value="Florida">Florida</mat-option>
              <mat-option value="Georgia">Georgia</mat-option>
              <mat-option value="Hawaii">Hawaii</mat-option>
              <mat-option value="Idaho">Idaho</mat-option>
              <mat-option value="Illinois">Illinois</mat-option>
              <mat-option value="Indiana">Indiana</mat-option>
              <mat-option value="Iowa">Iowa</mat-option>
              <mat-option value="Kansas">Kansas</mat-option>
              <mat-option value="Kentucky">Kentucky</mat-option>
              <mat-option value="Louisiana">Louisiana</mat-option>
              <mat-option value="Maine">Maine</mat-option>
              <mat-option value="Maryland">Maryland</mat-option>
              <mat-option value="Massachusetts">Massachusetts</mat-option>
              <mat-option value="Michigan">Michigan</mat-option>
              <mat-option value="Minnesota">Minnesota</mat-option>
              <mat-option value="Mississippi">Mississippi</mat-option>
              <mat-option value="Missouri">Missouri</mat-option>
              <mat-option value="Montana">Montana</mat-option>
              <mat-option value="Nebraska">Nebraska</mat-option>
              <mat-option value="Nevada">Nevada</mat-option>
              <mat-option value="New Hampshire">New Hampshire</mat-option>
              <mat-option value="New Jersey">New Jersey</mat-option>
              <mat-option value="New Mexico">New Mexico</mat-option>
              <mat-option value="New York">New York</mat-option>
              <mat-option value="North Carolina">North Carolina</mat-option>
              <mat-option value="North Dakota">North Dakota</mat-option>
              <mat-option value="Ohio">Ohio</mat-option>
              <mat-option value="Oklahoma">Oklahoma</mat-option>
              <mat-option value="Oregon">Oregon</mat-option>
              <mat-option value="Pennsylvania">Pennsylvania</mat-option>
              <mat-option value="Rhode Island">Rhode Island</mat-option>
              <mat-option value="South Carolina">South Carolina</mat-option>
              <mat-option value="South Dakota">South Dakota</mat-option>
              <mat-option value="Tennessee">Tennessee</mat-option>
              <mat-option value="Texas">Texas</mat-option>
              <mat-option value="Utah">Utah</mat-option>
              <mat-option value="Vermont">Vermont</mat-option>
              <mat-option value="Virginia">Virginia</mat-option>
              <mat-option value="Washington">Washington</mat-option>
              <mat-option value="West Virginia">West Virginia</mat-option>
              <mat-option value="Wisconsin">Wisconsin</mat-option>
              <mat-option value="Wyoming">Wyoming</mat-option>
              <mat-option value="N/A">N/A</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['state'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="33.33">
          <mat-label><b>13. {{ "GENERAL.ZIP" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="zip_code" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.zip_code.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['zip_code'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label class="label-height"><b>12. {{ "GC.EDUCATION_LEVEL.TITLE" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="highest_level_education" placeholder="Select education">
              <mat-option value="High school not completed">{{ "GC.EDUCATION_LEVEL.HIGHSCHOOL_NOT_COMPLETED" | translate }}</mat-option>
              <mat-option value="High school completed">{{ "GC.EDUCATION_LEVEL.HIGHSCHOOL_COMPLETED" | translate }}</mat-option>
              <mat-option value="College enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_ENROLLED" | translate }}</mat-option>
              <mat-option value="College in progress or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_PROGRESS" | translate }}</mat-option>
              <mat-option value="College completed or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_COMPLETED" | translate }}</mat-option>
              <mat-option value="Graduate school enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_ENROLLED" | translate }}</mat-option>
              <mat-option value="Graduate school in progress or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_PROGRESS" | translate }}</mat-option>
              <mat-option value="Graduate school completed or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_COMPLETED" | translate }}</mat-option>
              <mat-option value="PhD enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_ENROLLED" | translate }}</mat-option>
              <mat-option value="PhD in progress or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_PROGRESS" | translate }}</mat-option>
              <mat-option value="PhD completed or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_COMPLETED" | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-label class="label-height"><b>10. {{ "GC.OCCUPATION" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="occupation" maxlength="50">
            <mat-error class="custom-msg" *ngIf="gcform.controls.occupation.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage2">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab1()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <!-- <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" [(disabled)]='!tab2valid' (click)='saveTab2()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button> -->
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab2()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
  </div>
  <div style="text-align: left" *ngIf="page_three">
    <div class="page-three">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>14. {{ "GC.MARITAL_STATUS.TITLE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="marital_status" placeholder="Select marital status" required>
              <mat-option value="Single">{{ "GC.MARITAL_STATUS.SINGLE" | translate }}</mat-option>
              <mat-option value="In a relationship">In a relationship</mat-option>
              <mat-option value="Married">{{ "GC.MARITAL_STATUS.MARRIED" | translate }}</mat-option>
              <mat-option value="Widowed">{{ "GC.MARITAL_STATUS.WIDOWED" | translate }}</mat-option>
              <mat-option value="Divorced">{{ "GC.MARITAL_STATUS.DIVORCED" | translate }}</mat-option>
              <mat-option value="Separated">{{ "GC.MARITAL_STATUS.SEPARATED" | translate }}</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['marital_status'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>15. {{ "GC.PARTNER_NAME" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="spouse_name" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.spouse_name.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['spouse_name'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>16. {{ "GC.FELONY_YOU" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="felony" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>17. {{ "GC.FELONY_YOU_PARTNER.TITLE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="felony_partner" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="N/A">N/A</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>18. Do you use recreational drugs such as marijuanna?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_drugs" placeholder="Please select">
            <mat-option value="No">No</mat-option>
            <mat-option value="Rarely">Rarely</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Daily">Daily</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>19. Are you currently taking prescribed antidepressants?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_antidepressan" placeholder="Please select" required>
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab2()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab3()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>14. {{ "GC.DRIVERS_LICENSE" | translate }}</b></mat-label><br><br>
          <mat-radio-group formControlName="drivers_license">
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="100" style="display: inline-table;" class="add-bottom-padding">
          <mat-label><b>11. {{ "GC.SURROGATE_BEFORE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="surrogate_before" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="100">
          <mat-label><b>16. {{ "GC.INSPIRED_YOU" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="inspired_you" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.inspired_you.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="100">
          <mat-label><b>15. {{ "GC.INSPIRED_YOU" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="inspired_you" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.inspired_you.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="100">
          <mat-label><b>17. {{ "GENERAL.ADDRESS" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="streetAdd" maxlength="500">
            <mat-error class="custom-msg" *ngIf="gcform.controls.streetAdd.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="33.33">
          <mat-label><b>18. {{ "GENERAL.CITY" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="city" maxlength="500">
            <mat-error class="custom-msg" *ngIf="gcform.controls.city.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>12. {{ "GENERAL.STATE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="state" placeholder="Select state" required>
              <mat-option value="Alabama">Alabama</mat-option>
              <mat-option value="Alaska">Alaska</mat-option>
              <mat-option value="Arizona">Arizona</mat-option>
              <mat-option value="Arkansas">Arkansas</mat-option>
              <mat-option value="California">California</mat-option>
              <mat-option value="Colorado">Colorado</mat-option>
              <mat-option value="Connecticut">Connecticut</mat-option>
              <mat-option value="Delaware">Delaware</mat-option>
              <mat-option value="Florida">Florida</mat-option>
              <mat-option value="Georgia">Georgia</mat-option>
              <mat-option value="Hawaii">Hawaii</mat-option>
              <mat-option value="Idaho">Idaho</mat-option>
              <mat-option value="Illinois">Illinois</mat-option>
              <mat-option value="Indiana">Indiana</mat-option>
              <mat-option value="Iowa">Iowa</mat-option>
              <mat-option value="Kansas">Kansas</mat-option>
              <mat-option value="Kentucky">Kentucky</mat-option>
              <mat-option value="Louisiana">Louisiana</mat-option>
              <mat-option value="Maine">Maine</mat-option>
              <mat-option value="Maryland">Maryland</mat-option>
              <mat-option value="Massachusetts">Massachusetts</mat-option>
              <mat-option value="Michigan">Michigan</mat-option>
              <mat-option value="Minnesota">Minnesota</mat-option>
              <mat-option value="Mississippi">Mississippi</mat-option>
              <mat-option value="Missouri">Missouri</mat-option>
              <mat-option value="Montana">Montana</mat-option>
              <mat-option value="Nebraska">Nebraska</mat-option>
              <mat-option value="Nevada">Nevada</mat-option>
              <mat-option value="New Hampshire">New Hampshire</mat-option>
              <mat-option value="New Jersey">New Jersey</mat-option>
              <mat-option value="New Mexico">New Mexico</mat-option>
              <mat-option value="New York">New York</mat-option>
              <mat-option value="North Carolina">North Carolina</mat-option>
              <mat-option value="North Dakota">North Dakota</mat-option>
              <mat-option value="Ohio">Ohio</mat-option>
              <mat-option value="Oklahoma">Oklahoma</mat-option>
              <mat-option value="Oregon">Oregon</mat-option>
              <mat-option value="Pennsylvania">Pennsylvania</mat-option>
              <mat-option value="Rhode Island">Rhode Island</mat-option>
              <mat-option value="South Carolina">South Carolina</mat-option>
              <mat-option value="South Dakota">South Dakota</mat-option>
              <mat-option value="Tennessee">Tennessee</mat-option>
              <mat-option value="Texas">Texas</mat-option>
              <mat-option value="Utah">Utah</mat-option>
              <mat-option value="Vermont">Vermont</mat-option>
              <mat-option value="Virginia">Virginia</mat-option>
              <mat-option value="Washington">Washington</mat-option>
              <mat-option value="West Virginia">West Virginia</mat-option>
              <mat-option value="Wisconsin">Wisconsin</mat-option>
              <mat-option value="Wyoming">Wyoming</mat-option>
              <mat-option value="N/A">N/A</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['state'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>13. {{ "GENERAL.ZIP" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="zip_code" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.zip_code.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['zip_code'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage3">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab2()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <!-- <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" [(disabled)]='!tab3valid' (click)='saveTab3()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button> -->
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab3()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
  </div>
  <div style="text-align: left" *ngIf="page_four">
    <div class="page-four">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="100">
          <mat-label><b>20. Sexually Transmitted Disease (STD) - Yourself</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="treat_yourself" placeholder="Please select" multiple required>
              <mat-option value="None">None</mat-option>
              <mat-option value="AIDS">AIDS</mat-option>
              <mat-option value="Genital Herpes">Genital Herpes</mat-option>
              <mat-option value="Genital Warts">Genital Warts</mat-option>
              <mat-option value="Hepatisis B">Hepatitis B</mat-option>
              <mat-option value="Hepatisis C">Hepatitis C</mat-option>
              <mat-option value="Herpes">Herpes</mat-option>
              <mat-option value="HIV">HIV</mat-option>
              <mat-option value="HPV">HPV</mat-option>
              <mat-option value="Syphilis">Syphilis</mat-option>
              <mat-option value="Trichomonas">Trichomonas</mat-option>
              <mat-option value="Chlamydia">Chlamydia</mat-option>
              <mat-option value="Gonorrhea">Gonorrhea</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['treat_yourself'].touched && gcform.controls['treat_yourself'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>21. Have you ever had any miscarriages or abortions?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="miscarriages" placeholder="Please select" required>
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" *ngIf="gcform.controls.miscarriages.value == 'Yes'">
          <mat-label class="label-height-53"><b>If yes, please list date(s), including the year</b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="explain_miscarriages" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explain_miscarriages.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['explain_miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>22. Currently breast feeding</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select  formControlName="breast_feeding" placeholder="Please select" required>
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['breast_feeding'].touched && gcform.controls['breast_feeding'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" *ngIf="gcform.controls.breast_feeding.value =='Yes'">
          <mat-label><b>If yes, approximately when to finish? (Month/Year) </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="explain_bf" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explain_bf.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['explain_bf'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>23. {{ "GC.TRIBE.TITLE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="tribe" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="50">
          <mat-label><b>24. Current Birth Control</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="birth_control" placeholder="Select birth control" required>
              <mat-option value="None">None</mat-option>
              <mat-option value="Abstinence">Abstinence</mat-option>
              <mat-option value="Birth Control Patch">Birth Control Patch</mat-option>
              <mat-option value="Birth Control Pills">Birth Control Pills</mat-option>
              <mat-option value="Condoms">Condoms</mat-option>
              <mat-option value="Depo Shot">Depo Shot</mat-option>
              <mat-option value="Depo-Provera Shot">Depo-Provera Shot</mat-option>
              <mat-option value="Essure">Essure</mat-option>
              <mat-option value="IUD - Copper (Paraguard)">IUD - Copper (Paraguard)</mat-option>
              <mat-option value="IUD - Mirena, Skyla, or Liletta">IUD - Mirena, Skyla, or Liletta</mat-option>
              <mat-option value="Implanon">Implanon</mat-option>
              <mat-option value="Nexplanon">Nexplanon</mat-option>
              <mat-option value="Nexplanon or Arm Implant">Nexplanon or Arm Implant</mat-option>
              <mat-option value="NuvaRing">NuvaRing</mat-option>
              <mat-option value="Other">Other</mat-option>
              <mat-option value="Partner Vasectomy">Partner Vasectomy</mat-option>
              <mat-option value="Tubal Ligation">Tubal Ligation</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['birth_control'].touched && gcform.controls['birth_control'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab3()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab4()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>14. {{ "GC.MARITAL_STATUS.TITLE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="marital_status" placeholder="Select marital status" required>
              <mat-option value="Single">{{ "GC.MARITAL_STATUS.SINGLE" | translate }}</mat-option>
              <mat-option value="In a relationship">In a relationship</mat-option>
              <mat-option value="Married">{{ "GC.MARITAL_STATUS.MARRIED" | translate }}</mat-option>
              <mat-option value="Widowed">{{ "GC.MARITAL_STATUS.WIDOWED" | translate }}</mat-option>
              <mat-option value="Divorced">{{ "GC.MARITAL_STATUS.DIVORCED" | translate }}</mat-option>
              <mat-option value="Separated">{{ "GC.MARITAL_STATUS.SEPARATED" | translate }}</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['marital_status'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>15. {{ "GC.PARTNER_NAME" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="spouse_name" maxlength="50" required>
            <mat-error class="custom-msg" *ngIf="gcform.controls.spouse_name.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['spouse_name'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>23. {{ "GC.PARTNER_EMAIL" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="spouse_email" maxlength="50" placeholder="pat@example.com">
            <mat-error class="custom-msg" *ngIf="gcform.controls.spouse_email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['spouse_email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label class="label-height-28"><b>24. {{ "GC.PARTNER_LIVING" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="partner_living" maxlength="50"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.partner_living.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label class="label-height-28"><b>25. {{ "GC.MAIN_SUPPORT" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="main_support" maxlength="50"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.main_support.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label class="label-height-28"><b>26. {{ "GC.LIST_EVERYONE" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="list_everyone" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.list_everyone.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>16. {{ "GC.FELONY_YOU" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="felony" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>17. {{ "GC.FELONY_YOU_PARTNER.TITLE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="felony_partner" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="N/A">N/A</mat-radio-button>
          </mat-radio-group>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>18. Do you use recreational drugs such as marijuanna?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_drugs" placeholder="Please select">
            <mat-option value="No">No</mat-option>
            <mat-option value="Rarely">Rarely</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Daily">Daily</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>19. Are you currently taking prescribed antidepressants?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_antidepressan" placeholder="Please select" required>
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage4">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab3()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <!-- <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" [(disabled)]='!tab4valid' (click)='saveTab4()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button> -->
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab4()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
  </div>
  <div style="text-align: left" *ngIf="page_five">
    <div class="page-five">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex>
          <mat-label><b>25. {{ "GC.HOW_MANY_CHILDREN.TITLE" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="children" placeholder="Select number of deliveries" (selectionChange)="selectChildren($event)">
              <mat-option value="0">0</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="display: contents;" *ngIf="showDeliveryTable">
        <div class="delivery-table" *ngIf="ctrl_list_pregnancy_historys!=null">
          <ng-container *ngFor="let lh of ctrl_list_pregnancy_historys.controls; let idx=index" [formGroup]="lh">
              <div><u><b>Delivery History <font color=purple>{{idx + 1 }}</font></b></u></div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Delivery History</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-label>{{idx + 1}}</mat-label>
                    <input formControlName="history" matInput type="number" readonly>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Outcome</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="outcome" placeholder="Please select">
                      <mat-option value="Singleton delivery">Singleton delivery</mat-option>
                      <mat-option value="Twins or more delivery">Twins or more delivery</mat-option>
                      <mat-option value="Stillbirth">Still birth</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Delivery Date</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <input #dpInput formControlName="delivery_date" matInput [matDatepicker]="dp" [min]="minDate" [max]="today">
                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Delivery Methods</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="delivery_method" placeholder="Please select">
                      <mat-option value="C_Section">C-Section</mat-option>
                      <mat-option value="Vaginal">Vaginal</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Surrogacy Pregnancy</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Surrogacy_Pregnancy" placeholder="Please select">
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Gestational Week at Birth</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <input matInput formControlName="Pregnancy_Week_Birth" maxlength="50">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Newborn Weight at Birth</b></mat-label><br>
                  <div fxLayout="row">
                      <div fxFlex="50">
                        <mat-form-field class="newborn_lbs" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight" type="number" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}'><span matSuffix>lbs</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight'].hasError('min') || lh.controls['Newborn_weight'].hasError('pattern')">Enter a positive integer</mat-error>
                        </mat-form-field> 
                      </div>
                      <div fxFlex="50">
                        <mat-form-field class="newborn_oz" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight_oz" type="number" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}'><span matSuffix>oz</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight_oz'].hasError('min') || lh.controls['Newborn_weight_oz'].hasError('pattern')">Enter a positive integer</mat-error>
                        </mat-form-field>
                      </div>
                  </div>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Pregnancy Medical Conditions</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Pregnancy_Medical" placeholder="Please select" (selectionChange)="selectPregnancyMedical($event)" multiple>
                      <mat-option value="None">None</mat-option>
                      <mat-option value="Diabetes">Diabetes</mat-option>
                      <mat-option value="Heart Problem">Heart Problem</mat-option>
                      <mat-option value="High Blood Pressure">High Blood Pressure</mat-option>
                      <mat-option value="Ovarian Cysts">Ovarian Cysts</mat-option>
                      <mat-option value="Migraine Headaches">Migraine Headaches</mat-option>
                      <mat-option value="Asthmatic Symptoms">Asthmatic Symptoms</mat-option>
                      <mat-option value="Uterine Fibroids">Uterine Fibroids</mat-option>
                      <mat-option value="Thyroid Problem">Thyroid Problem</mat-option>
                      <mat-option value="Others">Others</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Severe Pregnancy Complications</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Severe_Pregnancy" placeholder="Please select" (selectionChange)="selectSeverePregnancy($event)" multiple>
                      <mat-option value="N/A">N/A</mat-option>
                      <mat-option value="Intrauterine Growth Restriction">Intrauterine Growth Restriction</mat-option>
                      <mat-option value="Eclampsia">Eclampsia</mat-option>
                      <mat-option value="Pre-eclampsia">Pre-eclampsia</mat-option>
                      <mat-option value="Oligohydramnios">Oligohydramnios</mat-option>
                      <mat-option value="Others">Others</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
          </ng-container>
        </div>
      </div>
      <br>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab4()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab5()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label class="label-height"><b>29. Do you drink alcohol?</b></mat-label><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_alcohol" placeholder="Please select">
            <mat-option value="No">No</mat-option>
            <mat-option value="Rarely">Rarely</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Daily">Daily</mat-option>
          </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label class="label-height"><b>30. Do you smoke?</b></mat-label><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_smoke" placeholder="Please select">
            <mat-option value="No">No</mat-option>
            <mat-option value="Rarely">Rarely</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Daily">Daily</mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>31. {{ "GC.SMOKE" | translate }}</b></mat-label><br><br>
          <mat-radio-group formControlName="smoke">
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="50">
          <mat-label><b>32. Are you currently taking any of the following substances?</b></mat-label><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance" placeholder="Please select" multiple>
            <mat-option value="None">None</mat-option>
            <mat-option value="Amphetamines">Amphetamines</mat-option>
            <mat-option value="Barbiturates">Barbiturates</mat-option>
            <mat-option value="Cocaine">Cocaine</mat-option>
            <mat-option value="Hallucinogens">Hallucinogens</mat-option>
            <mat-option value="Heroin">Heroin</mat-option>
            <mat-option value="LSD">LSD</mat-option>
            <mat-option value="Narcotics">Narcotics</mat-option>
            <mat-option value="Opiates">Opiates</mat-option>
            <mat-option value="PCP">PCP</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>18. Do you use recreational drugs such as marijuanna?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_drugs" placeholder="Please select">
            <mat-option value="No">No</mat-option>
            <mat-option value="Rarely">Rarely</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Daily">Daily</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>19. Are you currently taking prescribed antidepressants?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
          <mat-select formControlName="substance_antidepressan" placeholder="Please select" required>
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
          </mat-select>
          <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>35. {{ "GC.MAJOR_CASES" | translate }}</b></mat-label><br><br>
          <mat-radio-group formControlName="major_cases">
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="50" class="add-bottom-padding">
          <mat-label><b>36. {{ "GC.FILLING_DIVORCE" | translate }}</b></mat-label><br><br>
          <mat-radio-group formControlName="filling_divorce">
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div> -->
      <div *ngIf="showExplainBox1 || showExplainBox2" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex class="explain_box">
          <mat-label><b>If you chose “Others” for pregnancy medical conditions or severe pregnancy complications, please provide more details below: </b></mat-label><br>
          <mat-form-field style="margin-left: -3px;" class="box" appearance="outline">
            <textarea matInput formControlName="explainDelivery" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explainDelivery.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showErrorMessage">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: The number of deliveries in the Delivery History table does not match your answer to question 45.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage5">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab4()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
      <!-- <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" [(disabled)]='!tab5valid' (click)='saveTab5()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button> -->
      <!-- <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab5()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button> -->
    </div>
  </div>
  <!-- page-six -->
  <!-- <div style="text-align: left" *ngIf="page_six">
    <div class="page-six">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div fxFlex>
          <div class="photos_section">
            <span style="width: 100%; word-break: break-word;">
              <b><p>26. Photos (optional)</p></b>
              <p>We would love to present the best of you. You can upload up to 20 photos taken from different time periods in your life.</p>
            </span>
          </div>
          <div class="photo-button-row">
            <button mat-raised-button type="button" color="primary" class="next_button" [disabled]='!galleryready' (click)="addPhoto()">Add Photos <mat-icon>photo</mat-icon></button>
          </div>
          <br>
          <br>
          <table width=95%>
            <tr>
              <td width=100%>
                <div *ngIf="galleryready&&photoimages.length==0">
                  [No Photos Uploaded]
                </div>
                <div *ngIf="galleryready&&photoimages.length>0">
                  <ks-carousel [id]="116" [images]="photoimages" (show)="onChangeImage($event)"
                    [carouselConfig]="{maxWidth: '350px', maxHeight: '350px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
                    [previewConfig]="{visible: true, number: 14, width: 'auto', maxHeight: '40px'}">
                  </ks-carousel>
                </div>
              </td>
              <td valign=top *ngIf="galleryready&&photoimages.length>0">
                <table style="max-height: 450px; min-width: 350px; display: table-caption; overflow-y: auto;">
                  <tr *ngFor="let img of photodata.images;let ii=index">
                    <td width=20></td>
                    <td *ngIf="ii==currentIndex" style="font-weight: bold">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=purple>[{{img.seqNo}}] {{img.fileName}}

                    </font></td>
                    <td *ngIf="ii!=currentIndex">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=black size=-1>{{img.seqNo}} {{img.fileName}}
                      
                    </font></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div *ngIf="!galleryready">
            <mat-spinner  diameter='20'></mat-spinner>
            <span> Loading Images....</span> 
          </div>
        </div>
      </div>
      <br> -->
      <!-- page-six -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab5()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab6()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="100">
          <mat-label><b>20. Sexually Transmitted Disease (STD) - Yourself</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="treat_yourself" placeholder="Please select" multiple required>
              <mat-option value="None">None</mat-option>
              <mat-option value="AIDS">AIDS</mat-option>
              <mat-option value="Genital Herpes">Genital Herpes</mat-option>
              <mat-option value="Genital Warts">Genital Warts</mat-option>
              <mat-option value="Hepatisis B">Hepatitis B</mat-option>
              <mat-option value="Hepatisis C">Hepatitis C</mat-option>
              <mat-option value="Herpes">Herpes</mat-option>
              <mat-option value="HIV">HIV</mat-option>
              <mat-option value="HPV">HPV</mat-option>
              <mat-option value="Syphilis">Syphilis</mat-option>
              <mat-option value="Trichomonas">Trichomonas</mat-option>
              <mat-option value="Chlamydia">Chlamydia</mat-option>
              <mat-option value="Gonorrhea">Gonorrhea</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['treat_yourself'].touched && gcform.controls['treat_yourself'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>38. Sexually Transmitted Disease (STD) - Your Partner</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="treat_yourpartner" placeholder="Please select" multiple>
              <mat-option value="None">None</mat-option>
              <mat-option value="AIDS">AIDS</mat-option>
              <mat-option value="Genital Herpes">Genital Herpes</mat-option>
              <mat-option value="Genital Warts">Genital Warts</mat-option>
              <mat-option value="Hepatisis B">Hepatitis B</mat-option>
              <mat-option value="Hepatisis C">Hepatitis C</mat-option>
              <mat-option value="Herpes">Herpes</mat-option>
              <mat-option value="HIV">HIV</mat-option>
              <mat-option value="HPV">HPV</mat-option>
              <mat-option value="Syphilis">Syphilis</mat-option>
              <mat-option value="Trichomonas">Trichomonas</mat-option>
              <mat-option value="Chlamydia">Chlamydia</mat-option>
              <mat-option value="Gonorrhea">Gonorrhea</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>21. Have you ever had any miscarriages or abortions?</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="miscarriages" placeholder="Please select" required>
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" *ngIf="gcform.controls.miscarriages.value == 'Yes'">
          <mat-label class="label-height-53"><b>If yes, please list date(s), including the year</b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="explain_miscarriages" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explain_miscarriages.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['explain_miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>22. Currently breast feeding</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select  formControlName="breast_feeding" placeholder="Please select" required>
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['breast_feeding'].touched && gcform.controls['breast_feeding'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" *ngIf="gcform.controls.breast_feeding.value =='Yes'">
          <mat-label><b>If yes, approximately when to finish? (Month/Year) </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="explain_bf" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explain_bf.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['explain_bf'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>41. Have you had GYN surgeries involving reproductive organs?</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="gyn" placeholder="Select GYN surgeries" multiple>
              <mat-option value="None">None</mat-option>
              <mat-option value="Ovarian Cystectomy">Ovarian Cystectomy</mat-option>
              <mat-option value="Fibroid Removal">Fibroid Removal</mat-option>
              <mat-option value="Salpingectomy">Salpingectomy</mat-option>
              <mat-option value="Oophorectomy">Oophorectomy</mat-option>
              <mat-option value="Endometrial Ablation">Endometrial Ablation</mat-option>
              <mat-option value="Uterine Artery Embolization">Uterine Artery Embolization</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="100" class="add-bottom-padding">
          <mat-label><b>23. {{ "GC.TRIBE.TITLE" | translate }}</b></mat-label><font color=red>*</font><br><br>
          <mat-radio-group formControlName="tribe" required>
              <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
              <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div> -->
      <!-- <mat-form-field appearance="outline">
        <input #pickerInput formControlName="latest_date" matInput [matDatepicker]="picker" [min]="minDate" [max]="today" required>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" (monthSelected)="monthPapSelected($event, picker, pickerInput,gcform)"></mat-datepicker>
        <mat-error class="custom-msg" *ngIf="gcform.controls['latest_date'].touched && gcform.controls['latest_date'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>43. Latest Pap Smear Date</b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="latest_date" maxlength="50">
            <mat-error class="custom-msg" *ngIf="gcform.controls.latest_date.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-label><b>44. Latest Pap Results</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="latest_result" placeholder="Select result">
              <mat-option value="Normal">Normal</mat-option>
              <mat-option value="Abnormal">Abnormal</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex="50">
          <mat-label><b>24. Current Birth Control</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="birth_control" placeholder="Select birth control" required>
              <mat-option value="None">None</mat-option>
              <mat-option value="Abstinence">Abstinence</mat-option>
              <mat-option value="Birth Control Patch">Birth Control Patch</mat-option>
              <mat-option value="Birth Control Pills">Birth Control Pills</mat-option>
              <mat-option value="Condoms">Condoms</mat-option>
              <mat-option value="Depo Shot">Depo Shot</mat-option>
              <mat-option value="Depo-Provera Shot">Depo-Provera Shot</mat-option>
              <mat-option value="Essure">Essure</mat-option>
              <mat-option value="IUD - Copper (Paraguard)">IUD - Copper (Paraguard)</mat-option>
              <mat-option value="IUD - Mirena, Skyla, or Liletta">IUD - Mirena, Skyla, or Liletta</mat-option>
              <mat-option value="Implanon">Implanon</mat-option>
              <mat-option value="Nexplanon">Nexplanon</mat-option>
              <mat-option value="Nexplanon or Arm Implant">Nexplanon or Arm Implant</mat-option>
              <mat-option value="NuvaRing">NuvaRing</mat-option>
              <mat-option value="Other">Other</mat-option>
              <mat-option value="Partner Vasectomy">Partner Vasectomy</mat-option>
              <mat-option value="Tubal Ligation">Tubal Ligation</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['birth_control'].touched && gcform.controls['birth_control'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- page-six -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage6">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- page-six -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- page-six -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab5()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
    </div>
  </div> -->
  <!-- page-six -->
  <!-- <div style="text-align: left" *ngIf="page_seven">
    <div class="page-seven"> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab6()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab7()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showErrorMessage">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: The number of deliveries in the Delivery History table does not match your answer to question 18.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex>
          <mat-label><b>25. {{ "GC.HOW_MANY_CHILDREN.TITLE" | translate }}</b></mat-label><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="children" placeholder="Select number of deliveries" (selectionChange)="selectChildren($event)">
              <mat-option value="0">0</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="display: contents;" *ngIf="showDeliveryTable">
        <div class="delivery-table" *ngIf="ctrl_list_pregnancy_historys!=null">
          <ng-container *ngFor="let lh of ctrl_list_pregnancy_historys.controls; let idx=index" [formGroup]="lh">
              <div><u><b>Delivery History <font color=purple>{{idx + 1 }}</font></b></u></div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Delivery History</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-label>{{idx + 1}}</mat-label>
                    <input formControlName="history" matInput type="number" readonly>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Outcome</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="outcome" placeholder="Please select">
                      <mat-option value="Singleton delivery">Singleton delivery</mat-option>
                      <mat-option value="Twins or more delivery">Twins or more delivery</mat-option>
                      <mat-option value="Stillbirth">Still birth</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Delivery Date</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <input #dpInput formControlName="delivery_date" matInput [matDatepicker]="dp" [min]="minDate" [max]="today">
                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Delivery Methods</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="delivery_method" placeholder="Please select">
                      <mat-option value="C_Section">C-Section</mat-option>
                      <mat-option value="Vaginal">Vaginal</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Surrogacy Pregnancy</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Surrogacy_Pregnancy" placeholder="Please select">
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Gestational Week at Birth</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <input matInput formControlName="Pregnancy_Week_Birth" maxlength="50">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxFlex="33.33">
                  <mat-label><b>Newborn Weight at Birth</b></mat-label><br>
                  <div fxLayout="row">
                      <div fxFlex="50">
                        <mat-form-field class="newborn_lbs" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight" type="number" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}'><span matSuffix>lbs</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight'].hasError('min') || lh.controls['Newborn_weight'].hasError('pattern')">Enter a positive integer</mat-error>
                        </mat-form-field> 
                      </div>
                      <div fxFlex="50">
                        <mat-form-field class="newborn_oz" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight_oz" type="number" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}'><span matSuffix>oz</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight_oz'].hasError('min') || lh.controls['Newborn_weight_oz'].hasError('pattern')">Enter a positive integer</mat-error>
                        </mat-form-field>
                      </div>
                  </div>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Pregnancy Medical Conditions</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Pregnancy_Medical" placeholder="Please select" (selectionChange)="selectPregnancyMedical($event)" multiple>
                      <mat-option value="None">None</mat-option>
                      <mat-option value="Diabetes">Diabetes</mat-option>
                      <mat-option value="Heart Problem">Heart Problem</mat-option>
                      <mat-option value="High Blood Pressure">High Blood Pressure</mat-option>
                      <mat-option value="Ovarian Cysts">Ovarian Cysts</mat-option>
                      <mat-option value="Migraine Headaches">Migraine Headaches</mat-option>
                      <mat-option value="Asthmatic Symptoms">Asthmatic Symptoms</mat-option>
                      <mat-option value="Uterine Fibroids">Uterine Fibroids</mat-option>
                      <mat-option value="Thyroid Problem">Thyroid Problem</mat-option>
                      <mat-option value="Others">Others</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33.33">
                  <mat-label><b>Severe Pregnancy Complications</b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Severe_Pregnancy" placeholder="Please select" (selectionChange)="selectSeverePregnancy($event)" multiple>
                      <mat-option value="N/A">N/A</mat-option>
                      <mat-option value="Intrauterine Growth Restriction">Intrauterine Growth Restriction</mat-option>
                      <mat-option value="Eclampsia">Eclampsia</mat-option>
                      <mat-option value="Pre-eclampsia">Pre-eclampsia</mat-option>
                      <mat-option value="Oligohydramnios">Oligohydramnios</mat-option>
                      <mat-option value="Others">Others</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
          </ng-container>
        </div>
      </div> -->
      <!-- <div *ngIf="showDeliveryTable">
        <button mat-raised-button class="next_button" type="button" color="warn" (click)="add_pregnancy()">{{ "GENERAL.ADD_MORE" | translate }} <mat-icon>add_box</mat-icon></button>
      </div>
      <br> -->
      <!-- <div *ngIf="ctrl_list_pregnancy_historys!=null">
        <div *ngFor="let lh of ctrl_list_pregnancy_historys.controls; let idx=index" [formGroup]="lh">
          <div *ngIf="showExplainBox" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <div fxFlex class="explain_box">
              <mat-label><b>If you chose “Others” for pregnancy medical conditions or severe pregnancy complications, please provide more details below: </b></mat-label><br>
              <mat-form-field style="margin-left: -3px;" class="box" appearance="outline">
                <textarea matInput formControlName="explainDelivery" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.explainDelivery.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div *ngIf="showExplainBox1 || showExplainBox2" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex class="explain_box">
          <mat-label><b>If you chose “Others” for pregnancy medical conditions or severe pregnancy complications, please provide more details below: </b></mat-label><br>
          <mat-form-field style="margin-left: -3px;" class="box" appearance="outline">
            <textarea matInput formControlName="explainDelivery" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.explainDelivery.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showErrorMessage">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: The number of deliveries in the Delivery History table does not match your answer to question 45.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage7">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div> -->
    <!-- <br>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab6()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab7()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
    </div>
  </div> -->
  <!-- <div style="text-align: left" *ngIf="page_eight">
    <div class="page-eight"> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex style="text-align: center;">
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab7()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
          <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
          <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
        </div>
      </div> -->
      <!-- <br>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" >
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
      <br>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage8">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div fxFlex>
          <div class="photos_section">
            <span style="width: 100%; word-break: break-word;">
              <b><p>26. Photos (optional)</p></b>
              <p>We would love to present the best of you. You can upload up to 20 photos taken from different time periods in your life.</p>
            </span>
          </div>
          <div class="photo-button-row">
            <button mat-raised-button type="button" color="primary" class="next_button" [disabled]='!galleryready' (click)="addPhoto()">Add Photos <mat-icon>photo</mat-icon></button>
          </div>
          <br>
          <br>
          <table width=95%>
            <tr>
              <td width=100%>
                <div *ngIf="galleryready&&photoimages.length==0">
                  [No Photos Uploaded]
                </div>
                <div *ngIf="galleryready&&photoimages.length>0">
                  <ks-carousel [id]="116" [images]="photoimages" (show)="onChangeImage($event)"
                    [carouselConfig]="{maxWidth: '350px', maxHeight: '350px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
                    [previewConfig]="{visible: true, number: 14, width: 'auto', maxHeight: '40px'}">
                  </ks-carousel>
                </div>
              </td>
              <td valign=top *ngIf="galleryready&&photoimages.length>0">
                <table style="max-height: 450px; min-width: 350px; display: table-caption; overflow-y: auto;">
                  <tr *ngFor="let img of photodata.images;let ii=index">
                    <td width=20></td>
                    <td *ngIf="ii==currentIndex" style="font-weight: bold">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=purple>[{{img.seqNo}}] {{img.fileName}}

                    </font></td>
                    <td *ngIf="ii!=currentIndex">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=black size=-1>{{img.seqNo}} {{img.fileName}}
                      
                    </font></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div *ngIf="!galleryready">
            <mat-spinner  diameter='20'></mat-spinner>
            <span> Loading Images....</span> 
          </div>
        </div>
      </div>
      <br> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div fxFlex>
          <mat-label><b>47. {{ "GC.HEAR.TITLE" | translate }}</b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <mat-select formControlName="hear_about_us" placeholder="Select how you heard about us" required>
              <mat-option value="A Friend (Provide Name)">{{ "GC.HEAR.FRIEND" | translate }}</mat-option>
              <mat-option value="Advocate (Provide Name)">{{ "GC.HEAR.ADVOCATE" | translate }}</mat-option>
              <mat-option value="Car Ad">{{ "GC.HEAR.CAR_AD" | translate }}</mat-option>
              <mat-option value="Craigslist">{{ "GC.HEAR.CRAIGSLIST" | translate }}</mat-option>
              <mat-option value="Doctor’s Office (Provide Name)">{{ "GC.HEAR.DOCTOR" | translate }}</mat-option>
              <mat-option value="Facebook">{{ "GC.HEAR.FACEBOOK" | translate }}</mat-option>
              <mat-option value="Family Building Partner (Provide Name)">{{ "GC.HEAR.FAMILY" | translate }}</mat-option>
              <mat-option value="Flyer">{{ "GC.HEAR.FLYER" | translate }}</mat-option>
              <mat-option value="Google Ad">{{ "GC.HEAR.GOOGLE_AD" | translate }}</mat-option>
              <mat-option value="Image Ad on a Website">{{ "GC.HEAR.IMAGE_AD" | translate }}</mat-option>
              <mat-option value="Instagram">{{ "GC.HEAR.INSTAGRAM" | translate }}</mat-option>
              <mat-option value="Internet Research">{{ "GC.HEAR.INTERNET_RESEARCH" | translate }}</mat-option>
              <mat-option value="Podcast">{{ "GC.HEAR.PODCAST" | translate }}</mat-option>
              <mat-option value="Postcard">{{ "GC.HEAR.POSTCARD" | translate }}</mat-option>
              <mat-option value="Recruiter (Provide Name)">{{ "GC.HEAR.RECRUITER" | translate }}</mat-option>
              <mat-option value="TV Ad">{{ "GC.HEAR.TV" | translate }}</mat-option>
              <mat-option value="Other (Specify)">{{ "GC.HEAR.OTHER" | translate }}</mat-option>
            </mat-select>
            <mat-error class="custom-msg" *ngIf="gcform.controls['hear_about_us'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" *ngIf="gcform.controls.hear_about_us.value =='A Friend (Provide Name)' || gcform.controls.hear_about_us.value =='Advocate (Provide Name)' || gcform.controls.hear_about_us.value =='Doctor’s Office (Provide Name)' || gcform.controls.hear_about_us.value =='Family Building Partner (Provide Name)' || gcform.controls.hear_about_us.value =='Recruiter (Provide Name)' || gcform.controls.hear_about_us.value =='Other (Specify)'">
        <div fxFlex>
          <mat-label class="line-height-33"><b>{{ "GENERAL.PROVIDE_NAMES" | translate }} </b></mat-label><br>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="provide_names" maxlength="5000"></textarea>
            <mat-error class="custom-msg" *ngIf="gcform.controls.provide_names.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error class="custom-msg" *ngIf="gcform.controls['provide_names'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showRequiredMessage8">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="errorMessage">
                <b><font color=#E80000>Error: Please fill out all the required fields.</font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="text-align: center;">
        <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
          <tr style="display: flex;"><td style="width: 5% !important;"></td>
            <td style="width: 90% !important;" align=center>
              <div class="missingAnswer">
                <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
              </div>
            </td>
            <td valign=top style="width: 10% !important;">
              <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
            </td>
          </tr>
        </table>
      </div> -->
      <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <div *ngIf="processSubmit" align="center">
          <mat-spinner diameter='20'></mat-spinner>
          <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
        </div>
      </div>
    </div>
    <br> -->
    <!-- <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="button-row" style="text-align: center;">
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab7()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
      <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
      <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
    </div>
  </div> -->
  <!-- <table style="display: inline-block; margin: auto 0; width:100% !important;">
    <div *ngIf="first"> -->
        <!--TEMPORARY DISABLE SPANISH-->
        <!-- <td class="language" colspan=2>
          <mat-form-field appearance="standard" style="width: 250px;">
            <mat-label>{{ "SELECT_LANGUAGE" | translate }}: {{languageDisplay}}</mat-label>
            <mat-select>
              <mat-option type="button" (click)="changeLanguage('en')"
              [ngStyle]="{'font-weight' : language === 'en' ? '900' : '400' }">{{ "ENGLISH" | translate }}</mat-option> -->
              <!-- <mat-option type="button" (click)="changeLanguage('zh-cn')"
              [ngStyle]="{'font-weight' : language === 'zh-cn' ? '900' : '400' }">{{ "SIMPLIFIED_CHINESE" | translate }}</mat-option>
              <mat-option type="button" (click)="changeLanguage('zh-tw')"
              [ngStyle]="{'font-weight' : language === 'zh-tw' ? '900' : '400' }">{{ "TRADITIONAL_CHINESE" | translate }}</mat-option> -->
              <!-- <mat-option type="button" (click)="changeLanguage('es')"
              [ngStyle]="{'font-weight' : language === 'es' ? '900' : '400' }">{{ "SPANISH" | translate }}</mat-option> -->
              <!-- <mat-option type="button" (click)="changeLanguage('it')"
              [ngStyle]="{'font-weight' : language === 'it' ? '900' : '400' }">{{ "ITALIAN" | translate }}</mat-option>
              <mat-option type="button" (click)="changeLanguage('ja')"
              [ngStyle]="{'font-weight' : language === 'ja' ? '900' : '400' }">{{ "JAPANESE" | translate }}</mat-option> -->
            <!-- </mat-select>
          </mat-form-field>
        </td> -->
      <!-- <div align="center">
        <tr style="display: contents;">
            <tr>
              <td>
                <table class="time-table">
                  <td class="time-img"><img style="width: 50px;margin-bottom: -20px" src="../../../assets/images/clockwise.png"></td>
                  <td class="time">
                    <tr><b>Estimated completion time:</b></tr>
                    <tr><span style="font-size: 20px;"><b>5 - 10 minutes</b></span></tr>
                  </td>
                </table>
              </td>
              <td>
                <div>
                  <button style="background-color: #8961a7 !important;" onclick="style.display = 'none'" mat-raised-button class="next_button" type="button" (click)="returnUser()">Returning user?</button>
                </div>
                <div class="return_user" *ngIf="returnuser">
                  <mat-label><b>Enter your email: </b></mat-label><br>
                  <mat-form-field style="margin-left: 0px;" appearance="outline">
                    <input matInput formControlName="returning_user" maxlength="50" placeholder="pat@example.com">
                    <button type="button" mat-icon-button matSuffix (click)="checkCase()">
                      <mat-icon style="color: #8a61a8; margin-top: 15px;">search</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </td>
              <td class="phone" *ngIf="appload">
                <div *ngIf="checkCaseStatus=='SUCCESS'" class="digit_box">
                  <mat-label><b>Please enter the last 4 digits of your phone number: </b></mat-label><br>
                  <mat-form-field style="margin-left: 0px;" appearance="outline">
                    <input matInput formControlName="enter_digits" maxlength="50">
                    <button type="button" mat-icon-button matSuffix (click)="fetchCase()">
                      <mat-icon style="color: #8a61a8; margin-top: 15px;">arrow_forward_ios</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td>
                <div *ngIf="showCheckCaseMessage" class="message">
                  <span *ngIf="checkCaseStatus=='SUCCESS'" style="color: green;"><b>{{checkCaseMessage}}</b></span>
                  <span *ngIf="checkCaseStatus=='FAIL'" style="color: red;"><b>{{checkCaseMessage}}</b></span>
                </div>
                <div *ngIf="showFetchMessage" class="message">
                  <span style="color: red;"><b>{{fetchMessage}}</b></span>
                </div>
              </td>
              <td>
              </td>
            </tr>
            <tr>
            <tr>
              <td>
                <mat-label><b>1. {{ "GENERAL.FIRST_NAME" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <input matInput id="firstName_field" formControlName="firstName" maxlength="50" required>
                  <mat-error class="custom-msg" *ngIf="gcform.controls.firstName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['firstName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-label><b>2. {{ "GENERAL.LAST_NAME" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="lastName" maxlength="50" required>
                  <mat-error class="custom-msg" *ngIf="gcform.controls.lastName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['lastName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-label><b>3. {{ "GENERAL.STATE" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="state" placeholder="Select state" required>
                    <mat-option value="Alabama">Alabama</mat-option>
                    <mat-option value="Alaska">Alaska</mat-option>
                    <mat-option value="Arizona">Arizona</mat-option>
                    <mat-option value="Arkansas">Arkansas</mat-option>
                    <mat-option value="California">California</mat-option>
                    <mat-option value="Colorado">Colorado</mat-option>
                    <mat-option value="Connecticut">Connecticut</mat-option>
                    <mat-option value="Delaware">Delaware</mat-option>
                    <mat-option value="Florida">Florida</mat-option>
                    <mat-option value="Georgia">Georgia</mat-option>
                    <mat-option value="Hawaii">Hawaii</mat-option>
                    <mat-option value="Idaho">Idaho</mat-option>
                    <mat-option value="Illinois">Illinois</mat-option>
                    <mat-option value="Indiana">Indiana</mat-option>
                    <mat-option value="Iowa">Iowa</mat-option>
                    <mat-option value="Kansas">Kansas</mat-option>
                    <mat-option value="Kentucky">Kentucky</mat-option>
                    <mat-option value="Louisiana">Louisiana</mat-option>
                    <mat-option value="Maine">Maine</mat-option>
                    <mat-option value="Maryland">Maryland</mat-option>
                    <mat-option value="Massachusetts">Massachusetts</mat-option>
                    <mat-option value="Michigan">Michigan</mat-option>
                    <mat-option value="Minnesota">Minnesota</mat-option>
                    <mat-option value="Mississippi">Mississippi</mat-option>
                    <mat-option value="Missouri">Missouri</mat-option>
                    <mat-option value="Montana">Montana</mat-option>
                    <mat-option value="Nebraska">Nebraska</mat-option>
                    <mat-option value="Nevada">Nevada</mat-option>
                    <mat-option value="New Hampshire">New Hampshire</mat-option>
                    <mat-option value="New Jersey">New Jersey</mat-option>
                    <mat-option value="New Mexico">New Mexico</mat-option>
                    <mat-option value="New York">New York</mat-option>
                    <mat-option value="North Carolina">North Carolina</mat-option>
                    <mat-option value="North Dakota">North Dakota</mat-option>
                    <mat-option value="Ohio">Ohio</mat-option>
                    <mat-option value="Oklahoma">Oklahoma</mat-option>
                    <mat-option value="Oregon">Oregon</mat-option>
                    <mat-option value="Pennsylvania">Pennsylvania</mat-option>
                    <mat-option value="Rhode Island">Rhode Island</mat-option>
                    <mat-option value="South Carolina">South Carolina</mat-option>
                    <mat-option value="South Dakota">South Dakota</mat-option>
                    <mat-option value="Tennessee">Tennessee</mat-option>
                    <mat-option value="Texas">Texas</mat-option>
                    <mat-option value="Utah">Utah</mat-option>
                    <mat-option value="Vermont">Vermont</mat-option>
                    <mat-option value="Virginia">Virginia</mat-option>
                    <mat-option value="Washington">Washington</mat-option>
                    <mat-option value="West Virginia">West Virginia</mat-option>
                    <mat-option value="Wisconsin">Wisconsin</mat-option>
                    <mat-option value="Wyoming">Wyoming</mat-option>
                    <mat-option value="N/A">N/A</mat-option>
                  </mat-select>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['state'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-label><b>4. {{ "GC.PRIMARY_PHONE" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <input matInput id="telephone_number_field" mask="(000) 000-0000" placeholder="Ex. (000) 000-0000" formControlName="telephone_number" maxlength="50" required>
                  <mat-error class="custom-msg" *ngIf="gcform.controls.telephone_number.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['telephone_number'].invalid">{{ "GENERAL.PHONE_INVALID" | translate }}</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-label><b>5. {{ "GENERAL.DATE_OF_BIRTH" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <input [(ngModel)]="birth_date" id="birth_date_field" formControlName="birthDate" placeholder="mm/dd/yyyy" matInput [matDatepicker]="picker" [min]="minDate" [max]="today" required>
                  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['birthDate'].invalid">{{ "GENERAL.INVALID_DATE" | translate }}</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-label><b>6. {{ "GENERAL.EMAIL" | translate }} </b></mat-label><font color=red>*</font><br>
                <mat-form-field appearance="outline">
                  <input matInput id="email_field" formControlName="email" maxlength="50" placeholder="pat@example.com" (change)="checkEmailMatch()" required>
                  <mat-error class="custom-msg" *ngIf="gcform.controls.email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                  <mat-error class="custom-msg" *ngIf="gcform.controls['email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
                </mat-form-field>
              </td>
            </tr>
            <td class="hide">
              <mat-label><b> {{ "GENERAL.EMAIL_CONFIRM" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="confirm_email" maxlength="50" value="{{this.gcform.controls['confirm_email']}}">
              </mat-form-field>
            </td>
            <br>
      </div> -->
    <!-- </div>
    <div *ngIf="!first" align="center">
      <div class="congrats">
        <p>Congratulations! You've Pre-Qualified.</p>
        <p>You are one step closer to becoming a Surrogate with us!</p>
        <p>Complete The Full Application To Find Out If You’re Eligible! </p>
        <br>
      </div>
<div style="text-align: left" *ngIf="page_two">
  <div class="page-two">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="50">
        <mat-label><b>7. Maiden Name/Other Names Used </b></mat-label><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="maiden_name" maxlength="50">
            <mat-error class="custom-msg" *ngIf="gcform.controls.maiden_name.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          </mat-form-field>
    </div>  
    <div fxFlex="50">
        <mat-label><b>17. {{ "GC.CITIZENSHIP_STATUS.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="citizen" placeholder="Select citizenship status" required>
            <mat-option value="US Citizen">{{ "GC.CITIZENSHIP_STATUS.US_CITIZEN" | translate }}</mat-option>
            <mat-option value="Green Card/Permanent Resident">{{ "GC.CITIZENSHIP_STATUS.PERMANENT_RESIDENT" | translate }}</mat-option>
            <mat-option value="Work Visa">{{ "GC.CITIZENSHIP_STATUS.WORK_VISA" | translate }}</mat-option>
            <mat-option value="Other">{{ "GC.CITIZENSHIP_STATUS.OTHER" | translate }}</mat-option>
          </mat-select>
          <mat-error class="custom-msg" *ngIf="gcform.controls['citizen'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
    </div>  
  </div>
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="33.33">
      <mat-label><b>15. {{ "GENERAL.HEIGHT" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field class="height" appearance="outline">
        <input matInput name="htf" type="number" min="1" maxlength="50" formControlName="height_feet" required>
        <span matSuffix> {{ "GENERAL.FEET" | translate }}</span>
        <mat-error class="custom-msg" *ngIf="gcform.controls.height_feet.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls['height_feet'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls.height_feet.hasError('min')">Enter a positive number</mat-error>
      </mat-form-field>
      <mat-form-field class="height" appearance="outline">
        <input matInput name="hti" type="number" min="1" maxlength="50" formControlName="height_in" required>
        <span matSuffix> {{ "GENERAL.INCHES" | translate }}</span>
        <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls['height_in'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('min')">Enter a positive number</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls.height_in.hasError('max')">Enter a number between 0 and 12</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="33.33">
      <mat-label><b>16. {{ "GENERAL.WEIGHT" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field class="weight" appearance="outline">
        <input matInput name="wt" type="number" min="0" formControlName="weight" maxlength="50" required>
        <span matSuffix> {{ "GENERAL.POUNDS" | translate }}</span>
        <mat-error class="custom-msg" *ngIf="gcform.controls.weight.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls['weight'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        <mat-error class="custom-msg" *ngIf="gcform.controls.weight.hasError('min')">Enter a positive number</mat-error>
      </mat-form-field>
      <mat-form-field class="weight" appearance="outline">
        <mat-label>{{Bmi()}}</mat-label>
        <input matInput type="number" readonly>
        <span matSuffix>{{ "GENERAL.BMI" | translate }}</span>
      </mat-form-field>
    </div>
    <div fxFlex="33.33">
      <mat-label><b>21. What is your ethnic heritage? </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="ethnic_heritage" placeholder="Select ethnic heritage" required>
          <mat-option value="Caucasian">Caucasian</mat-option>
          <mat-option value="Asian">Asian</mat-option>
          <mat-option value="Hispanic or Latina">Hispanic or Latina</mat-option>
          <mat-option value="Black or African American">Black or African American</mat-option>
          <mat-option value="Mixed race">Mixed race</mat-option>
        </mat-select>
        <mat-error class="custom-msg" *ngIf="gcform.controls['ethnic_heritage'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="50">
      <mat-label><b>23. {{ "GC.EDUCATION_LEVEL.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="highest_level_education" placeholder="Select education" required>
          <mat-option value="High school not completed">{{ "GC.EDUCATION_LEVEL.HIGHSCHOOL_NOT_COMPLETED" | translate }}</mat-option>
          <mat-option value="High school completed">{{ "GC.EDUCATION_LEVEL.HIGHSCHOOL_COMPLETED" | translate }}</mat-option>
          <mat-option value="College enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_ENROLLED" | translate }}</mat-option>
          <mat-option value="College in progress or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_PROGRESS" | translate }}</mat-option>
          <mat-option value="College completed or equivalent">{{ "GC.EDUCATION_LEVEL.COLLEGE_COMPLETED" | translate }}</mat-option>
          <mat-option value="Graduate school enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_ENROLLED" | translate }}</mat-option>
          <mat-option value="Graduate school in progress or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_PROGRESS" | translate }}</mat-option>
          <mat-option value="Graduate school completed or equivalent">{{ "GC.EDUCATION_LEVEL.GRADUATEDSCHOOL_COMPLETED" | translate }}</mat-option>
          <mat-option value="PhD enrolled or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_ENROLLED" | translate }}</mat-option>
          <mat-option value="PhD in progress or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_PROGRESS" | translate }}</mat-option>
          <mat-option value="PhD completed or equivalent">{{ "GC.EDUCATION_LEVEL.PHD_COMPLETED" | translate }}</mat-option>
        </mat-select>
        <mat-error class="custom-msg" *ngIf="gcform.controls['highest_level_education'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="50">
      <mat-label class="label-height"><b>24. {{ "GC.OCCUPATION" | translate }} </b></mat-label><br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="occupation" maxlength="50">
        <mat-error class="custom-msg" *ngIf="gcform.controls.occupation.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="50" class="add-bottom-padding">
        <mat-label><b>26. {{ "GC.DRIVERS_LICENSE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="drivers_license" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div fxFlex="50" style="display: inline-table;" class="add-bottom-padding">
        <mat-label><b>20. {{ "GC.SURROGATE_BEFORE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="surrogate_before" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  </div>
  <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab1()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
  <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
  <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab2()'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
</div>

<div *ngIf="page_three">
  <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab2()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
  <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>

      <tr style="display: contents;">
        <table class="table-form">


          <tr>

            <td>
              <mat-label><b>8. {{ "GENERAL.ADDRESS" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="streetAdd" maxlength="500" required>
                <mat-error class="custom-msg" *ngIf="gcform.controls.streetAdd.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['streetAdd'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>9. {{ "GENERAL.CITY" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="city" maxlength="500" required>
                <mat-error class="custom-msg" *ngIf="gcform.controls.city.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['city'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-label><b>10. {{ "GENERAL.STATE" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <mat-label>{{State()}}</mat-label>
                <input matInput type="text" readonly>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>11. {{ "GENERAL.ZIP" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="zip_code" maxlength="50" required>
                <mat-error class="custom-msg" *ngIf="gcform.controls.zip_code.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['zip_code'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td >
              <mat-label><b>12. {{ "GC.PARTNER_NAME" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="spouse_name" maxlength="50" required>
                <mat-error class="custom-msg" *ngIf="gcform.controls.spouse_name.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['spouse_name'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label class="label-height"><b>13. {{ "GC.MARITAL_STATUS.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="marital_status" placeholder="Select marital status" required>
                  <mat-option value="Single">{{ "GC.MARITAL_STATUS.SINGLE" | translate }}</mat-option>
                  <mat-option value="Married">{{ "GC.MARITAL_STATUS.MARRIED" | translate }}</mat-option>
                  <mat-option value="Widowed">{{ "GC.MARITAL_STATUS.WIDOWED" | translate }}</mat-option>
                  <mat-option value="Divorced">{{ "GC.MARITAL_STATUS.DIVORCED" | translate }}</mat-option>
                  <mat-option value="Separated">{{ "GC.MARITAL_STATUS.SEPARATED" | translate }}</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['marital_status'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label class="label-height"><b>14. {{ "GC.PARTNER_EMAIL" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <input matInput formControlName="spouse_email" maxlength="50" placeholder="pat@example.com">
                <mat-error class="custom-msg" *ngIf="gcform.controls.spouse_email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['spouse_email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <mat-label><b>18. {{ "GC.HOW_MANY_CHILDREN.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="children" placeholder="Select number of deliveries" required>
                  <mat-option value="0">0</mat-option>
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="3">3</mat-option>
                  <mat-option value="4">4</mat-option>
                  <mat-option value="5">5</mat-option>
                  <mat-option value="6">6</mat-option>
                  <mat-option value="7 or more">{{ "GC.HOW_MANY_CHILDREN.MORE" | translate }}</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['children'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>19. {{ "GC.INSPIRED_YOU" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="inspired_you" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.inspired_you.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
              </mat-form-field>
            </td>

          </tr>
          <tr>

            <td>
              <div class="add-bottom-padding">
                <mat-label><b>22. {{ "GC.TRIBE.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="tribe" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
          </tr>
          <tr>

          </tr>
          <tr>
            <td>
              <div class="add-bottom-padding">
                <mat-label><b>25. {{ "GC.SMOKE" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="smoke" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
            <td *ngIf="gcform.controls.smoke.value == 'Yes'">
              <mat-label style="margin-left: 6px;"><b>{{ "GC.YES_SMOKE" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="explain_smoke" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.explain_smoke.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['explain_smoke'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>

          </tr>
          <tr>
            <td>
              <mat-label class="label-height-28"><b>27. {{ "GC.PARTNER_LIVING" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="partner_living" maxlength="50"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.partner_living.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label class="label-height-28"><b>28. {{ "GC.LIST_EVERYONE" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="list_everyone" maxlength="5000" required></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.list_everyone.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['list_everyone'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label class="label-height-28"><b>29. {{ "GC.MAIN_SUPPORT" | translate }} </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="main_support" maxlength="50" required></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.main_support.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['main_support'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <div class="add-bottom-padding">
                <mat-label><b>30. {{ "GC.MAJOR_CASES" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="major_cases" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
            <td>
              <div class="add-bottom-padding">
                <mat-label><b>31. {{ "GC.FILLING_DIVORCE" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="filling_divorce" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
            <td style="display: inline-table;">
              <div class="add-bottom-padding">
                <mat-label><b>32. {{ "GC.FELONY_YOU" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="felony" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="add-bottom-padding">
                <mat-label><b>33. {{ "GC.FELONY_YOU_PARTNER.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
                <mat-radio-group formControlName="felony_partner" required>
                    <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
                    <mat-radio-button class="add-right-padding" value="N/A">N/A</mat-radio-button>
                </mat-radio-group>
              </div>
            </td>
            <td>
              <mat-label><b>34. {{ "GC.HEAR.TITLE" | translate }}  </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="hear_about_us" placeholder="Select how you heard about us" required>
                  <mat-option value="A Friend (Provide Name)">{{ "GC.HEAR.FRIEND" | translate }}</mat-option>
                  <mat-option value="Advocate (Provide Name)">{{ "GC.HEAR.ADVOCATE" | translate }}</mat-option>
                  <mat-option value="Car Ad">{{ "GC.HEAR.CAR_AD" | translate }}</mat-option>
                  <mat-option value="Craigslist">{{ "GC.HEAR.CRAIGSLIST" | translate }}</mat-option>
                  <mat-option value="Doctor’s Office (Provide Name)">{{ "GC.HEAR.DOCTOR" | translate }}</mat-option>
                  <mat-option value="Facebook">{{ "GC.HEAR.FACEBOOK" | translate }}</mat-option>
                  <mat-option value="Family Building Partner (Provide Name)">{{ "GC.HEAR.FAMILY" | translate }}</mat-option>
                  <mat-option value="Flyer">{{ "GC.HEAR.FLYER" | translate }}</mat-option>
                  <mat-option value="Google Ad">{{ "GC.HEAR.GOOGLE_AD" | translate }}</mat-option>
                  <mat-option value="Image Ad on a Website">{{ "GC.HEAR.IMAGE_AD" | translate }}</mat-option>
                  <mat-option value="Instagram">{{ "GC.HEAR.INSTAGRAM" | translate }}</mat-option>
                  <mat-option value="Internet Research">{{ "GC.HEAR.INTERNET_RESEARCH" | translate }}</mat-option>
                  <mat-option value="Podcast">{{ "GC.HEAR.PODCAST" | translate }}</mat-option>
                  <mat-option value="Postcard">{{ "GC.HEAR.POSTCARD" | translate }}</mat-option>
                  <mat-option value="Recruiter (Provide Name)">{{ "GC.HEAR.RECRUITER" | translate }}</mat-option>
                  <mat-option value="TV Ad">{{ "GC.HEAR.TV" | translate }}</mat-option>
                  <mat-option value="Other (Specify)">{{ "GC.HEAR.OTHER" | translate }}</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['hear_about_us'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td *ngIf="gcform.controls.hear_about_us.value =='A Friend (Provide Name)' || gcform.controls.hear_about_us.value =='Advocate (Provide Name)' || gcform.controls.hear_about_us.value =='Doctor’s Office (Provide Name)' || gcform.controls.hear_about_us.value =='Family Building Partner (Provide Name)' || gcform.controls.hear_about_us.value =='Recruiter (Provide Name)' || gcform.controls.hear_about_us.value =='Other (Specify)'">
              <mat-label class="line-height-33"><b>{{ "GENERAL.PROVIDE_NAMES" | translate }} </b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="provide_names" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.provide_names.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['provide_names'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-label><b>35. Have you ever had any miscarriages or abortions? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="miscarriages" placeholder="Please select" required>
                  <mat-option value="No">No</mat-option>
                  <mat-option value="Yes">Yes</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td *ngIf="gcform.controls.miscarriages.value == 'Yes'">
              <mat-label class="label-height-53"><b>If yes, please explain.</b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="explain_miscarriages" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.explain_miscarriages.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['explain_miscarriages'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </tr>
      <tr style="display: contents;">
        <div><h1><b>Delivery History</b></h1></div>
        <div class="delivery-table" *ngIf="ctrl_list_pregnancy_historys!=null">
          <ng-container *ngFor="let lh of ctrl_list_pregnancy_historys.controls; let idx=index" [formGroup]="lh">
            <table>
              <tr><u><b>Delivery History <font color=purple>{{idx + 1 }}</font></b></u><button mat-icon-button color="warn" (click)="remove_pregnancy(idx)"><img style="width: 24px;" src="../../../assets/images/bin.png"></button></tr>
              <tr>
                <td>
                  <mat-label><b>Delivery History<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-label>{{idx + 1}}</mat-label>
                    <input formControlName="history" matInput type="number" readonly>
                  </mat-form-field>
                </td>
                <td>
                  <mat-label><b>Outcome<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="outcome" placeholder="Please select" required>
                      <mat-option value="Singleton delivery">Singleton delivery</mat-option>
                      <mat-option value="Twins or more delivery">Twins or more delivery</mat-option>
                      <mat-option value="Stillbirth">Still birth</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['outcome'].touched && lh.controls['outcome'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-label><b>Delivery Year/Month (e.g. 2020/6) <font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <input #dpInput formControlName="delivery_date" matInput [matDatepicker]="dp" [min]="minDate" [max]="today" required>
                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dp startView="multi-year" (monthSelected)="monthSelected($event, dp, dpInput,lh)"></mat-datepicker>
                    <mat-error class="custom-msg" *ngIf="lh.controls['delivery_date'].touched && lh.controls['delivery_date'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label><b>Delivery Methods<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="delivery_method" placeholder="Please select" required>
                      <mat-option value="C_Section">C-Section</mat-option>
                      <mat-option value="Vaginal">Vaginal</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['delivery_method'].touched && lh.controls['delivery_method'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-label><b>Surrogacy Pregnancy<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Surrogacy_Pregnancy" placeholder="Please select" required>
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['Surrogacy_Pregnancy'].touched && lh.controls['Surrogacy_Pregnancy'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-label><b>Gestational Week at Birth<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Pregnancy_Week_Birth" placeholder="Please select" required>
                      <mat-option value="Less than 34 weeks">Less than 34 weeks</mat-option>
                      <mat-option value="34-36 weeks">34-36 weeks</mat-option>
                      <mat-option value="37-42 weeks">37-42 weeks</mat-option>
                      <mat-option value="More than 42 weeks">More than 42 weeks</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['Pregnancy_Week_Birth'].touched && lh.controls['Pregnancy_Week_Birth'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label><b>Newborn Weight at Birth<font color=red> *</font></b></mat-label><br>
                  <table class="newborn_table">
                    <tr>
                      <td class="newborn_column">
                        <mat-form-field class="newborn_lbs" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight" type="text" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}' required><span matSuffix>lbs</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight'].touched && lh.controls['Newborn_weight'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight'].hasError('min')">Enter a positive number</mat-error>
                        </mat-form-field> 
                      </mat-form-field> 
                        </mat-form-field> 
                      </td>
                      <td class="newborn_column">
                        <mat-form-field class="newborn_oz" appearance="outline">
                          <input matInput placeholder="0" min="0" formControlName="Newborn_weight_oz" type="text" maxlength="3" onkeypress='(event)=>{return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 89 && event.charCode >= 80;}' required><span matSuffix>oz</span>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight_oz'].touched && lh.controls['Newborn_weight_oz'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                          <mat-error class="custom-msg" *ngIf="lh.controls['Newborn_weight_oz'].hasError('min')">Enter a positive number</mat-error>
                        </mat-form-field>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <mat-label><b>Pregnancy Medical Conditions<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Pregnancy_Medical" placeholder="Please select" required multiple>
                      <mat-option value="N/A">N/A</mat-option>
                      <mat-option value="Diabetes">Diabetes</mat-option>
                      <mat-option value="Heart Problem">Heart Problem</mat-option>
                      <mat-option value="High Blood Pressure">High Blood Pressure</mat-option>
                      <mat-option value="Ovarian Cysts">Ovarian Cysts</mat-option>
                      <mat-option value="Migraine Headaches">Migraine Headaches</mat-option>
                      <mat-option value="Asthmatic Symptoms">Asthmatic Symptoms</mat-option>
                      <mat-option value="Uterine Fibroids">Uterine Fibroids</mat-option>
                      <mat-option value="Thyroid Problem">Thyroid Problem</mat-option>
                      <mat-option value="Others">Others</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['Pregnancy_Medical'].touched && lh.controls['Pregnancy_Medical'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-label><b>Severe Pregnancy Complications<font color=red> *</font></b></mat-label><br>
                  <mat-form-field style="margin-left: 3px;" appearance="outline">
                    <mat-select formControlName="Severe_Pregnancy" placeholder="Please select" required multiple>
                      <mat-option value="N/A">N/A</mat-option>
                      <mat-option value="Intrauterine Growth Restriction">Intrauterine Growth Restriction</mat-option>
                      <mat-option value="Eclampsia">Eclampsia</mat-option>
                      <mat-option value="Pre-eclampsia">Pre-eclampsia</mat-option>
                      <mat-option value="Oligohydramnios">Oligohydramnios</mat-option>
                    </mat-select>
                    <mat-error class="custom-msg" *ngIf="lh.controls['Severe_Pregnancy'].touched && lh.controls['Severe_Pregnancy'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </ng-container>
        </div>
      </tr>
      <br>
      <div align="left">
        <button mat-raised-button class="next_button" type="button" color="warn" (click)="add_pregnancy()">{{ "GENERAL.ADD_MORE" | translate }} <mat-icon>add_box</mat-icon></button>
      </div>
      <br><br>
      <div align="left" class="explain_box">
        <mat-label><b>Please write your explanation as needed: </b></mat-label><br>
        <mat-form-field style="margin-left: -3px;" class="box" appearance="outline">
          <textarea matInput formControlName="explainDelivery" maxlength="5000"></textarea>
          <mat-error class="custom-msg" *ngIf="gcform.controls.explainDelivery.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <tr style="display: contents;">
        <div><h1><b>Substance use and Anti-depressant disclosure</b></h1></div>
        <table class="table-form">
          <tr>
            <td>
              <mat-label class="label-height"><b>36. Do you drink alcohol? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
              <mat-select formControlName="substance_alcohol" placeholder="Please select" required>
                <mat-option value="No">No</mat-option>
                <mat-option value="Rarely">Rarely</mat-option>
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Daily">Daily</mat-option>
              </mat-select>
              <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>37. Do you use recreational drugs such as marijuanna? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
              <mat-select formControlName="substance_drugs" placeholder="Please select">
                <mat-option value="No">No</mat-option>
                <mat-option value="Rarely">Rarely</mat-option>
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Daily">Daily</mat-option>
              </mat-select>
              <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-label class="label-height"><b>38. Do you smoke? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
              <mat-select formControlName="substance_smoke" placeholder="Please select" required>
                <mat-option value="No">No</mat-option>
                <mat-option value="Rarely">Rarely</mat-option>
                <mat-option value="Weekly">Weekly</mat-option>
                <mat-option value="Daily">Daily</mat-option>
              </mat-select>
              <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>39. Are you currently taking prescribed antidepressants? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
              <mat-select formControlName="substance_antidepressan" placeholder="Please select" required>
                <mat-option value="No">No</mat-option>
                <mat-option value="Yes">Yes</mat-option>
              </mat-select>
              <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>40. Are you currently taking any of the following substances? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
              <mat-select formControlName="substance" placeholder="Please select" multiple required>
                <mat-option value="N/A">N/A</mat-option>
                <mat-option value="Amphetamines">Amphetamines</mat-option>
                <mat-option value="Barbiturates">Barbiturates</mat-option>
                <mat-option value="Cocaine">Cocaine</mat-option>
                <mat-option value="Hallucinogens">Hallucinogens</mat-option>
                <mat-option value="Heroin">Heroin</mat-option>
                <mat-option value="LSD">LSD</mat-option>
                <mat-option value="Narcotics">Narcotics</mat-option>
                <mat-option value="Opiates">Opiates</mat-option>
                <mat-option value="PCP">PCP</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error class="custom-msg">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </tr>
      <tr style="display: contents;">
        <div><h1><b>Health Information</b></h1></div>
        <table class="table-form">
          <tr>
            <td>
              <mat-label><b>41. Have you had GYN surgeries involving reproductive organs? </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="gyn" placeholder="Select GYN surgeries" multiple required>
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-option value="Ovarian Cystectomy">Ovarian Cystectomy</mat-option>
                  <mat-option value="Fibroid Removal">Fibroid Removal</mat-option>
                  <mat-option value="Salpingectomy">Salpingectomy</mat-option>
                  <mat-option value="Oophorectomy">Oophorectomy</mat-option>
                  <mat-option value="Endometrial Ablation">Endometrial Ablation</mat-option>
                  <mat-option value="Uterine Artery Embolization">Uterine Artery Embolization</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['gyn'].touched && gcform.controls['gyn'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>42. Current Birth Control </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="birth_control" placeholder="Select birth control" required>
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-option value="Abstinence">Abstinence</mat-option>
                  <mat-option value="Birth Control Patch">Birth Control Patch</mat-option>
                  <mat-option value="Birth Control Pills">Birth Control Pills</mat-option>
                  <mat-option value="Condoms">Condoms</mat-option>
                  <mat-option value="Depo Shot">Depo Shot</mat-option>
                  <mat-option value="Depo-Provera Shot">Depo-Provera Shot</mat-option>
                  <mat-option value="Essure">Essure</mat-option>
                  <mat-option value="IUD - Copper (Paraguard)">IUD - Copper (Paraguard)</mat-option>
                  <mat-option value="IUD - Mirena, Skyla, or Liletta">IUD - Mirena, Skyla, or Liletta</mat-option>
                  <mat-option value="Implanon">Implanon</mat-option>
                  <mat-option value="Nexplanon">Nexplanon</mat-option>
                  <mat-option value="Nexplanon or Arm Implant">Nexplanon or Arm Implant</mat-option>
                  <mat-option value="NuvaRing">NuvaRing</mat-option>
                  <mat-option value="Other">Other</mat-option>
                  <mat-option value="Partner Vasectomy">Partner Vasectomy</mat-option>
                  <mat-option value="Tubal Ligation">Tubal Ligation</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['birth_control'].touched && gcform.controls['birth_control'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr><td colspan="2"><p>Are you or your partner/spouse <b>CURRENTLY</b> diagnosed with the following:</p></td></tr>
          <tr>
            <td>
              <mat-label><b>43. Transmitted Disease - Yourself </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="treat_yourself" placeholder="Please select" multiple required>
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-option value="AIDS">AIDS</mat-option>
                  <mat-option value="Genital Herpes">Genital Herpes</mat-option>
                  <mat-option value="Genital Warts">Genital Warts</mat-option>
                  <mat-option value="Hepatisis B">Hepatitis B</mat-option>
                  <mat-option value="Hepatisis C">Hepatitis C</mat-option>
                  <mat-option value="Herpes">Herpes</mat-option>
                  <mat-option value="HIV">HIV</mat-option>
                  <mat-option value="HPV">HPV</mat-option>
                  <mat-option value="Syphilis">Syphilis</mat-option>
                  <mat-option value="Trichomonas">Trichomonas</mat-option>
                  <mat-option value="Chlamydia">Chlamydia</mat-option>
                  <mat-option value="Gonorrhea">Gonorrhea</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['treat_yourself'].touched && gcform.controls['treat_yourself'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>44. Transmitted Disease - Your Partner </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="treat_yourpartner" placeholder="Please select" multiple required>
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-option value="AIDS">AIDS</mat-option>
                  <mat-option value="Genital Herpes">Genital Herpes</mat-option>
                  <mat-option value="Genital Warts">Genital Warts</mat-option>
                  <mat-option value="Hepatisis B">Hepatitis B</mat-option>
                  <mat-option value="Hepatisis C">Hepatitis C</mat-option>
                  <mat-option value="Herpes">Herpes</mat-option>
                  <mat-option value="HIV">HIV</mat-option>
                  <mat-option value="HPV">HPV</mat-option>
                  <mat-option value="Syphilis">Syphilis</mat-option>
                  <mat-option value="Trichomonas">Trichomonas</mat-option>
                  <mat-option value="Chlamydia">Chlamydia</mat-option>
                  <mat-option value="Gonorrhea">Gonorrhea</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['treat_yourpartner'].touched && gcform.controls['treat_yourpartner'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-label><b>45. Latest Pap Smear Date </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <input formControlName="latest_date" matInput [matDatepicker]="picker" [min]="minDate" [max]="today" required>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error class="custom-msg" *ngIf="gcform.controls['latest_date'].touched && gcform.controls['latest_date'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-label><b>46. Latest Pap Results </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select formControlName="latest_result" placeholder="Select result" required>
                  <mat-option value="Normal">Normal</mat-option>
                  <mat-option value="Abnormal">Abnormal</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['latest_result'].touched && gcform.controls['latest_result'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-label><b>47. Currently breast feeding </b></mat-label><font color=red>*</font><br>
              <mat-form-field appearance="outline">
                <mat-select  formControlName="breast_feeding" placeholder="Please select" required>
                  <mat-option value="No">No</mat-option>
                  <mat-option value="Yes">Yes</mat-option>
                </mat-select>
                <mat-error class="custom-msg" *ngIf="gcform.controls['breast_feeding'].touched && gcform.controls['breast_feeding'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
            <td *ngIf="gcform.controls.breast_feeding.value =='Yes'">
              <mat-label><b>If yes, approximately when to finish? (Month/Year) </b></mat-label><br>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="explain_bf" maxlength="5000"></textarea>
                <mat-error class="custom-msg" *ngIf="gcform.controls.explain_bf.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
                <mat-error class="custom-msg" *ngIf="gcform.controls['explain_bf'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>
        <div class="photos_section">
          <span style="width: 100%; word-break: break-word;">
            <b><p>48. Photos (optional)</p></b>
            <p>We would love to present the best of you. You can upload up to 20 photos taken from different time periods in your life.</p>
          </span>
        </div>
        <div class="photo-button-row">
          <button mat-raised-button type="button" color="primary" class="addphoto_button" [disabled]='!galleryready' (click)="addPhoto()">Add Photos <mat-icon>photo</mat-icon></button>
        </div>
        <br>
        <br>
          <table width=95%>
            <tr>
              <td width=60%>
                <div *ngIf="galleryready&&photoimages.length==0">
                  [No Photos Uploaded]
                </div>
                <div *ngIf="galleryready&&photoimages.length>0">
                  <ks-carousel [id]="116" [images]="photoimages" (show)="onChangeImage($event)"
                    [carouselConfig]="{maxWidth: '350px', maxHeight: '350px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
                    [previewConfig]="{visible: true, number: 14, width: 'auto', maxHeight: '40px'}">
                  </ks-carousel>
                </div>
              </td>
              <td valign=top *ngIf="galleryready&&photoimages.length>0">
                <table style="max-height: 450px; min-width: 350px; display: table-caption; overflow-y: auto;">
                  <tr *ngFor="let img of photodata.images;let ii=index">
                    <td width=20></td>
                    <td *ngIf="ii==currentIndex" style="font-weight: bold">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=purple>[{{img.seqNo}}] {{img.fileName}}

                    </font></td>
                    <td *ngIf="ii!=currentIndex">
                      <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                      <font color=black size=-1>{{img.seqNo}} {{img.fileName}}
                      
                    </font></td>
                  </tr>
            
                </table>
              </td>
            </tr>
          </table>
          
          <div *ngIf="!galleryready">
            <mat-spinner  diameter='20'></mat-spinner>
            <span> Loading Images....</span> 
          </div> -->

          <!-- EDITED CODE -->
                  <!-- <ks-carousel [id]="116" [images]="photoimages" (show)="onChangeImage($event)"
                  [carouselConfig]="{maxWidth: '400px', maxHeight: '400px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
                  [previewConfig]="{visible: true, number: 14, width: 'auto', maxHeight: '40px'}">
                </ks-carousel>
              </div>
            </td>
            <td valign=top class="photo-list">

              <table style="height: 400px; width: 210px; display: contents; overflow-y: auto;">

                <tr *ngFor="let img of photodata.images;let ii=index">
                  <td *ngIf="ii==currentIndex"><font color=red>[ {{img.seqNo}} ]</font></td>
                  <td *ngIf="ii!=currentIndex"><font color=#8961a7 size=-1>{{img.seqNo}}</font></td>
                  <td><font color=#8961a7 size=-1>{{img.fileName}}</font></td>
                  <td>
                    <button mat-icon-button color="warn" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table> -->
          <!-- EDITED CODE -->

            <!-- <table width=90%>
              <tr align=center>
                <td width=13%> </td>
                <td align=center>
                <div class="my-class" *ngIf="buildGallery">
                    <mat-spinner  diameter='20'></mat-spinner>
                    <span> {{ "RP.GENERAL.LOADING_IMAGE" | translate }} </span> 
                </div>
                <div class="my-class" *ngIf="nophotos">
                  <span ><font color=green> {{ "RP.GENERAL.NO_PHOTOS" | translate }} </font></span> 
               </div>
               <div class="my-class" *ngIf="galleryready" >
                <ks-modal-gallery [id]="100" [plainGalleryConfig]="galley_config" 
                [modalImages]="photoimages" [buttonsConfig]="customButtonsConfig" 
                [slideConfig]="{infinite: true, sidePreviews: {show: false}}"
                [enableCloseOutside]="false"
                [previewConfig]="{visible: true, number:4}"></ks-modal-gallery>
               </div>
                </td>
              </tr>
              <tr>
                <td width=10%> </td>
                <td align=center>
                  <img src="https://i.ibb.co/2ctDzgx/Upload-photos.png" width="250px" height="130px" class="cursor" (click)="showAddPhotoDialog()">
                </td>
              </tr>
            </table>
            <div style="visibility:hidden;height:1pt"> <input matInput formControlName="photoes" maxlength="10"  required></div>  -->
      <!-- </tr>
      <br><br>
    </div>
    </div>
  </table>
  <div *ngIf="page_three">
  <div class="button-row" *ngIf="first">
    <div *ngIf="processSubmit" >
      <mat-spinner diameter='20'></mat-spinner>
      <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
    </div>

    <div *ngIf="recordexists">
      <font size=+1 color=green>{{message}}</font> <br>
    </div>
  </div>
  <div style="text-align: center;">
    <table width=100% *ngIf="showValidationMessage || showValidationMessageLP || showValidationMessageLH || showValidationMessageLD">
      <tr style="display: flex;"><td style="width: 5% !important;"></td>
        <td style="width: 90% !important;" align=center>
          <div class="missingAnswer">
            <b>Missing answer(s): <font color=#8961a7 *ngFor="let msg of validationmsgs;let ii=index">{{msg}}, </font><font color=#8961a7 *ngFor="let msg of validationmsgsLP;let ii=index">{{msg}}, </font></b>
          </div>
        </td>
        <td valign=top style="width: 10% !important;">
          <button class="my-fab" mat-mini-fab color="warn" (click)="closeValidation()"><mat-icon>close</mat-icon></button>
        </td>
      </tr>
    </table>
  </div>
  <br>
  <div style="text-align: center;">
    <table width=100% *ngIf="showErrorMessage">
      <tr style="display: flex;"><td style="width: 5% !important;"></td>
        <td style="width: 90% !important;" align=center>
          <div class="errorMessage">
            <b><font color=#E80000>Error: The number of deliveries in the Delivery History table does not match your answer to question 18.</font></b>
          </div>
        </td>
        <td valign=top style="width: 10% !important;">
          <button class="my-fab" mat-mini-fab color="warn" (click)="closeError()"><mat-icon>close</mat-icon></button>
        </td>
      </tr>
    </table>
  </div>
  <br>
  <div class="button-row" *ngIf="!first">
    <div *ngIf="processSubmit" align="center">
      <mat-spinner diameter='20'></mat-spinner>
      <span><font size=+1 color=#8961a7>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
    </div>
    <button mat-raised-button class="back_button" type="button" color="warn" (click)='backTab1()'><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
    <button mat-raised-button class="back_button" type="button" color="warn" (click)='saveData()'>SAVE <mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" class="bottom_buttons" type="submit" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
  </div>
</div> -->
</form>
</div>
<div class="container" *ngIf="serviceapierror">
  <font color=red>This page is temporarily unavailable and will be restored ASAP; please try again later. If the problem persists for you, please email rpsupport@everleagues.com.</font>
</div>
<br>