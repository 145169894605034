import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService, AlertService } from '@app/core/services';
import { RPUser } from '@app/core/models';
@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
    orgnization:any ={};
    form: FormGroup;
    loading = false;
    submitted = false;
    regForm: FormGroup;
    failedmessage ="";
    registerfailed=false;
    confirmedPasswordError=false;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private service: AuthService,
        private alertService: AlertService
    ) { 
        this.regForm = new FormGroup ({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            accountType: new FormControl(''),
            orgUnitId: new FormControl(''),
            email: new FormControl('', [Validators.email, Validators.required]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmPassword: new FormControl('', Validators.required)
          },{ validators: this.checkPasswords.bind(this) });
          this.service.org.subscribe(orgvalue=>{
            this.orgnization=orgvalue;
            console.log(this.orgnization);
            this.regForm.get('accountType').setValue(this.orgnization.accountType);
            this.regForm.get('orgUnitId').setValue(this.orgnization.orgUnitId);
          });
         
    }

    ngOnInit() {
        // here we should have cached org object
        console.log(this.regForm.value);
    }
 
    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
               const password = group.get('password').value;
               const confirmPassword = group.get('confirmPassword').value;
               if(confirmPassword && confirmPassword!=password)
               {
                   this.confirmedPasswordError=true;
               } else
               {
                   this.confirmedPasswordError=false;
               }
                return password === confirmPassword ? null : { notSame: true }     
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    register(){
        this.registerfailed=false;
        this.failedmessage="";
        let user: RPUser = new RPUser();
        user.accountType=this.regForm.get("accountType").value;
        user.phone="000000000";
        user.firstName=this.regForm.get("firstName").value;
        user.lastName=this.regForm.get("lastName").value;
        user.password=this.regForm.get("password").value;
        user.emailId=this.regForm.get("email").value;
        user.orgUnitId=this.orgnization.orgUnitId;
        user.orgName=this.orgnization.tenantName;
        this.service.register(user).subscribe(resp=>{
          let res:any =resp;
          console.log(res);
          if(res.status=="FAIL"){
            this.registerfailed=true;
            this.failedmessage=res.message;
          } else {
            this.router.navigate(["/"+this.service.getOrgName()+'/login'])
          }
        });
      }
   
}