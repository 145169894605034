export const environment = {
    production: false,
    // apiUrl: "https://elrpprescreeningapi.everleagues.com"
    //apiUrl: "https://elrpprescreeningapi.everleagues.com",
    apiUrl:"https://elrpprescreeningapi-lab.azurewebsites.net",
    psapiUrl:"https://elrppsform1api-lab1.azurewebsites.net",
    GA_DONOR_TRACKING_ID:"UA-194696684-1",
    GA_GC_TRACKING_ID:"UA-113473572-1",
    //GADS_GC_TRACKING_ID:"AW-948883577",
    GADS_GC_TRACKING_ID:"AW-16543270642",
    GADS_GC_QUALIFY_LEAD_CONVERSION:"AW-16543270642/25XrCPWEraoZEPKNudA9",
    GADS_GC_TRACKING_NEXT_EVENT:"AW-948883577/66YqCP3cqsgDEPmgu8QD",
    GADS_GC_TRACKING_THANK_YOU_EVENT:"AW-948883577/cyqrCOvu1NICEPmgu8QD",
    GADS_GC_TRACKING_THANK_YOU_EVENT_NEW:"AW-16543270642/UbDoCOuLp6oZEPKNudA9",
    GADS_GC_TRACKING_SUBMISSION_EVENT:"AW-948883577/N3s_CJqk6JYZEPmgu8QD",
    REFERRAL_GC_TRACKING_LIBRARY_URL:"https://portal.simplifyreferrals.com/assets/js/api.js",
    REFERRAL_GC_TRACKING_KEY:"9bF6rr8yUuYOozj1chAoeUSDnwNzx8",
    REFERRAL_DONOR_TRACKING_LIBRARY_URL:"https://portal.simplifyreferrals.com/assets/js/api.js",
    REFERRAL_DONOR_TRACKING_KEY:"9bF6rr8yUuYOozj1chAoeUSDnwNzx8",
    FB_DONOR_TRACKING_ID:"686614512590889",
    FB_GC_TRACKING_ID:"1149959979130461",
    GA_DONOR_SINGUP_EVENT:"AW-385648607/aKVwCLHE0v0YEN-P8rcB",
    GA_DONOR_NEXT_BUTTON:"AW-16510631400/IJyKCPvQ6KAZEOj78MA9",
    GA_DONOR_PREQUALIFICATION_TRACKING:"AW-16510631400/1z4lCLPc6KAZEOj78MA9"
};
