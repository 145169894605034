import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { RPUser } from '@app/core/models';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private userSubject: BehaviorSubject<RPUser>;
    public user: Observable<RPUser>;
    private orgSubject: BehaviorSubject<any>;
    public org: Observable<any>;
    public apiBase = environment.apiUrl+"/api/v1/";
    public apiPS = environment.psapiUrl+"/api/v1/";
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<RPUser>(JSON.parse(localStorage.getItem('rpuser')));
        this.user = this.userSubject.asObservable();
        this.orgSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('org')));
        this.org=this.orgSubject.asObservable();
    }

    public get userValue(): RPUser {
        return this.userSubject.value;
    }
    public get orgValue(): any{
        return this.orgSubject.value;
    }

    public queryOrg(orgName){
        const url=this.apiBase+'auth/tenant/'+orgName;
        console.log(url);
        return this.http.get(url).pipe(map(rr=>{
            let res:any=rr;
            console.log(res);
            if(res.status=="SUCCESS"&&res.data){
                let _org=res.data;
                localStorage.setItem('org',JSON.stringify(_org));
                this.orgSubject.next(_org);
                const user=this.userValue;
                if(user){
                    // switch to user name later
                    if(_org.orgUnitId!==user.orgUnitId){
                        console.log("clear user object cache");
                        localStorage.removeItem('rpuser');
                        this.userSubject.next(null);
                    }
                }
            } else {
                localStorage.setItem('org',null);
                this.orgSubject.next(null);
            }
            return res;
        }));
    }

    getRPHeaders() {
        const token = this.userValue.authToken;
        let header = new HttpHeaders();
        header = header.append('Content-Type', 'application/json');
        header = header.append('Accept', 'application/json');
        header = header.append('Authorization', 'Bearer ' + token);
        console.log(header);
        return { 'headers': header };
      }
   
    login(username, password) {
        const loginURL=this.apiBase+"Auth/tenant/"+this.orgValue.tenantName;
        return this.http.post(loginURL,{
            "emailId": username,   "securePassword":password
        }).pipe(map(rr => {
            let resp:any=rr;
            console.log(resp);
            let user=resp.data;
            // login successful if there's a jwt token in the response
            if (resp && resp.data.authToken) {
                let user=resp.data;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.userSubject.next(user);
            }

            return user;
        }))
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('rpuser');
       // localStorage.removeItem('org');
        this.userSubject.next(null);
       // this.orgSubject.next(null);
        this.router.navigate(['/'+this.orgValue.tenantName+'/login']);
    }

    register(user: RPUser) {
       
            const url=this.apiBase+"Registration/tenant/"+this.orgValue.tenantName;
            
            const json={
                "userId":"",
                "firstName": user.firstName,
                "lastName": user.lastName,
                "accountType": user.accountType,
                "emailId": user.emailId,
                "securePassword":user.password,
                "orgUnitId":user.orgUnitId,
                "mobileNumber": user.phone,
                "isActive": "Y"
            }
            console.log(json);
            return this.http.post(url, json);
       
    }

    getUserId(){
        if(this.userValue){
            return this.userValue.userId;
        } else {
            return null;
        }
    }

    getOrgName(){
        if(this.orgValue){
            return this.orgValue.tenantName;
        } else {
            return null;
        }
    }

    getUploadOptions() {
       
        let header = new HttpHeaders();
       // header = header.append('Authorization', 'Bearer ' + authdata.accessToken);
       // console.log(header);
        return { 'headers': header };
      }
}