import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@app/services/dataservice.service';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-ps-authorization-form',
  templateUrl: './ps-authorizationform.component.html',
  styleUrls: ['./ps-authorizationform.component.scss']
})
export class AuthorizationFormDialog implements OnInit {
  signatureImg="";
  todaystr=this._dt2str(new Date());
  tenantName="";
  values:any={
  };
  @ViewChild("sigimgref") sigimgref: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AuthorizationFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataservice: DataService
  ) {
    this.initValues();
    
  }

  ngOnInit() {
    
  }

  initValues(){
    this.values=this.data.values;
    this.tenantName=this.data.tenant;
    this.dataservice.getSignatureImageDataURL(this.tenantName,this.values.esign).subscribe((res:any)=>{
      console.log(res);
      this.signatureImg="data:image/png;base64,"+res.signatureFontDataUrls[0];
    });
  }
 
  _dt2str(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
  }

  generatePDF(){
    let  doc:any = new jsPDF('p', 'pt', 'a4',true);
    autoTable(doc,{ html: '#authform'});
    let ypos=doc.lastAutoTable.finalY;
    doc.addImage(this.sigimgref.nativeElement,'JPEG', 40,ypos);
    ypos=ypos+60;
    autoTable(doc,{html:"#authdate",startY:ypos});
    doc.save("authform.pdf");
  }

  savePDF(){
      let  doc:any = new jsPDF('p', 'pt', 'a4',true);
      autoTable(doc,{ html: '#authform'});
      let ypos=doc.lastAutoTable.finalY;
      doc.addImage(this.sigimgref.nativeElement,'JPEG', 40,ypos);
      ypos=ypos+60;
      autoTable(doc,{html:"#authdate",startY:ypos});
      let tenant=this.data.tenant;
      let caseId=this.data.caseId;
      let type="surrogate-auth-form";
      
      this.dataservice.uploadPSAuthForm(tenant,caseId,type,doc).subscribe(res=>{
          console.log(res);
          this.dialogRef.close(res);
      });

  }
}
