import { Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, SessionService } from '@app/core/services';
import { DataService } from '@app/services/dataservice.service';
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GCApprovalViewComponent } from '../approvalview/gcapprovalview.component';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { UploadDocPopupComponent } from '../uploaddocpopup/uploaddocpopup.component';
import { AuthorizationFormDialog } from '../authorizationform/ps-authorizationform.component';

import * as moment from 'moment';
import { AlbumComponent } from '../album/album.component';
import { UploadImagePopupComponent } from '../uploadimagepopup/uploadimagepopup.component';


import {PlainGalleryConfig, AccessibilityConfig, Image, ButtonEvent,ImageEvent,ButtonsConfig,ButtonsStrategy,ButtonType,  PlainGalleryStrategy, LineLayout } from '@ks89/angular-modal-gallery';
import { DomSanitizer } from '@angular/platform-browser';
import { subscribeOn } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { state } from '@angular/animations';

declare let gtag:Function;
declare let fbq:Function;
//declare let register_referral:Function;

@Component({
  selector: 'app-gestationalcarrier',
  templateUrl: './gestationalcarrier.component.html',
  styleUrls: ['./gestationalcarrier.component.scss']
})
export class GestationalcarrierComponent implements OnInit, AfterViewInit {
  //new
  showExplainBox1 = false;
  showExplainBox2 = false;
  showDeliveryTable;
  totalChildren: any;
  selectedMiscarriages;
  showValidationMessage=false;
  showErrorMessage=false;
  showRequiredMessage2=false;
  showRequiredMessage3=false;
  showRequiredMessage4=false;
  showRequiredMessage5=false;
  showRequiredMessage6=false;
  showRequiredMessage7=false;
  showRequiredMessage8=false;
  showValidationMessageLP=false;
  showValidationMessageLH=false;
  showValidationMessageLD=false;
  validationmsgs=[];
  validationmsgsLP=[];
  validationmsgsLD=[];
  validationmsgsLH=[];
  minDate: Date;
  ctrl_list_pregnancy_historys:FormArray;
  ctrl_list_ob_doctors:FormArray;
  ctrl_list_delivery_hospital:FormArray;
  birth_date: Date;
  age: number;
  second = false;
  //old
  today = new Date();
  showForm: boolean = true;
  TotalRow: number;
  _form: FormGroup;
  gcform: FormGroup;
  organization:any={};
  selectedHear;
  selectedSmoke;
  selectedBreastfeeding;
  selectedAlcohol;
  selectedDrugs;
  cur_db_values:any;
  form_progress:any={percent:0};
  lastsaveTS:Date=null;
  lastsavedvalues:any={};
  autosavecontrol=null;
  confirmEmailError=false;
  first = true;
  page_one = true;
  page_two = false;
  page_three = false;
  page_four = false;
  page_five = false;
  page_six = false;
  page_seven = false;
  page_eight = false;
  tab1valid=false;
  tab2valid=false;
  test2=false;
  tab3valid=false;
  tab4valid=false;
  tab5valid=false;
  tab6valid=false;
  tab7valid=false;
  tab8valid=false;
  language: string;
  languageDisplay: string;
  recordexists=false;
  message="";
  inviteurl="";
  photoIDdoc=null;
  authformDoc=null;
  return_email="test";
  last4_digits="1111";
  serviceapierror=false;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  // photodata:any={};
  processSubmit=false;
  // buildGallery=false;
  // galleryready=false;
  nophotos=true;
  // photoimages=[];
  // PHOTO_LIMIT=20;

  photoimages=[];
  bufferimages=[];
  photodata:any={};
  galleryready=false;
  // selectPhoto=false;
  currentIndex=0;
  PHOTO_LIMIT=20;
  galley_config:PlainGalleryConfig;
  customButtonsConfig:ButtonsConfig;

   // 3/31/2024 Add appcast.io tracking
   trackingPixelUrl: string = 'https://click.appcast.io/generic-9k/a17.png?ent=464&e=23469&t=1710353102824';
   pageOneSubmitted = false;

  constructor(private _snackBar: MatSnackBar, private _fb: FormBuilder, public dialog: MatDialog, private router: Router, private authservice:AuthService,private dataservice:DataService, private sessionservice:SessionService, private translateService: TranslateService, private title: Title,
    // public dialogRef: MatDialogRef<AlbumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private santilizer:DomSanitizer,
    ) {
      this.initGCForm();
      this.authservice.org.subscribe(res=>{
        this.organization=res;
      });
      this.languageDisplay = "English"
      this.language = "en"
      this.translateService.use(this.language);
      this.setLanguageDisplay(this.language);
      const currentYear = new Date().getFullYear();
      this.minDate = new Date(currentYear - 200, 0, 1);
   }

  @ViewChild('goUp') goUp: ElementRef;

  ngOnInit() {
    this.favIcon.href = 'assets/icons/ps_icon.png';
    this.title.setTitle("Physican's Surrogacy")
    this.dataservice.getGCPrescreenForm().subscribe(res=>{
      this.cur_db_values=res;
      this.validateformcontrols();
    },error=>{
      console.log("api service issues");
      this.serviceapierror=true;
    });
    if(!this.serviceapierror){
      this.dataservice.getSignatureImageDataURL(this.organization.tenantName,"esign name").subscribe((res:any)=>{
        console.log(res);
        //this.signatureImg="data:image/png;base64,"+res.signatureFontDataUrls[0];
      });
    }
  
  }

  async ngAfterViewInit(){
    // 06/23/2022 Add new referral tracking code
    let newReferralURL = environment.REFERRAL_GC_TRACKING_LIBRARY_URL;
    await this.loadScript(newReferralURL);
    //await this.loadScript("https://portal.simplifyreferrals.com/assets/js/api.js");
    this.add_gc_referral_library_script();
  }

  private loadScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.head.appendChild(scriptElement);
    })
  }

  changeLanguage(lang: string) {
    // Change selected language
      this.language = lang;
      this.setLanguageDisplay(lang);
      this.translateService.use(this.language);
      this.setLanguageDisplay(this.language);
  }

  setLanguageDisplay(lang: string) {
    if (lang == "en")
      this.languageDisplay = this.translateService.instant("ENGLISH");
    else if (lang == 'zh-cn')
      this.languageDisplay = this.translateService.instant("SIMPLIFIED_CHINESE");
    else if (lang == 'zh-tw')
      this.languageDisplay = this.translateService.instant("TRADITIONAL_CHINESE");
    else if (lang == 'es')
      this.languageDisplay = this.translateService.instant("SPANISH");
    else if (lang == 'it')
      this.languageDisplay = this.translateService.instant("ITALIAN");
    else if (lang == 'ja')
      this.languageDisplay = this.translateService.instant("JAPANESE");
  }

  buildNewDeliveryHistorys() {
    return this._fb.group({
      surrogacy: [''],
      birthDate: [''],
      vaginal: [''],
      birthWeight: [''],
      number: ['']
    });
  }

  checkEmail(ctrl) { // here we have the 'passwords' group
  //const group=ctrl.parent;
  if (!ctrl.parent) {
    return null;
  }
  console.log(this.confirmEmailError);
  console.log("inside check email",ctrl);
  const confirmemail = ctrl.value;
  const email = ctrl.parent.get('email').value;
  console.log("email-->",email);
  console.log("confirm email--->",confirmemail);

  if (confirmemail != null && email !=null) {
  if(confirmemail.toLowerCase()!=email.toLowerCase())
  {
      this.confirmEmailError=true;
  } else
  {
      this.confirmEmailError=false;
  }
  console.log(this.confirmEmailError);
  return email.toLowerCase() === confirmemail.toLowerCase() ? null : { matchOther: true }     
  } else {
    this.confirmEmailError=true;
  }

  }

  checkEmailMatch() {
    console.log("MATCHCCCCCCHHHHH")
    this.gcform.controls["confirm_email"].setValue(this.gcform.get('email').value)
    const email = this.gcform.get('email')
    const confirm = this.gcform.get('confirm_email')

    if (email.value !=null && confirm.value != null) {

    if (email.value.toLowerCase() === confirm.value.toLowerCase()) {
      // this.emailMatch=true;
      this.confirmEmailError = false
      console.log("TRUEEEEEEE")
      this.gcform.get('confirm_email').setErrors(null);
    } else {
      // this.emailMatch=false;
      this.confirmEmailError = true
      // this.form.get('email')
      this.gcform.get('confirm_email').setErrors({ 'Email Address not Matching.': true });
      console.log("FALAAAASEEEE")
    }

  } else {
    this.confirmEmailError = true
  }

    this.validatTab1(this.gcform)
    this.validatTab2(this.gcform)
    this.validatTab3(this.gcform)
    this.validatTab4(this.gcform)
    this.validatTab5(this.gcform)
    // this.validatTab6(this.gcform)
    // this.validatTab7(this.gcform)
    // this.validatTab8(this.gcform)
  }

  initGCForm(){
    this.gcform = new FormGroup ({
      returning_user: new FormControl('', [Validators.email, Validators.maxLength(50)]),
      enter_digits: new FormControl('', Validators.maxLength(50)),
      firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      telephone_number: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
      confirm_email: new FormControl('', [ Validators.email, this.checkEmail.bind(this), Validators.maxLength(50)]),
      birthDate: new FormControl(new Date(), Validators.required),
      hear_about_us: new FormControl('', Validators.required),
      // age:new FormControl(''),
      maiden_name: new FormControl('', Validators.maxLength(50)),
      citizen: new FormControl('', Validators.required),
      height_feet: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.min(0), Validators.pattern("^[0-9]*$")]),
      height_in: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.min(0), Validators.max(12)]),
      weight: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.min(0)]),
      ethnic_heritage: new FormControl(''),
      highest_level_education: new FormControl(''),
      occupation: new FormControl('', Validators.maxLength(50)),
      drivers_license: new FormControl(0),
      surrogate_before: new FormControl(0, Validators.required),
      inspired_you: new FormControl('', Validators.maxLength(5000)),
      streetAdd: new FormControl('', Validators.maxLength(500)),
      city: new FormControl('', Validators.maxLength(500)),
      state: new FormControl('', Validators.required),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      marital_status: new FormControl('', Validators.required),
      spouse_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      spouse_email: new FormControl('', [Validators.email, Validators.maxLength(50)]),
      partner_living: new FormControl('', Validators.maxLength(50)),
      main_support: new FormControl('', Validators.maxLength(50)),
      list_everyone: new FormControl('', Validators.maxLength(5000)),
      felony: new FormControl(0, Validators.required),
      felony_partner: new FormControl(0, Validators.required),
      substance_alcohol: new FormControl(''),
      explain_alcohol: new FormControl('', Validators.maxLength(5000)),
      substance_smoke: new FormControl(''),
      smoke: new FormControl(0),
      explain_smoke: new FormControl('', Validators.maxLength(5000)),
      substance: new FormControl(''),
      substance_drugs: new FormControl('', Validators.required),
      explain_drugs: new FormControl('', Validators.maxLength(5000)),
      substance_antidepressan: new FormControl('', Validators.required),
      major_cases: new FormControl(0),
      filling_divorce: new FormControl(0),
      treat_yourself: new FormControl('', Validators.required),
      treat_yourpartner: new FormControl(''),
      miscarriages: new FormControl('', Validators.required),
      explain_miscarriages: new FormControl('', Validators.maxLength(5000)),
      breast_feeding: new FormControl('', Validators.required),
      explain_bf: new FormControl('', Validators.maxLength(5000)),
      gyn: new FormControl(''),
      //explain_gyn: new FormControl('', Validators.maxLength(5000)),
      tribe: new FormControl(0, Validators.required),
      latest_date: new FormControl('', Validators.maxLength(50)),
      //explain_date: new FormControl('', Validators.maxLength(5000)),
      latest_result: new FormControl(''),
      //explain_results: new FormControl('', Validators.maxLength(5000)),
      birth_control: new FormControl('', Validators.required),
      //explain_birth_control: new FormControl('', Validators.maxLength(5000)),
      children: new FormControl(''),
      provide_names: new FormControl('', Validators.maxLength(5000)),
      list_history: new FormControl(''),
      list_doctor: new FormControl(''),
      list_hospital: new FormControl(''),
      explainDelivery: new FormControl('', Validators.maxLength(5000)),
      refere_url:new FormControl('')
      // transportation: new FormControl(0, Validators.required),
      // active_orders: new FormControl(0, Validators.required),
      // explainComplications: new FormControl('', Validators.maxLength(5000)),
      // explain_disease: new FormControl('', Validators.maxLength(5000)),
      // elec_sign: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      // photo_id: new FormControl('',Validators.required)
      //  explain2: new FormControl('', Validators.maxLength(5000)),
      //  address_2: new FormControl(''),
      //  C_sections: new FormControl('', [Validators.required]),
      //  children_living_with: new FormControl('', [Validators.required]),
      //  pregnant: new FormControl(0, [Validators.required]),
      //  Intrauterine: new FormControl(0, [Validators.required]),
      //  Pre_eclampsia: new FormControl(0, [Validators.required]),
      //  Placenta: new FormControl(0, [Validators.required]),
      //  medications: new FormControl(0, [Validators.required]),
      //  nicotine: new FormControl(0, [Validators.required]),
      //  Essure: new FormControl(0, [Validators.required]),
      // endometrial: new FormControl(0, [Validators.required]),
    },
     {validators:this.validatTab1.bind(this)},
     );
    var frm = this._fb.group({
      list_history: this._fb.array([this.buildNewPregnantHistory()]),
    });
    var fr = this._fb.group({
      list_doctor: this._fb.array([this.buildNewOBDoctor()]),
    });
    var f = this._fb.group({
      list_hospital: this._fb.array([this.buildNewDeliveryHospital()]),
    });

    this.ctrl_list_pregnancy_historys=<FormArray>frm.controls["list_history"];
    this.ctrl_list_ob_doctors=<FormArray>fr.controls["list_doctor"];
    this.ctrl_list_delivery_hospital=<FormArray>f.controls["list_hospital"];
  }
  getTab1ControlIds(){
    // let tab1_id_list=["firstName","lastName","maiden_name","streetAdd","zip_code","city","state","telephone_number","birthDate","email","confirm_email","refere_url"];
    // let tab1_id_list=["firstName","lastName","state","telephone_number", "birthDate", "email","confirm_email","refere_url"];
    let tab1_id_list=["firstName","lastName","telephone_number","email","confirm_email","birthDate","hear_about_us","provide_names","refere_url"];
    // let tab1_id_list=["firstName","lastName","state","telephone_number","birthDate","email", "refere_url"];
    return tab1_id_list
  }
  getTab2ControlIds(){
    // let tab2_id_list=["citizen","weight","height_feet","height_in","ethnic_heritage","highest_level_education"];
    let tab2_id_list=["citizen","weight","height_feet","height_in","surrogate_before","state","zip_code"];
    return tab2_id_list
  }
  getTab3ControlIds(){
    // let tab3_id_list=["drivers_license","surrogate_before","streetAdd","state","city","zip_code"];
    // let tab3_id_list=["surrogate_before","streetAdd","state","city","zip_code"];
    let tab3_id_list=["marital_status","spouse_name","felony","felony_partner","substance_antidepressan","substance_drugs"];
    return tab3_id_list
  }
  getTab4ControlIds(){
    // let tab4_id_list=["marital_status","spouse_name","main_support","list_everyone","felony","felony_partner"];
    let tab4_id_list=["treat_yourself","miscarriages","breast_feeding","tribe","birth_control"];
    return tab4_id_list
  }
  // getTab5ControlIds(){
  //   // let tab5_id_list=["substance_alcohol","substance_smoke","substance_antidepressan","smoke","major_cases","substance","substance_drugs","filling_divorce"];
  //   let tab5_id_list=[];
  //   return tab5_id_list
  // }
  // getTab6ControlIds(){
  //   // let tab6_id_list=["treat_yourself","treat_yourpartner","miscarriages","breast_feeding","gyn","tribe","latest_date","latest_result","birth_control"];
  //   let tab6_id_list=[];
  //   return tab6_id_list
  // }
  // getTab7ControlIds(){
  //   let tab7_id_list=["children"];
  //   return tab7_id_list
  // }
  // getTab8ControlIds(){
  //   let tab8_id_list=["hear_about_us"];
  //   return tab8_id_list
  // }

  buildNewPregnantHistory () {
    return this._fb.group({
      history: [''],
      outcome: [''],
      delivery_date: [''],
      delivery_method: [''],
      Surrogacy_Pregnancy: [''],
      Pregnancy_Week_Birth: [''],
      Newborn_weight: ['', [Validators.min(0), Validators.pattern("^[0-9]*$")]],
      Newborn_weight_oz: ['', [Validators.min(0), Validators.pattern("^[0-9]*$")]],
      Pregnancy_Medical: [''],
      Severe_Pregnancy: [''],
      //Treatment_applied: ['', Validators.required],
    });
  }

  selectPregnancyMedical($event){
    let lp = $event.value;
    if (lp.length === 0) {
      this.showExplainBox1 = false;
    }
    for(let j=0;j<lp.length;j++){
      if (lp[j] == 'Others') {
        this.showExplainBox1 = true;
      } else {
        this.showExplainBox1 = false;
      }
    }
  }

  selectSeverePregnancy($event){
    let lp = $event.value;
    if (lp.length === 0) {
      this.showExplainBox2 = false;
    }
    for(let j=0;j<lp.length;j++){
      if (lp[j] == 'Others') {
        this.showExplainBox2 = true;
      } else {
        this.showExplainBox2 = false;
      }
    }
  }

  // yearSelected(event) {
  //   console.log(event);
  //   input.value = event.toISOString().split('-').join('/').substr(0, 7);
  //   const array = event.toISOString().split('-');
  //   let year = array[0];
  //   for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
  //     let lp = this.ctrl_list_pregnancy_historys.controls[i];
  //     lp['delivery_date'] = event;
  //     console.log(lp['delivery_date']);
  //   }
  // }

  monthSelected(event, dp, input,frm) {
    dp.close();
    console.log(event);
    console.log(input.value);
    let datestr=this._dt2str(event);
    input.value=event;
    //console.log(input);
    //console.log(input.value );
    console.log(frm);
    frm.controls['delivery_date'].setValue(event);
  }

  monthPapSelected(event, dp, input,frm) {
    dp.close();
    console.log(event);
    console.log(input.value);
    let datestr=this._dt2str(event);
    input.value=event;
    //console.log(input);
    //console.log(input.value );
    console.log(frm);
    frm.controls['latest_date'].setValue(event);
  }

  buildNewOBDoctor () {
    return this._fb.group({
      obgyn_name: ['', Validators.required],
      obgyn_address: ['', Validators.required],
      obgyn_city: ['', Validators.required],
      obgyn_state: ['', Validators.required],
      obgyn_zipcode: ['', Validators.required],
      obgyn_phone: ['', Validators.required],
      obgyn_date: ['', Validators.required]
    });
  }

  buildNewDeliveryHospital () {
    return this._fb.group({
      dr_name: ['', Validators.required],
      hospital: ['', Validators.required],
      hospital_date: ['', Validators.required]
    });
  }

  add_pregnancy(){
    this.ctrl_list_pregnancy_historys.push(this.buildNewPregnantHistory());
  }

  add_doctor(){
    this.ctrl_list_ob_doctors.push(this.buildNewOBDoctor());
  }

  add_hospital(){
    this.ctrl_list_delivery_hospital.push(this.buildNewDeliveryHospital());
  }

  remove_pregnancy(index){
    const control = this.ctrl_list_pregnancy_historys;
    if (control.length>0) {
      control.removeAt(index);
    } else {
      // alert('Keeping one record is required.');
      alert('No records to be deleted.');
      return false;
    }
  }

  remove_doctor(index){
    const control = this.ctrl_list_ob_doctors;
    if (control.length>1) {
      control.removeAt(index);
    } else {
      alert('Keeping one record is required.');
      return false;
    }
  }

  remove_hospital(index){
    const control = this.ctrl_list_delivery_hospital;
    if (control.length>1) {
      control.removeAt(index);
    } else {
      alert('Keeping one record is required.');
      return false;
    }
  }

  selectChildren($event){
    let totalChildren = $event.value;
    let difference = totalChildren-this.ctrl_list_pregnancy_historys.length
    if (totalChildren == 0) {
      this.showErrorMessage = false;
      this.showRequiredMessage5 = false;
    }
    if (totalChildren >= 1) {
      this.showDeliveryTable = true;
    } else {
      this.showDeliveryTable = false;
    }
    if (difference > 0) {
      for(let i=0;i<difference;i++){
        this.add_pregnancy();
      }
    } else if (difference < 0) {
      for(let j=0;j<Math.abs(difference);j++){
        this.ctrl_list_pregnancy_historys.removeAt(this.ctrl_list_pregnancy_historys.length-1)
      }
 }

  }

  load_list_pregnancy(data){
    if(data){
      let ele_ary=JSON.parse(data);
      console.log(ele_ary);
      if(ele_ary){
        // add new row
        for(let i=1;i<ele_ary.length;i++){
            this.add_pregnancy();
        }
        // set new value
        for(let i=0;i<ele_ary.length;i++){
          let ctrl= this.ctrl_list_pregnancy_historys.controls[i];
          // here we are construct date object for preganant history record
          let vdt=ele_ary[i]["delivery_date"];
          ele_ary[i]["delivery_date"]=new Date(vdt);
          if(!ele_ary[i]["Newborn_weight_oz"]) ele_ary[i]["Newborn_weight_oz"]="0";
          ctrl.setValue(ele_ary[i]);
        }
      }
    }
  }

  load_list_doctor(data){
    if(data){
      let ele_ary=JSON.parse(data);
      console.log(ele_ary);
      if(ele_ary){
        // add new row
        for(let i=0;i<ele_ary.length;i++){
            this.add_doctor();
        }
        // set new value
        for(let i=0;i<ele_ary.length;i++){
          let ctrl= this.ctrl_list_ob_doctors.controls[i];
          ctrl.setValue(ele_ary[i]);
        }
      }
    }
  }

  load_list_hospital(data){
    if(data){
      let ele_ary=JSON.parse(data);
      console.log(ele_ary);
      if(ele_ary){
        // add new row
        for(let i=0;i<ele_ary.length;i++){
            this.add_hospital();
        }
        // set new value
        for(let i=0;i<ele_ary.length;i++){
          let ctrl= this.ctrl_list_delivery_hospital.controls[i];
          ctrl.setValue(ele_ary[i]);
        }
      }
    }
  }
  

  validatTab1(form:FormGroup){ 
    //this.tab1valid=false;
    let tab1controls=this.getTab1ControlIds();
    this.tab1valid=true;
    tab1controls.forEach(ctrlId=>{
      if(form.controls[ctrlId].invalid) this.tab1valid=false;
    });
  }
  // validatTab2(form:FormGroup){ 
  //   let tab2controls=this.getTab2ControlIds();
  //   this.tab2valid=true;
  //   tab2controls.forEach(ctrlId=>{
  //     if(form.controls[ctrlId].invalid) this.tab2valid=false;
  //   });
  // }
  validatTab2(form:FormGroup){ 
    let tab2controls=this.getTab2ControlIds();
    this.tab2valid=true;
    tab2controls.forEach(ctrlId=>{
      if(form.controls[ctrlId].invalid) this.tab2valid=false;
    });
  }
  validatTab3(form:FormGroup){ 
    let tab3controls=this.getTab3ControlIds();
    this.tab3valid=true;
    tab3controls.forEach(ctrlId=>{
      if(form.controls[ctrlId].invalid) this.tab3valid=false;
    });
  }
  validatTab4(form:FormGroup){ 
    let tab4controls=this.getTab4ControlIds();
    this.tab4valid=true;
    tab4controls.forEach(ctrlId=>{
      if(form.controls[ctrlId].invalid) this.tab4valid=false;
    });
  }
  // validatTab5(form:FormGroup){ 
  //   let tab5controls=this.getTab5ControlIds();
  //   this.tab5valid=true;
  //   tab5controls.forEach(ctrlId=>{
  //     if(form.controls[ctrlId].invalid) this.tab5valid=false;
  //   });
  // }
  validatTab5(form:FormGroup){ 
    this.tab5valid=true;
    let lp;
    const delivery = this.gcform.get('children');
    for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
      lp = this.ctrl_list_pregnancy_historys.controls[i];
      if(delivery.value >= 1) {
        if(lp.invalid){
          this.tab5valid=false;
          console.log(lp);
          break;
        }
      }
    }
  }
  validatTab6(form:FormGroup){ 
    // let tab6controls=this.getTab6ControlIds();
    this.tab6valid=true;
    // tab6controls.forEach(ctrlId=>{
    //   if(form.controls[ctrlId].invalid) this.tab6valid=false;
    // });
  }
  // validatTab7(form:FormGroup){ 
  //   // let tab7controls=this.getTab7ControlIds();
  //   this.tab7valid=true;
  //   // tab7controls.forEach(ctrlId=>{
  //   //   if(form.controls[ctrlId].invalid) this.tab7valid=false;
  //   // });
  //   let lp;
  //   const delivery = this.gcform.get('children');
  //   for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
  //     lp = this.ctrl_list_pregnancy_historys.controls[i];
  //     if(delivery.value >= 1) {
  //       if(lp.invalid){
  //         this.tab7valid=false;
  //         console.log(lp);
  //         break;
  //       }
  //     }
  //   }
  // }
  validatTab8(form:FormGroup){ 
    // let tab8controls=this.getTab8ControlIds();
    this.tab8valid=true;
    // tab8controls.forEach(ctrlId=>{
    //   if(form.controls[ctrlId].invalid) this.tab8valid=false;
    // });
  }
  buildSaveJsonTab1(){
    let tab1_ctrl_id_list=this.getTab1ControlIds();
    this.cur_db_values.pages.forEach(pp=>{
      console.log(pp);
      pp.questions.forEach(qq=>{
        if(tab1_ctrl_id_list.includes(qq.questionControlId)) {
          if(qq.questionControlId=="list_history") {
            let vv=this.getAggregateControlData(this.ctrl_list_pregnancy_historys);
              qq.answer=JSON.stringify(vv);
          }
          if(qq.questionControlId=="list_doctor") {
            let vv=this.getAggregateControlData(this.ctrl_list_ob_doctors);
              qq.answer=JSON.stringify(vv);
          }
          if(qq.questionControlId=="list_hospital") {
            let vv=this.getAggregateControlData(this.ctrl_list_delivery_hospital);
              qq.answer=JSON.stringify(vv);
          }
          console.log(qq.questionControlId);
          let vv=this.gcform.get(qq.questionControlId).value;
          if(vv!=null){
            if(vv instanceof Date){
              qq.answer=this._dt2str(vv)
            } else {
              qq.answer=vv+"";
            }
          }
        }
      });
    });
    this.cur_db_values.emailId=this.gcform.get("email").value;
    return this.cur_db_values;
  }

  scrollToTop() {
    this.goUp.nativeElement.scrollIntoView({behavior: 'smooth'}); 
  }

  saveTab1(){
    //this.first = false;
    //this.second = true;
    // this.gcform.controls["confirm_email"].setValue(this.gcform.get('email').value)
    this.processSubmit=true;
    const newvv=this.buildSaveJsonTab1();
    console.log(newvv);
    // 3/31/2024 Add appcast.io tracking

    this.pageOneSubmitted = true;
    
    this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
      
      console.log(res);
      let resp:any=res;
      this.processSubmit=false;
      if(resp.status=="FAIL"){
          this.recordexists=true;
          // this.message=resp.message;
          this.message = "Application with email " + resp.data.emailId + " has already been submitted."
          this.inviteurl=resp.data.invitationUrl;
      } else {
        this.cur_db_values=resp.data;
        console.log("current db values:",this.cur_db_values);
        this.page_one=false;
        this.page_two=true;
      //  this.second = true;
        this.validateformcontrols();
        this.loadDocuments();

        let tenant=this.organization.tenantName;
        let caseId=this.cur_db_values.caseId;
        this.data = { caseId: caseId,tenantName:tenant,emailId:this.cur_db_values.emailId }
        this.buildGallery();
      }
      this.scrollToTop();
    });

    // 6/23/2022 Facebook Tracking
    if (document.getElementById('rp-fbfbq-id')) {
      console.log("Facebook Lead tracking");
      fbq('track', 'Lead');
    }

    // let str_appType = this.sessionservice.get('rp_AppType');
    // if (str_appType == "DONOR") {   

    // } else if (str_appType == "GC")  {
    //   if (document.getElementById('rp-gadsgtag-id')) { 
    //       gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});
    //   }
    // }

    // 06/23/2022 Add/Update Referral Tracking Code
    this.send_RR_RP_Data();

    // 06/23/2022 Add Google Event Code: NEXT_SCREEN/PAGE_LINK 
    // On next button add below onclick attribute and value. 
    // NEXT_SCREEN/PAGE_LINK is the link of next screens that 
    // audiences sees after successfully submitting details 
    // on first screen and clicking on next button                                    
    if (document.getElementById('rp-gadsgtag-id')) {  
      console.log("Google Event Next button tracking");                                       
      //gtag_report_conversion(this.router.url);
      gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});

      // 05/01/2024 
      // <!-- Event snippet for EL - Surro - Qualify/Leads conversion page --> 
      console.log("New Google Event Next button tracking - 05012024");                                       
      gtag('event', 'conversion', {'send_to': environment.GADS_GC_QUALIFY_LEAD_CONVERSION});      
    }
  }

  saveTab2() {
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    this.validatTab2(this.gcform)
    console.log(newvv);
    // if(!this.validateFormData(this.gcform)){
    //   this.showValidationMessage=true;
    // } else {
    //   this.showValidationMessage=false;
    // }
    if (this.tab2valid) {
      this.showRequiredMessage2=false;
      this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_one=false;
        this.page_two=false;
        this.page_three=true;
      });      
      this.scrollToTop();      
      // 05/01/2024 
      // Add FaceBook Tracking
      if (document.getElementById('rp-fbfbq-id')) {
        console.log("Facebook Lead tracking");
        fbq('track', 'Lead');
      }      
      
    } else {
      this.processSubmit=false;
      this.showRequiredMessage2=true;
      // console.log("error")
    }
  }

  saveTab3() {
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    this.validatTab3(this.gcform)
    console.log(newvv);
    // if(!this.validateFormData(this.gcform)){
    //   this.showValidationMessage=true;
    // } else {
    //   this.showValidationMessage=false;
    // }
    if (this.tab3valid) {
      this.showRequiredMessage3=false;
      this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_two=false;
        this.page_three=false;
        this.page_four=true;
      });
      this.scrollToTop();     
      // 05/01/2024 
      // Add FaceBook Tracking
      if (document.getElementById('rp-fbfbq-id')) {
        console.log("Facebook Lead tracking");
        fbq('track', 'Lead');
      }   

    } else {
      this.processSubmit=false;
      this.showRequiredMessage3=true;
      // console.log("error")
    }
  }
  saveTab4() {
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    this.validatTab4(this.gcform)
    console.log(newvv);
    // if(!this.validateFormData(this.gcform)){
    //   this.showValidationMessage=true;
    // } else {
    //   this.showValidationMessage=false;
    // }
    if(this.tab4valid) {
      this.showRequiredMessage4=false;
      this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_three=false;
        this.page_four=false;
        this.page_five=true;
      });
      this.scrollToTop();
      const delivery = this.gcform.get('children');
      if (delivery.value >= 1) {
        this.showDeliveryTable = true;
      }
      // 05/01/2024 
      // Add FaceBook Tracking
      if (document.getElementById('rp-fbfbq-id')) {
        console.log("Facebook Lead tracking");
        fbq('track', 'Lead');
      }   

    } else {
      this.processSubmit=false;
      this.showRequiredMessage4=true;
      // console.log("error")
    }
  }
  // saveTab5() {
  //   const newvv=this.buildSaveJson();
  //   this.processSubmit=true;
  //   this.validatTab5(this.gcform)
  //   console.log(newvv);
  //   // if(!this.validateFormData(this.gcform)){
  //   //   this.showValidationMessage=true;
  //   // } else {
  //   //   this.showValidationMessage=false;
  //   // }
  //   if (this.tab5valid) {
  //     this.showRequiredMessage5=false;
  //     this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
  //       console.log(res);
  //       this.processSubmit=false;
  //       this.getProcess((<any>res).data);
  //       this.cur_db_values=(<any>res).data;
  //       this.page_four=false;
  //       this.page_five=false;
  //       this.page_six=true;
  //     });
  //     this.scrollToTop();
  //   } else {
  //     this.processSubmit=false;
  //     this.showRequiredMessage5=true;
  //     // console.log("error")
  //   }
  // }
  saveTab5() {
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    this.validatTab5(this.gcform)
    const delivery = this.gcform.get('children');
    console.log(newvv);
    // let lp;
    // if(!this.validateFormData(this.gcform)){
    //   this.showValidationMessage=true;
    // } else {
    //   this.showValidationMessage=false;
    // }
    // for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
    //   lp = this.ctrl_list_pregnancy_historys.controls[i];
    //   if(!this.validateFormDataLP(lp)){
    //     this.showValidationMessageLP=true;
    //     break;
    //   }
    // }
    this.showErrorMessage=false;
    let nosubmit=false;
    if (delivery.value == "" || delivery.value == null) {
      this.showErrorMessage=false;
    }
    else if (delivery.value == 0) {
      this.showErrorMessage=false;
    }
    else if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
      this.showErrorMessage=true;
      this.processSubmit=false;
      nosubmit=true;
    }
    if(this.tab5valid && !this.showErrorMessage) {
      this.showRequiredMessage5=false;
      this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_four=false;
        this.page_five=false;
        this.page_six=true;
        });
        this.scrollToTop();
      // if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
      //   this.showErrorMessage=true;
      // }
    }
    if (this.tab5valid && this.showErrorMessage) {
      this.showRequiredMessage5=false;
      this.showErrorMessage=true;
    }
    if (!this.tab5valid) {
      this.showRequiredMessage5=true;
      this.processSubmit=false;
      // console.log("error")
    }
  }
  // saveTab6() {
  //   const newvv=this.buildSaveJson();
  //   this.processSubmit=true;
  //   this.validatTab6(this.gcform)
  //   console.log(newvv);
  //   const delivery = this.gcform.get('children');
  //   if (delivery.value >= 1) {
  //     this.showDeliveryTable=true;
  //   }
  //   // if(!this.validateFormData(this.gcform)){
  //   //   this.showValidationMessage=true;
  //   // } else {
  //   //   this.showValidationMessage=false;
  //   // }
  //   if (this.tab6valid) {
  //     this.showRequiredMessage6=false;
  //     this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
  //       console.log(res);
  //       this.processSubmit=false;
  //       this.getProcess((<any>res).data);
  //       this.cur_db_values=(<any>res).data;
  //       this.page_five=false;
  //       this.page_six=false;
  //       this.page_seven=true;
  //     });
  //     this.scrollToTop();
  //   } else {
  //     this.processSubmit=false;
  //     this.showRequiredMessage6=true;
  //     // console.log("error")
  //   }
  // }
  // saveTab7() {
  //   const newvv=this.buildSaveJson();
  //   this.processSubmit=true;
  //   this.validatTab7(this.gcform)
  //   const delivery = this.gcform.get('children');
  //   console.log(newvv);
  //   // let lp;
  //   // if(!this.validateFormData(this.gcform)){
  //   //   this.showValidationMessage=true;
  //   // } else {
  //   //   this.showValidationMessage=false;
  //   // }
  //   // for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
  //   //   lp = this.ctrl_list_pregnancy_historys.controls[i];
  //   //   if(!this.validateFormDataLP(lp)){
  //   //     this.showValidationMessageLP=true;
  //   //     break;
  //   //   }
  //   // }
  //   this.showErrorMessage=false;
  //   let nosubmit=false;
  //   if (delivery.value == "" || delivery.value == null) {
  //     this.showErrorMessage=false;
  //   }
  //   else if (delivery.value == 0) {
  //     this.showErrorMessage=false;
  //   }
  //   else if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
  //     this.showErrorMessage=true;
  //     this.processSubmit=false;
  //     nosubmit=true;
  //   }
  //   if(this.tab7valid && !this.showErrorMessage) {
  //     this.showRequiredMessage7=false;
  //     this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
  //       console.log(res);
  //       this.processSubmit=false;
  //       this.getProcess((<any>res).data);
  //       this.cur_db_values=(<any>res).data;
  //       this.page_six=false;
  //       this.page_seven=false;
  //       this.page_eight=true;
  //       });
  //       this.scrollToTop();
  //     // if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
  //     //   this.showErrorMessage=true;
  //     // }
  //   }
  //   if (this.tab7valid && this.showErrorMessage) {
  //     this.showRequiredMessage7=false;
  //     this.showErrorMessage=true;
  //   }
  //   if (!this.tab7valid) {
  //     this.showRequiredMessage7=true;
  //     this.processSubmit=false;
  //     // console.log("error")
  //   }
  // }

  backTab1(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
    // 3/31/2024 Add appcast.io tracking
    this.pageOneSubmitted = false;
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_one=true;
        this.page_two=false;
      });
    this.scrollToTop();
  }
  backTab2(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_two=true;
        this.page_three=false;
      });
    this.scrollToTop();
  }
  backTab3(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_three=true;
        this.page_four=false;
      });
    this.scrollToTop();
  }
  backTab4(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_four=true;
        this.page_five=false;
      });
    this.scrollToTop();
  }
  backTab5(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_five=true;
        this.page_six=false;
      });
    this.scrollToTop();
  }
  backTab6(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_six=true;
        this.page_seven=false;
      });
    this.scrollToTop();
  }
  backTab7(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.processSubmit=false;
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
        this.page_seven=true;
        this.page_eight=false;
      });
    this.scrollToTop();
  }
  
  send_RR_RP_Data(){
    const str_appType = this.sessionservice.get('rp_AppType');
    if (str_appType == "DONOR") {    
      console.log ("Not Applied to Donor");
    } else if (str_appType == "GC") {  
      // 06/23/2022 Two Referral Tracking 
      let rp_referral_code = this.sessionservice.get('rp_ReferralCode');
      if (rp_referral_code != "" && rp_referral_code != null) {
        if (!document.getElementById('rrdatacollection-id')) {        
            const rrScript = document.createElement('script');
            rrScript.setAttribute("id", "rrdatacollection-id");
            rrScript.innerHTML = `
              window.rrSpace = (
                rrSettingsConversion = {
                  debug: "false",
                  parameters: {
                    firstname: "${this.gcform.controls.firstName.value}",
                    lastname: "${this.gcform.controls.lastName.value}",
                    email: "${this.gcform.controls.email.value}",
                    phone: "${this.gcform.controls.telephone_number.value}",
                    referralcode: "${this.sessionservice.get('rp_ReferralCode')}",   

                    conversionstatus: "PROGRAMRULE"
                  }
                }
              );
              (function (f, r, n, d, b, y) { b = f.createElement(r), y = f.getElementsByTagName(r)[0]; b.async = 1; b.src = n; b.id = 'RR_DIVID'; y.parentNode.insertBefore(b, y); })(document, 'script', '//physicianssurrogacy.referralrock.com/webpixel/beta/universalv03.js');
            `;
            document.body.appendChild(rrScript);   
            
            console.log ("send_RR_RP_Data -- Version 1");
        } else {
          console.log ("RR_RP_Data already exists");
        }
      }
    }
  }

  add_gc_referral_library_script() {
    if (!document.getElementById('rrdatacollection1-id')) {
        // 06/23/2022 Add new Referral Tracking Code      
          // const gcReferralScript = document.createElement('script');
          // gcReferralScript.async = true;
          // gcReferralScript.type='text/javascript';
          // gcReferralScript.src = environment.REFERRAL_GC_TRACKING_LIBRARY_URL;
          //   //'https://portal.simplifyreferrals.com/assets/js/api.js';
          // document.head.appendChild(gcReferralScript);        
          
          const rrScript1 = document.createElement('script');
          rrScript1.setAttribute("id", "rrdatacollection1-id");
          rrScript1.innerHTML = `
            jQuery(document).ready(function($){   
              window.addEventListener("ref_form_submit", function(e) {
                var ret_val = e.detail;
                console.log("jquery_function");
                console.log(ret_val);
              }, false);
        
              register_referral({
                        "trigger":"#next_button",	
                        "key":"${environment.REFERRAL_GC_TRACKING_KEY}",	
                        "ref_type":"referral",	
                        "name":"#firstName_field",	
                        "phone":"#telephone_number_field",	
                        "email":"#email_field",	
                        "dob":"#birth_date_field"
                    });
            });  
          `;

          document.body.appendChild(rrScript1);   
          
          console.log ("send_RR_RP_Data -- Version 2");
    } else {
          console.log ("RR_RP_Data V2 already exists");
    }               
  }

  goForm2(){
    if(this.inviteurl){
      window.open(this.inviteurl,"_blank");
    }
  }
  compareDifference(newvalues,oldvalues){
    let data_changed=false;
    console.log(newvalues);
    console.log(oldvalues);
    for(const pkey in oldvalues){
      if(newvalues[pkey]!==oldvalues[pkey]){
        console.log("data changed found!! -->",pkey);
        console.log("old value:",oldvalues[pkey]);
        console.log("new value:",newvalues[pkey]);
        data_changed=true;
        break;
      }
    }
    console.log("Data Changed:",data_changed);
    return data_changed;
  }


  onSubmit() {
    if (this.gcform.valid) {
      console.log("Form Submitted!", this.gcform.value);
      /* this.Submit(this.service.form.value).subscribe((result) => {
        console.warn("Result is here", result);
      }) */

      // 06/23/2022 Facebook Tracking
      // regardless of being qualified or not
      if (document.getElementById('rp-fbfbq-id')) {       
        fbq('track', 'CompleteRegistration');
      }

    }
  }

  getAggregateControlData(fa_ctrl){
    const res=[];
    for(let i=0;i<fa_ctrl.controls.length;i++){
      let ctrl= fa_ctrl.controls[i];
      if(this.checkP(ctrl.value)){
        let vv=ctrl.value;
        if(vv["delivery_date"]!=null){
          let nv=this._dt2str(new Date(vv["delivery_date"]));
          vv["delivery_date"]=nv;
        }
        res.push(vv);
      }
    }
    console.log(res);
    return res;
  }

  _dt2str(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
  }

  buildSaveJson(){
    this.cur_db_values.pages.forEach(pp=>{
      console.log(pp);
      pp.questions.forEach(qq=>{
        if(qq.questionControlId=="list_history") {
          let vv=this.getAggregateControlData(this.ctrl_list_pregnancy_historys);
            qq.answer=JSON.stringify(vv);
            //console.log(qq);
        }
        else if(qq.questionControlId=="list_doctor") {
          let vv=this.getAggregateControlData(this.ctrl_list_ob_doctors);
            qq.answer=JSON.stringify(vv);
        }
        else if(qq.questionControlId=="list_hospital") {
          let vv=this.getAggregateControlData(this.ctrl_list_delivery_hospital);
            qq.answer=JSON.stringify(vv);
        }
        else{
          let vv=this.gcform.get(qq.questionControlId).value;
          console.log(qq.questionControlId+"--->"+vv);
          
          if(vv!=null){
            if(vv instanceof Date){
              qq.answer=this._dt2str(vv)
            } else {
              qq.answer=vv+"";
            }
          }
        }
      });
    });
    this.cur_db_values.emailId=this.gcform.get("email").value;
    return this.cur_db_values;
  }

  buildAuthFormValue(){
    let res:any = {};
    res.fullName=this.gcform.get("firstName").value+" "+this.gcform.get("lastName").value;
    res.maidenName=this.gcform.get("maiden_name").value;
    res.birthDate=this.gcform.get("birthDate").value;
    res.obgyn_list=this.getAggregateControlData(this.ctrl_list_ob_doctors);
    res.hospital_list=this.getAggregateControlData(this.ctrl_list_delivery_hospital);
    res.esign=this.gcform.get("elec_sign").value;
    res.valid=true;
    if(res.obgyn_list.length==0 || res.hospital_list.lenght==0 || res.esign==null || res.esign==""){
      res.valid=false;
    }
    return res;
  }
  showAuthorizationDialog(){
    let vv=this.buildAuthFormValue();
    if(!vv.valid){
      window.alert("Missing Data for authorization. You need enter OBGYN doctor and hospital and fill in the esign field");
      return;
    }
    const dialogRef = this.dialog.open(AuthorizationFormDialog, {
      width: '900px',data:{
        values:vv,
        tenant:this.organization.tenantName,
        caseId:this.cur_db_values.caseId
      }
    });
    dialogRef.afterClosed().subscribe(
      data =>{
        if(data){
          // here we need update video data;
           console.log(data);
           this.cur_db_values=data.data;
           if(this.cur_db_values.authFormId){
             this.dataservice.getPSDocInfo(this.organization.tenantName,this.cur_db_values.caseId,"surrogate-auth-form").subscribe(res=>{
               this.authformDoc=res;
             });
           }
         }
      });
  }

  showUploadPhotoIDDialog(){
    const dialogRef = this.dialog.open(UploadDocPopupComponent, {
      width: '900px',disableClose:true,data:{
        doctype:"surrogate-photo-id",
        case:{caseId:this.cur_db_values.caseId},
        orgdata:this.organization,
        title:"Photo ID"
      }
    });
    
    dialogRef.afterClosed().subscribe(
      data =>{
        console.log("Dialog output:", data);
        //this.photodata=data;
        if(data){
         // here we need update video data;
          console.log(data);
          this.cur_db_values=data.data;
          if(this.cur_db_values.photoDocId){
            this.dataservice.getPSDocInfo(this.organization.tenantName,this.cur_db_values.caseId,"surrogate-photo-id").subscribe(res=>{
              this.photoIDdoc=res;
              console.log(this.photoIDdoc);
              
            });
            this.gcform.controls["photo_id"].setValue(this.cur_db_values.photoDocId);
           
          }
        }
      });   
  }

  displayPhotoID(){
    let tenant=this.organization.tenantName;
    let caseId=this.cur_db_values.caseId;
    let docId=this.photoIDdoc.docId;
    let type=this.photoIDdoc.extension;
    this.dataservice.displayPSDocument(tenant,caseId,docId,type);
  }

  displayAuthForm(){
    let tenant=this.organization.tenantName;
    let caseId=this.cur_db_values.caseId;
    let docId=this.authformDoc.docId;
    let type=this.authformDoc.extension;
    this.dataservice.displayPSDocument(tenant,caseId,docId,type);
  }
  deletePhotoID(){
    let tenant=this.organization.tenantName;
    let caseId=this.cur_db_values.caseId;
    let docId=this.photoIDdoc.docId;
    this.dataservice.deletePSDoc(tenant,caseId,docId).subscribe(res=>{
      this.photoIDdoc=null;
    });
  }
  deleteAuthForm(){
    let tenant=this.organization.tenantName;
    let caseId=this.cur_db_values.caseId;
    let docId=this.authformDoc.docId;
    this.dataservice.deletePSDoc(tenant,caseId,docId).subscribe(res=>{
      this.authformDoc=null;
    });
  }

  saveData(){
    const newvv=this.buildSaveJson();
    console.log(newvv);
    this._snackBar.open('Application has been saved','OK',{duration: 2000, panelClass: 'snackbar'});
    
       this.dataservice.saveGCPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
      });
      let vv=this.getDBValues();
      vv['returning_user']="";
      vv['enter_digits']="";
      this.gcform.setValue(vv);
      this.lastsavedvalues=vv;
   
    this.lastsaveTS=new Date();
    this.validateformcontrols();
    this.loadDocuments();
  }

  loadDocuments(){
    if(this.cur_db_values.photoDocId){
      this.dataservice.getPSDocInfo(this.organization.tenantName,this.cur_db_values.caseId,"surrogate-photo-id").subscribe(res=>{
        this.photoIDdoc=res;
        console.log(this.photoIDdoc);
        this.gcform.controls["photo_id"].setValue(this.cur_db_values.photoDocId);
      });
    }
    if(this.cur_db_values.authFormId){
      this.dataservice.getPSDocInfo(this.organization.tenantName,this.cur_db_values.caseId,"surrogate-auth-form").subscribe(res=>{
        this.authformDoc=res;
      });
    }
  }
  getDBValues(){
    const vv:any ={};
    if(this.cur_db_values && this.cur_db_values.pages){
      this.cur_db_values.pages.forEach(pp=>{
          console.log(pp);
          pp.questions.forEach(qq=>{
            pp.questions.forEach(qq=>{
              if(qq.answer=="false"){
                vv[qq.questionControlId]=false;
              } else if(qq.answer=='true'){
                vv[qq.questionControlId]=true;
              } else{
                vv[qq.questionControlId]=qq.answer;
              }
          });
          });
      });
    }
    this.processMultiselect(vv);
    return this.processDateValue(vv);
  }

  clearautosave(){
    if(this.autosavecontrol){
      clearInterval(this.autosavecontrol);
    }
  }

  ngOnDestroy() {
    console.log("clear interval");
    this.clearautosave();
  }


  getProcess(data:any){
    this.form_progress.n_required=data.requiredFieldsCount;
    this.form_progress.n_completed=data.requiredFieldsCompletedCount;
    this.form_progress.percent=Math.round(100*this.form_progress.n_completed/this.form_progress.n_required);
    this.form_progress.status=(this.form_progress.percent==100);
    this.form_progress.formstatus=data.status;
    console.log(this.form_progress);
  }
 
  validateformcontrols(){
    let formkeys=Object.getOwnPropertyNames(this.gcform.value);
    let matched=true;
    let dbkeys=Object.getOwnPropertyNames(this.getDBValues());
    dbkeys.forEach(key=>{
      if(!(formkeys.includes(key))){
        console.log("un matched db key:",key);
        matched=false;
      }
    });
    
    formkeys.forEach(key =>{
      if(!(dbkeys.includes(key))){
        console.log("un matched form key:",key);
        matched=false;
      }
    });
    console.log("matched:",matched);
    console.log("set db values")
    let vv=this.getDBValues();
    let r_url=this.sessionservice.get("rp_ReferralURL");
    if(vv["refere_url"]==null ||vv["refere_url"]==""){
      vv["refere_url"]=r_url;
    }
    this.lastsavedvalues=vv;
    if(this.cur_db_values.photoDocId){
      vv["photo_id"]=this.cur_db_values.photoDocId;
    }
    // default return user and digits
    vv['returning_user']="";
    vv['enter_digits']="";
    //this.processMultiselect(vv);
    if(vv.list_history!=null && vv.list_history!="")
    {
      this.load_list_pregnancy(vv.list_history);
    }
    this.gcform.setValue(vv);
   // this.loadDBDelivery_historys(vv.delivery_historys);
  }
  
  

  checkP(obj){
    let hasValue=false;
    for(const p in obj){
      if((obj[p]!=null) && (obj[p]!="")){
        hasValue=true;
        break;
      }
    }
    return hasValue;
  }

  getDateFields(){
    // return ['birthDate', "latest_date","IUD_dateinserted", "IUD_dateremoved", "Copper_dateinserted", "Copper_dateremoved","Depo_dateinserted", "Depo_dateremoved", "Arm_dateinserted", "Arm_dateremoved"];
    return ['birthDate', "IUD_dateinserted", "IUD_dateremoved", "Copper_dateinserted", "Copper_dateremoved","Depo_dateinserted", "Depo_dateremoved", "Arm_dateinserted", "Arm_dateremoved"];
  }
  getMultiSelectFields(){
    return ["Pregnancy_Medical","Severe_Pregnancy","substance","gyn","treat_yourself","treat_yourpartner"];
  }
  processMultiselect(vv){
    this.getMultiSelectFields().forEach(key=>{
      if(vv[key]!=null && vv[key]!=""){
        vv[key]=vv[key].split(",");
      }
    });
  }
  processDateValue(vv){
    this.getDateFields().forEach(key=>{
      if(vv[key]!=null && vv[key]!=""){
        vv[key]=new Date(vv[key]);
      }
    });
    return vv;
  }

  testFinalize(){
    const vv=this.buildSaveJson();
    vv.status="denied";
    this.dataservice.pushGCformvalue(vv);
        const dialogRef = this.dialog.open(GCApprovalViewComponent, {
          panelClass: 'custom-dialog-container'
    });
  }

  testapprove(){
    this.dialog.open(GCApprovalViewComponent, {
      panelClass: 'custom-dialog-container', disableClose:true
    });
  }

  //hide photos
  finalize(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    let nosubmit=false;
    console.log("proceed validations");
    if(!this.validateFormData(this.gcform)){
      this.showValidationMessage=true;
      nosubmit=true;
      console.log(this.gcform);
    }
    if(!nosubmit){
      this.validatTab5(this.gcform)
            const delivery = this.gcform.get('children');
      console.log(newvv);
      this.dataservice.finalizedGCPrescreening(newvv).subscribe((res:any)=>{
       console.log(res);
      // this.getProcess((<any>res).data);
      if(res.status=="FAIL"){
        res.data.status=res.status;
        res.data.message=res.message;
      }
       this.dataservice.pushGCformvalue(res.data);
       const dialogRef = this.dialog.open(GCApprovalViewComponent, {
         panelClass: 'custom-dialog-container', disableClose:true
       });
       this.processSubmit=false;
     }
      );
      this.showRequiredMessage5=false;
      this.showErrorMessage=false;
      if (delivery.value == "" || delivery.value == null) {
        this.showErrorMessage=false;
      }
      else if (delivery.value == 0) {
        this.showErrorMessage=false;
      }
      else if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
        this.showErrorMessage=true;
        this.processSubmit=false;
        nosubmit=true;
      }
      if (this.tab5valid && this.showErrorMessage) {
        this.showRequiredMessage5=false;
        this.showErrorMessage=true;
      }
    } else{
      this.processSubmit=false;
      this.showRequiredMessage5=true;
      this.showValidationMessage=true;
    }

    // 03/01/2024 Add the triggered script upon submission
    const str_appType = this.sessionservice.get('rp_AppType');
    if (str_appType == "GC") {
      if (document.getElementById('rp-gadsgtag-id')) { 
          gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_SUBMISSION_EVENT});
          // // 05/01/2024 New Tracking regardless of Deny or Approval
          // console.log("05/01/2024 New Tracking regardless of Deny or Approval");
          // gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_THANK_YOU_EVENT_NEW});          

          fbq('track', 'CompleteRegistration');
      }


    }         
  }

  // finalize(){
  //   const newvv=this.buildSaveJson();
  //   //const delivery = this.gcform.get('children');
  //   this.processSubmit=true;    
  //   let lp, ld, lh;
  //   this.showValidationMessage=false;
  //   //this.showErrorMessage=false;
  //   this.showValidationMessageLP=false;
  //   //this.showValidationMessageLH=false;
  //   //this.showValidationMessageLD=false;
  //   let nosubmit=false;
  //   console.log("proceed validations");
  //   // if (delivery.value > 0) {
  //     // if (delivery.value != this.ctrl_list_pregnancy_historys.length) {
  //     //   this.showErrorMessage=true;
  //     //   nosubmit=true;
  //     // }
  //   // }
  //   if(!this.validateFormData(this.gcform)){
  //     this.showValidationMessage=true;
  //     nosubmit=true;
  //     console.log(this.gcform);
  //   }
  //   // for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
  //   //   lp = this.ctrl_list_pregnancy_historys.controls[i];
  //   //   if(!this.validateFormDataLP(lp)){
  //   //     this.showValidationMessageLP=true;
  //   //     nosubmit=true;
  //   //     console.log(lp);
  //   //     break;
  //   //   }
  //   // }
  //   /*
  //   for(let i=0;i<this.ctrl_list_ob_doctors.length;i++){
  //     ld = this.ctrl_list_ob_doctors.controls[i];
  //   }
  //   for(let i=0;i<this.ctrl_list_delivery_hospital.length;i++){
  //     lh = this.ctrl_list_delivery_hospital.controls[i];
  //   }
    

  //   if(!this.validateFormDataLP(lp)){
  //     this.showValidationMessageLP=true;
  //     nosubmit=true;
  //     console.log(lp);
  //   }
  //   if(!this.validateFormDataLD(ld)){
  //     this.showValidationMessageLD=true;
  //     nosubmit=true;
  //     console.log(ld);
  //   }
  //   if(!this.validateFormDataLH(lh)){
  //     this.showValidationMessageLH=true;
  //     nosubmit=true;
  //     console.log(lh);
  //   }
  //   */
  //   if(!nosubmit){
  //     this.validatTab6(this.gcform)
  //     console.log(newvv);
  //     this.dataservice.finalizedGCPrescreening(newvv).subscribe((res:any)=>{
  //      console.log(res);
  //     // this.getProcess((<any>res).data);
  //     if(res.status=="FAIL"){
  //       res.data.status=res.status;
  //       res.data.message=res.message;
  //     }
  //      this.dataservice.pushGCformvalue(res.data);
  //      const dialogRef = this.dialog.open(GCApprovalViewComponent, {
  //        panelClass: 'custom-dialog-container', disableClose:true
  //      });
  //      this.processSubmit=false;
  //      /* this.router.navigate(["/"+this.organization.tenantName+"/gcapproval"]); */
  //    }
  //     );
  //     this.showRequiredMessage6=false;
  //   } else{
  //     this.processSubmit=false;
  //     this.showRequiredMessage6=true;
  //     this.showValidationMessage=true;
  //   }
  // }

  closeValidation() {
    this.showValidationMessage=false;
    this.showValidationMessageLP=false;
    this.showValidationMessageLH=false;
    this.showValidationMessageLD=false;
  }

  closeError() {
    this.showErrorMessage=false;
    this.showRequiredMessage2=false;
    this.showRequiredMessage3=false;
    this.showRequiredMessage4=false;
    this.showRequiredMessage5=false;
    this.showRequiredMessage6=false;
    this.showRequiredMessage7=false;
    this.showRequiredMessage8=false;
  }

  validateFormData(frm){
    this.validationmsgs=[];
    if(frm==this.gcform){
      return this.__validateform(frm);
    }
    return true;
  }

  // validateFormDataLP(frm){
  //   this.validationmsgsLP=[];
  //   for(let i=0;i<this.ctrl_list_pregnancy_historys.length;i++){
  //     let lp= this.ctrl_list_pregnancy_historys.controls[i];
  //     if(frm==lp){
  //       return this.__validateformLP(frm);
  //     }
  //   }
  //   return true;
  // }

  validateFormDataLD(frm){
    this.validationmsgsLD=[];
    for(let i=0;i<this.ctrl_list_ob_doctors.length;i++){
      let ld= this.ctrl_list_ob_doctors.controls[i];
      if(frm==ld){
        return this.__validateformLD(frm);
      }
    }
    return true;
  }

  validateFormDataLH(frm){
    this.validationmsgsLH=[];
    for(let i=0;i<this.ctrl_list_delivery_hospital.length;i++){
      let lh= this.ctrl_list_delivery_hospital.controls[i];
      if(frm==lh){
        return this.__validateformLH(frm);
      }
    }
    return true;
  }

  __validateform(frm:FormGroup){
    Object.keys(frm.controls).forEach(key => {
      if(frm.controls[key].invalid){
        frm.markAllAsTouched();
        key=key.toLowerCase();
        if(key=='firstname'){this.validationmsgs.push(" Question 1");}
        else if (key=='lastname'){this.validationmsgs.push(" Question 2");}
        else if (key=='telephone_number'){this.validationmsgs.push(" Question 3");}
        else if (key=='email'){this.validationmsgs.push(" Question 4");}
        else if (key=='confirm_email'){this.validationmsgs.push(" Error - please contact support");}
        else if (key=='birthdate'){this.validationmsgs.push(" Question 5");}
        else if (key=='hear_about_us'){this.validationmsgs.push(" Question 6");}
        //else if (key=='maiden_name'){this.validationmsgs.push(" Question 7");}
        else if (key=='citizen'){this.validationmsgs.push(" Question 7");}
        else if (key=='height_feet'){this.validationmsgs.push(" Question 8: Height (feet)");}
        else if (key=='height_in'){this.validationmsgs.push(" Question 8: Height (inches)");}
        else if (key=='weight'){this.validationmsgs.push(" Question 9");}
        //else if (key=='ethnic_heritage'){this.validationmsgs.push(" Question 11");}
        //else if (key=='highest_level_education'){this.validationmsgs.push(" Question 12");}
        else if (key=='occupation'){this.validationmsgs.push(" Question 10");}
        //else if (key=='drivers_license'){this.validationmsgs.push(" Question 14");}
        else if (key=='surrogate_before'){this.validationmsgs.push(" Question 11");}
        //else if (key=='inspired_you'){this.validationmsgs.push(" Question 16");}
        //else if (key=='streetadd'){this.validationmsgs.push(" Question 17");}
        //else if (key=='city'){this.validationmsgs.push(" Question 18");}
        else if (key=='state'){this.validationmsgs.push(" Question 12");}
        else if (key=='zip_code'){this.validationmsgs.push(" Question 13");}
        else if (key=='marital_status'){this.validationmsgs.push(" Question 14");}
        else if (key=='spouse_name'){this.validationmsgs.push(" Question 15");}
        //else if (key=='spouse_email'){this.validationmsgs.push(" Question 23");}
        // else if (key=='partner_living'){this.validationmsgs.push(" Question 24");}
        //else if (key=='main_support'){this.validationmsgs.push(" Question 25");}
        //else if (key=='list_everyone'){this.validationmsgs.push(" Question 26");}
        else if (key=='felony'){this.validationmsgs.push(" Question 16");}
        else if (key=='felony_partner'){this.validationmsgs.push(" Question 17");}
        //else if (key=='substance_alcohol'){this.validationmsgs.push(" Question 29");}
        //else if (key=='explain_alcohol'){this.validationmsgs.push(" Question 28: If yes, how frequent?");}
        //else if (key=='substance_smoke'){this.validationmsgs.push(" Question 30");}
        //else if (key=='smoke'){this.validationmsgs.push(" Question 31");}
        //else if (key=='explain_smoke'){this.validationmsgs.push(" Question 30: If yes, please explain");}
        //else if (key=='substance'){this.validationmsgs.push(" Question 32");}
        else if (key=='substance_drugs'){this.validationmsgs.push(" Question 18");}
        //else if (key=='explain_drugs'){this.validationmsgs.push(" Question 32: If yes, how frequent?");}
        else if (key=='substance_antidepressan'){this.validationmsgs.push(" Question 19");}
        //else if (key=='major_cases'){this.validationmsgs.push(" Question 35");}
        //else if (key=='filling_divorce'){this.validationmsgs.push(" Question 36");}
        else if (key=='treat_yourself'){this.validationmsgs.push(" Question 20");}
        //else if (key=='treat_yourpartner'){this.validationmsgs.push(" Question 38");}
        //else if (key=='explain_disease'){this.validationmsgs.push(" Explanation notes if you marked any disease");}
        else if (key=='miscarriages'){this.validationmsgs.push(" Question 21");}
        //else if (key=='explain_miscarriages'){this.validationmsgs.push(" Question 38: If yes, please explain");}
        else if (key=='breast_feeding'){this.validationmsgs.push(" Question 22");}
        //else if (key=='explain_bf'){this.validationmsgs.push(" Question 39: If yes, approximately when to finish? (Month/Year)");}
        //else if (key=='gyn'){this.validationmsgs.push(" Question 41");}
        //else if (key=='explain_gyn'){this.validationmsgs.push(" Explanation notes");}
        else if (key=='tribe'){this.validationmsgs.push(" Question 23");}
        //else if (key=='latest_date'){this.validationmsgs.push(" Question 43");}
        //else if (key=='explain_date'){this.validationmsgs.push(" Explanation notes");}
        //else if (key=='latest_result'){this.validationmsgs.push(" Question 44");}
        //else if (key=='explain_results'){this.validationmsgs.push(" Explanation notes");}
        else if (key=='birth_control'){this.validationmsgs.push(" Question 24");}
        //else if (key=='explain_birth_control'){this.validationmsgs.push(" Explanation notes for birth control");}
        //else if (key=='children'){this.validationmsgs.push(" Question 46");}
        //else if (key=='provide_names'){this.validationmsgs.push(" Question 47: Please provide more details");}
        //else if (frm.controls.smoke.value!='No' && key=='explain_smoke'){this.validationmsgs.push(" Question 25: If yes, please explain");}
        //else if (key=='explaindelivery'){this.validationmsgs.push("Please write your explanation as needed");}
        //else if (key=='transportation'){this.validationmsgs.push(" Question 27");}
        //else if (key=='active_orders'){this.validationmsgs.push(" Question 32");}
        // else if (key=='substance_alcohol'){this.validationmsgs.push(" Question 39");}
        // else if (frm.controls.substance_alcohol.value!='No' && key=='explain_alcohol'){this.validationmsgs.push(" Question 39: If yes, how frequent?");}
        // else if (key=='substance_drugs'){this.validationmsgs.push(" Question 40");}
        // else if (frm.controls.substance_drugs.value!='No' && key=='explain_drugs'){this.validationmsgs.push(" Question 40: If yes, how frequent?");}
        // else if (key=='substance_smoke'){this.validationmsgs.push(" Question 41");}
        // else if (key=='substance_antidepressan'){this.validationmsgs.push(" Question 42");}
        // else if (key=='substance'){this.validationmsgs.push(" Question 43");}
        // else if (key=='gyn'){this.validationmsgs.push(" Have you had any GYN surgeries involving reproductive organs?");}
        //else if (key=='elec_sign'){this.validationmsgs.push(" Electronic signature");}
        //else if (key=='photo_id'){this.validationmsgs.push(" Upload your ID");}
      }
    });
    if(this.validationmsgs.length>0)
      return false;
    else return true;
  }

  // __validateformLP(frm:FormGroup){
  //   console.log(frm);
  //   Object.keys(frm.controls).forEach(key => {
  //     if(frm.controls[key].invalid){
  //       frm.markAllAsTouched();
  //       key=key.toLowerCase();
  //       if (key=='outcome'){this.validationmsgsLP.push("Delivery History: Outcome");}
  //       //else if (key=='history'){this.validationmsgsLP.push(" Delivery History");}
  //       else if (key=='delivery_date'){
  //         this.validationmsgsLP.push("Delivery History: Delivery Month/Year");
  //       }
  //       else if (key=='delivery_method'){this.validationmsgsLP.push("Delivery History: Delivery Methods");}
  //       else if (key=='surrogacy_pregnancy'){this.validationmsgsLP.push("Delivery History: Surrogacy Pregnancy");}
  //       else if (key=='pregnancy_week_birth'){this.validationmsgsLP.push("Delivery History: Gestational Week at Birth");}
  //       else if (key=='newborn_weight'){this.validationmsgsLP.push("Delivery History: Newborn Weight at Birth (lbs)");}
  //       else if (key=='newborn_weight_oz'){this.validationmsgsLP.push("Delivery History: Newborn Weight at Birth (oz)");}
  //       else if (key=='pregnancy_medical'){this.validationmsgsLP.push("Delivery History: Pregnancy Medical Conditions");}
  //       //else if (key=='treatment_applied'){this.validationmsgsLP.push(" Treatment Applied");}
  //       else if (key=='severe_pregnancy'){this.validationmsgsLP.push("Delivery History: Severe Pregnancy Complications");}
  //       //else if (key=='explaincomplications'){this.validationmsgsLP.push(" Please write an explanation for severe pregnancy complications");}
  //     }
  //   });
  //   return frm.valid;
  // }

  __validateformLD(frm:FormGroup){
    Object.keys(frm.controls).forEach(key => {
      if(frm.controls[key].invalid){
        frm.markAllAsTouched();
        key=key.toLowerCase();
        if (key=='obgyn_name'){this.validationmsgsLD.push(" Question 37: Name");}
        else if (key=='obgyn_address'){this.validationmsgsLD.push(" Question 37: Address");}
        else if (key=='obgyn_city'){this.validationmsgsLD.push(" Question 37: City");}
        else if (key=='obgyn_state'){this.validationmsgsLD.push(" Question 37: State");}
        else if (key=='obgyn_zipcode'){this.validationmsgsLD.push(" Question 37: Zip Code");}
        else if (key=='obgyn_phone'){this.validationmsgsLD.push(" Question 37: Telephone and Fax");}
        else if (key=='obgyn_date'){this.validationmsgsLD.push(" Question 37: Dates of Treatment");}
      }
    });
    return frm.valid;
  }

  __validateformLH(frm:FormGroup){
    Object.keys(frm.controls).forEach(key => {
      if(frm.controls[key].invalid){
        frm.markAllAsTouched();
        key=key.toLowerCase();
        if (key=='dr_name'){this.validationmsgsLH.push(" Question 38: Dr. Name");}
        else if (key=='hospital'){this.validationmsgsLH.push(" Question 38: Hospital");}
        else if (key=='hospital_date'){this.validationmsgsLH.push(" Question 38: Dates of Treatment");}
      }
    });
    return frm.valid;
  }

  Age() {
    if (this.birth_date) {
      var timeDiff = Math.abs(Date.now() - new Date(this.birth_date).getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }
    return this.age;
  }

  State() {
    let state = this.gcform.get("state").value;
    return state;
  }

  Bmi() {
    var f = self.document.forms[0];
    let w = f.wt.value;
    let u = f.hti.value;
    let v = f.htf.value;
    var i =  f.htf.value * 12 + f.hti.value*1.0;
    let bmi: any = (w/(i*i))*703;
    let f_bmi = Math.floor(bmi);
    let diff  = bmi - f_bmi;
    diff = diff * 10;
    diff = Math.round(diff);
    if (diff == 10)
    {
      f_bmi += 1;
      diff   = 0;
    }
    bmi = f_bmi + "." + diff;
    if(isNaN(bmi)){
      bmi="BMI";
  }
    return bmi;
  }
    
  validateGCForm(){
    let invalid_controls=[];
    Object.keys(this.gcform.controls).forEach(key => {
      if(this.gcform.controls[key].invalid){
        invalid_controls.push(key);
      }
    });
    console.log(this.gcform.controls['photo_id']);
    console.log(invalid_controls);
    console.log("total invalid controls:"+invalid_controls.length);
  }

  returnuser: boolean;
  returnUser() {
    this.returnuser = true;
  }

  showCheckCaseMessage=false;
  checkCaseStatus="";
  checkCaseMessage="";
  appload: boolean;
  checkCase(){
    this.showCheckCaseMessage=false;
    let emailId=this.gcform.get("returning_user").value;
    if(emailId=="" || emailId==null ){
      alert("Please enter email address.");
      return;
    }
    this.dataservice.checkExistingCase(emailId).subscribe(res=>{
        this.showCheckCaseMessage=true;
        console.log(res);
        let resp:any=res;
        this.checkCaseStatus=resp.status;
        this.checkCaseMessage=resp.message;
        this.appload = true;
    });
  }

  showFetchMessage=false;
  fetchMessage="";
  fetchCase(){
      this.showFetchMessage=false;
      this.showCheckCaseMessage=false;
      console.log("inside fetech existing case.");
      let emailId=this.gcform.get("returning_user").value;
      let last4=this.gcform.get("enter_digits").value;
      if(emailId=="" || emailId==null || last4==null || last4==""){
        alert("Need Email Address and Last 4 Digits of phone number");
        return;
      }
      this.dataservice.fetchExistingCase(emailId,last4).subscribe(res=>{
          let resp:any=res;
          if(resp.status=="SUCCESS"){
            this.showFetchMessage=false;
            this.cur_db_values=resp.data;
            console.log("current db values:",this.cur_db_values);
           // this.first=false;
          //  this.second = true;
            this.validateformcontrols();
            this.loadDocuments();
            this.appload = false;
          } else{
            this.showFetchMessage=true;
            this.fetchMessage=resp.message;
          }
      });
  }

  //   showAddPhotoDialog(){
  //   const dialogRef = this.dialog.open(UploadImagePopupComponent, {
  //     width: '900px',disableClose:true,data:{
  //       type:"GC",
  //       f2data:this.current_page_db_values,
  //       tenantName:this.organization.tenantName
  //     }
  //   });
    
  //   dialogRef.afterClosed().subscribe(
  //     data =>{
  //       console.log("Dialog output:", data);
  //       //this.photodata=data;
  //       if(data){
  //         this.buildImageGallery(this.gcform2def.caseId);
  //       }
  //     });   
  // }

  showAddPhotoDialog(){
    let tenant=this.organization.tenantName;
    let caseId=this.cur_db_values.caseId;
    const dialogref=this.dialog.open(AlbumComponent, {
      width: '960px', height: '600px',
      data: { caseId: caseId,tenantName:tenant,emailId:this.cur_db_values.emailId }
    });
    dialogref.afterClosed().subscribe(res=>{
      // console.log("Dialog output:", res);
      // window.alert(this.photoimages.length + " photos uploaded");
    });
  }


  buildGallery(){
    this.buildImageGallery();
  }


  onChangeImage(event){
    console.log(event);
    this.currentIndex=event.result-1;
  }
  
  buildImageGallery(){
    this.galleryready=false;
    this.photoimages=[];
   // this.bufferimages=[];
    this.dataservice.getImageData(this.data.tenantName,this.data.caseId).subscribe(res=>{
      console.log(res);
      this.photodata=res;
      if(this.photodata.images && this.photodata.images.length>0){
        console.log("loading image content for gallery");
        this.loadImageContent();

      } else {
        console.log("no image found loaded");
        this.galleryready=true;
      }
      //this.edform2.controls['photos'].setValue(this.photodata.images.length)
    });
  }

  loadImageContent(){
    // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/image/{seqNo}
    this.galleryready=false;
    this.photoimages=[];
    this.bufferimages=[];
    const tenant=this.data.tenantName;
    const caseId=this.data.caseId;
    if(this.photodata.images){
      
      this.photodata.images.forEach(img=>{
        let imgapiurl=this.dataservice.authservice.apiPS+"Form1/tenant/"+tenant+"/form/surrogate/case/"+caseId+"/image/"+img.seqNo;
        //let thumbapiurl=imgapiurl+"/thumbnail";
       // console.log(imgapiurl);
        this.dataservice._getImageBlob(imgapiurl).subscribe(resp=>{
          const file = new Blob([<any>resp], {type: 'image/jpeg'});
          let _imageurl = URL.createObjectURL(file);
          let image_url=this.santilizer.bypassSecurityTrustResourceUrl(_imageurl);
          /*
          console.log("----->")
          console.log(imageurl)
          this.photoimages.push(new Image(img.seqNo,{img:imgapiurl},{img:thumbapiurl}));
          */
       
            this._addGalleryImage(img.seqNo,image_url,image_url,img.fileName);
         
        });
      });
    } else{
      this.galleryready=true;
    }
  }

  _addGalleryImage(id,img_url,thumb_img_url,fn){
    
    this.bufferimages.push(new Image(id,{img:img_url,title:fn,description:fn,downloadFileName:fn},{img:thumb_img_url,title:fn,description:fn}));
  //  console.log("add image --->",this.photoimages);
  //  console.log("current size: ===>",this.photoimages.length,"   ",this.photodata.images.length);
    if(this.bufferimages.length==this.photodata.images.length){
      // reorder
      this.photodata.images.forEach(img=>{
        this.bufferimages.forEach(img2=>{
            if(img2.id==img.seqNo){
              this.photoimages.push(img2);
              // this.selectPhoto = true;
              // console.log("Select Photo: " + this.selectPhoto);
              // console.log("img: " + img.seqNo);
              // console.log("img2: " + img2.id);
            }
        });
      });
      this.galleryready=true;
    }
  }
  

  deletePhoto(img){
    console.log("admin remove photoes");
    console.log(img);
    // if(this.photoimages.length<=3){
    //   alert("Need to keep at least 3 photos.");
    //   return;
    // }
    this.dataservice.removePhoto(this.organization.tenantName,this.photodata.caseId,img.seqNo).subscribe(res=>{
      console.log(res);
      this.photodata=res;
      this.loadImageContent();
    });
  }

  addPhoto(){
    let imgcount=0;
    if(this.photoimages){
      imgcount=this.photoimages.length;
    }
    if(imgcount>=this.PHOTO_LIMIT){
      window.alert("Can't upload more than "+this.PHOTO_LIMIT+" photos.");
      return;
    }
    let uploadphoto_dialog=this.dialog.open(UploadImagePopupComponent,
      {width: '800px',
       position: { top: '100px' },
       data: {count:imgcount,caseId:this.data.caseId,emailId:this.data.emailId,tenantName:this.data.tenantName}
      });
      uploadphoto_dialog.afterClosed().subscribe(res=>{
        if(res!=null){
          this.photodata=res;
          this.loadImageContent();
        }
      });
  }
  
}