import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { DataService, RPErrorHandler } from '@app/services/dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService} from "../../core/services";
import { environment } from '@environments/environment';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-gcapprovalview',
  templateUrl: './gcapprovalview.component.html',
  styleUrls: ['./gcapprovalview.component.scss']
})
export class GCApprovalViewComponent implements OnInit, AfterViewInit {
  form:FormGroup;
  acknowledge=false;
  firstName="";
  lastName="";
  inviteurl="";
  casedata:any={status:"form1-approved"};
  final=false;
  reasons=[];
  constructor(private route: ActivatedRoute,private router:Router,private service:DataService, public dialogRef: MatDialogRef<GCApprovalViewComponent>, private session: SessionService){

    this.service.gcformdata.subscribe(data=>{
       this.casedata=data;
       console.log(this.casedata);
     });
     
  }
  ngOnInit(): void {
    console.log(this.casedata);
    const emailaddress=this.casedata.emailId;
    this.initForm(emailaddress);
    this.getFirstName();
    if(this.casedata.denialReason){
      this.finalizedeny()
      // this.reasons=eval(this.casedata.denialReason);
      // this.buildReasons();
      // console.log(this.reasons);    
    }
  }

  ngAfterViewInit(){
      // 05/01/2024 Add Thanks-you tracking for the denied scenario
      const str_appType = this.session.get('rp_AppType');
      if (str_appType == "GC") {
        if (document.getElementById('rp-gadsgtag-id')) { 
          console.log("05/01/2024 Add Thanks-you tracking for the denied scenario");
          gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_THANK_YOU_EVENT_NEW});
        }
      }    
  }

  sendEmail(){
    
  }
  buildReasons(){
    if(this.reasons && this.reasons.length>0){
      this.reasons.forEach(ele=>{
        ele.questionId=this.getQuestionID(ele.Key);
      });
    }
  }

  getQuestionID(key){
    key=key.toLowerCase();
    let question="";
    if (key=='birthdate'){question=question+" Question 5"; }
    else if (key=='citizen'){question=question+" Question 7"; }
    else if (key=='bmi'){question=question+" BMI";}
    else if (key=='state'){question=question+" Question 12"; }
    else if (key=='felony'){ question=question+" Question 16"; }
    else if (key=='felony_partner'){question=question+" Question 17"; }
    else if (key=='substance_drugs'){question=question+" Question 18"; }
    else if (key=='substance_antidepressan'){question=question+" Question 19"; }
    else if (key=='treat_yourself'){question=question+" Question 20"; }
    else if (key=='tribe'){ question=question+" Question 23"; }
    else if (key=='children'){question=question+" Question 25";}
    else if (key=='severe_pregnancy'){question=question+" Delivery History: Severe Pregnancy Complications"; }
    else if (key=='height_feet'){question=question+" height_feet";}
    else if (key=='smoke'){question=question+" smoke";  }
    else if (key=='substance_alcohol'){question=question+" substance_alcohol"; }
    else if (key=='substance_smoke'){ question=question+" substance_smoke";}
    else if (key=='substance'){question=question+" substance";}
    else if (key=='gyn'){question=question+" gyn"; }
    // else if (key=='birth_control'){question=question+" Question 43"; }
    else if (key=='treat_yourpartner'){question=question+" treat_yourpartner"; }
    return question;
  }

  initForm(emailid){
    this.form = new FormGroup ({
      email: new FormControl(emailid, [Validators.required, Validators.email])
    });
  }

  sendInvite(){
    const caseId=this.casedata.caseId;
    const elemail=this.form.get("email").value;
    this.service.sendGCInvite(caseId,elemail).subscribe(res=>{
        console.log(res);
        this.acknowledge=true;
        this.inviteurl=(<any>res).data.invitationUrl;
    });

    // 06/23/2022 Move the tracking to Submit instead
    //if (document.getElementById('rp-fbfbq-id')) {       
    //  fbq('track', 'CompleteRegistration');
    //}

    const str_appType = this.session.get('rp_AppType');
    if (str_appType == "GC") {
      if (document.getElementById('rp-gadsgtag-id')) { 
        gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_THANK_YOU_EVENT});
      }
    }    
  }

  getFirstName(){
    if(this.casedata.pages && this.casedata.pages[0].questions)
    {
      this.casedata.pages[0].questions.forEach(qq=>{
        if(qq.questionControlId=="firstName"){
          this.firstName=qq.answer;
        }
        if(qq.questionControlId=="lastName"){
          this.lastName=qq.answer;
        }
      });
    }
  }

  continueForm2(){
    window.open(this.inviteurl, "_self");
  }

  onClose(): void {
    window.open('location', '_self').close();
  }

  closeMe(){
    this.dialogRef.close();
  }
  onReview(): void {
    this.dialogRef.close();
  }

  finalizedeny(){
    const caseId=this.casedata.caseId;
    this.service.finalizeGCDeny(caseId).subscribe(res=>{
        let resp:any=res;
        this.final=true;
        this.casedata.status=resp.data;
      });
  }
  finalize(){
    const caseId=this.casedata.caseId;
    const elemail=this.casedata.emailId;
    this.service.sendGCInvite(caseId,elemail).subscribe(res=>{
        console.log(res);
      //  this.acknowledge=true;
      //  this.inviteurl=(<any>res).data.invitationUrl;
    });
    setTimeout(() => {
      this.final=true;
    }, (500));

    if (document.getElementById('rp-fbfbq-id')) {  
        fbq('track', 'Lead');
    }

    // 06/23/2022 Commented out Submission button Tracking -- not "Next" button
    // const str_appType = this.session.get('rp_AppType');
    // if (str_appType == "GC") {
    //   if (document.getElementById('rp-gadsgtag-id')) { 
    //       gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});
    //   }
    // }       

    // 06/23/2022 Add Thanks-you tracking
    const str_appType = this.session.get('rp_AppType');
    if (str_appType == "GC") {
      if (document.getElementById('rp-gadsgtag-id')) { 
        gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_THANK_YOU_EVENT});
      }
    }       
  }

}