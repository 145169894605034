import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '@app/services/dataservice.service';

@Component({
  selector: 'app-uploadimagepopup',
  templateUrl: './uploadimagepopup.component.html',
  styleUrls: ['./uploadimagepopup.component.scss']
})

export class UploadImagePopupComponent implements OnInit {
  files: any = [];
  progressInfo ={};
  ezSignFileList: File;
  uploadedFileName: string;
  showProcessSpinner = false;
  isLinear: any;
  documentTitle = null;
  uploading=false;
  completestatus=false;
  completefiles=[];
  uploadMessage="";
  imageCount=0;
  PHOTO_LIMIT=20;
  complete=false;
  bufferValue;
  determinate;
  //image_db_value:any;
  //historyref: HistoryComponent;
 // archiveComponentRef: ArchiveComponent;
  constructor(private service: DataService,
    public dialogRef: MatDialogRef<UploadImagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    console.log(this.data);
    this.imageCount=this.data.count;
  }

  ngOnInit() {
  }

  closeme() {
    this.dialogRef.close();
  }

  uploadInputFile(event) {
    let overlimit:boolean=false;
    console.log('upload input file');
    console.log(event);
    this.ezSignFileList = event;
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if(this.imageCount+this.files.length<this.PHOTO_LIMIT)
      {
        this.files.push(element);
        this.uploadedFileName = element.name;
        if (index === 0) {
          this.documentTitle = element.name;
        }
      }
      else{
        overlimit=true;
      }
     
    }
    if(overlimit){
      alert("Exceed Photo Limits:"+this.PHOTO_LIMIT);
    }
  }

  dragAndDropFile(event) {
    let overlimit:boolean=false;
    console.log('drag and drop file');
    console.log(event);
    this.ezSignFileList = event;
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if(!this.checkDuplicateFile(element) ){
          if(this.imageCount+this.files.length<this.PHOTO_LIMIT)
          {
            this.files.push(element);
            this.uploadedFileName = element.name;
             if (index === 0) {
              this.documentTitle = element.name;
             }
          }
          else{
            overlimit=true;
          }
            
      }
    }
    if(overlimit){
      alert("Max Photo Count Limit Exceeded: "+this.PHOTO_LIMIT);
    }
  }

  checkDuplicateFile(ff:any){
    console.log(ff);
    let matched=false;
    this.files.forEach(fe=>{
      console.log("1--->");
      console.log(fe);
      if((fe.name==ff.name) && fe.size==ff.size){
        matched=true;
      }
    });
    console.log(matched);
    return matched;
  }
  deleteAttachment(index) {
    console.log('delete attachment');
    console.log(index);
    this.files.splice(index, 1);
    this.ezSignFileList = this.files;
    this.documentTitle = null;
    console.log(this.ezSignFileList);
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        if (i === 0) {
          this.documentTitle = this.files[i].name;
        }
      }
    }
  }
  uploadCheck(fn){
    this.completefiles.push(fn);
    if(this.completefiles.length==this.files.length){
      console.log("upload complete");
      this.completestatus=true;
      //this.dialogRef.close(res);   
    }
  }

  uploadPhoto(){
    this.uploadGCPhoto();
    
  }

  uploadGCPhoto(){
    this.uploading= !this.uploading;
    this.completefiles=[];
    this.uploadMessage="uploading....";
    this.service.uploadGCPrescreenImage(this.data.tenantName,this.data.caseId,this.data.emailId,this.files).subscribe(ev=>{
   if (ev.type === HttpEventType.UploadProgress) {
      let percentage= Math.round(100 * ev.loaded / ev.total);
      console.log(percentage);
      console.log(ev);
      this.files.forEach(imgfile=>{ 
        this.progressInfo[imgfile.name]=percentage;
      });
     } else if (ev instanceof HttpResponse) {
      let res:any=ev.body;
      console.log(res);
      this.files.forEach(imageFile=>{
        this.uploadCheck(imageFile.name);
        this.uploadMessage="Saving Complete ...."+imageFile.name;
      });
      this.dialogRef.close(res);
     }
    });
  }

  uploadEDPhotoAdmin(){
    /*
     this.uploading= !this.uploading;
      this.completefiles=[];
      this.uploadMessage="uploading....";
     this.service.uploadEDImagesAdmin(this.data.case.tenantName,this.data.case,this.files).subscribe(ev=>{
     if (ev.type === HttpEventType.UploadProgress) {
        let percentage= Math.round(100 * ev.loaded / ev.total);
        console.log(percentage);
        console.log(ev);
        this.files.forEach(imgfile=>{ 
          this.progressInfo[imgfile.name]=percentage;
        });
       } else if (ev instanceof HttpResponse) {
        let res:any=ev.body;
        console.log(res);
        this.files.forEach(imageFile=>{
          this.uploadCheck(imageFile.name);
          this.uploadMessage="Saving Complete ...."+imageFile.name;
        });
        this.dialogRef.close(res);
       }
    });
    */
  }

 
}
