import { Component, OnInit, AfterViewInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, SessionService } from '@app/core/services';
import { DataService, RPErrorHandler } from '@app/services/dataservice.service';
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EDApprovalViewComponent } from '../edapprovalview/approvalview.component';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { ButtonEvent, ButtonsConfig,ButtonsStrategy,ButtonType,Image, LineLayout, PlainGalleryConfig, PlainGalleryStrategy } from '@ks89/angular-modal-gallery';
import { UploadimagepopupComponent } from '../uploadimagepopup/uploadimagepopup.component';
import { UploadvideopopupComponent } from '../uploadvideopopup/uploadvideopopup.component';
declare let gtag:Function;
declare let fbq:Function;
declare let gtag_report_conversion:Function;

@Component({
  selector: 'app-eggdonation',
  templateUrl: './eggdonation.component.html',
  styleUrls: ['./eggdonation.component.scss']
})
export class EggdonationComponent implements OnInit, AfterViewInit {
  minDate: Date;
  usPhone;
  foreignPhone;
  today = new Date();
  processSubmit=false;
  confirmEmailError=false;
  // confirmEmail2Error=false;
  showForm: boolean = true;
  form: FormGroup;
  lastsaveTS:Date=null;
  autosavecontrol=null;
  lastsavedvalues:any={};
  cur_db_values:any =null;
  organization:any;
  form_progress:any={};
  selectedCountry;
  selectedHear;
  covid;
  raceChecked=false;
  emailMatch=false;
  first = true;
  tab1valid=false;
  language: string;
  languageDisplay: string;
  recordexists=false;
  message="";
  inviteurl="";
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  checkCaseStatus
  // gallery
  photodata:any={};
  galley_config:PlainGalleryConfig;
  customButtonsConfig:ButtonsConfig;
  buildGallery=false;
  galleryready=false;
  nophotos=true;
  photoimages=[];
  bufferimages=[];
  form4valid=false;
  showVideo=false;
  videourl="";
  videos=[];
  PHOTO_LIMIT=20;

  //return user
  appload: boolean;
  showCheckCaseMessage=false;
  checkCaseMessage="";
  showFetchMessage=false;
  fetchMessage="";

  constructor(public dialog: MatDialog, private authservice:AuthService, 
    private router: Router,
    private dataservice:DataService,
    private sessionservice:SessionService,
    private translateService: TranslateService,
    private errorhandler:RPErrorHandler,
    private santilizer:DomSanitizer,
    private title: Title) { 
      this.initFormControl();
      this.authservice.org.subscribe(res=>{
        this.organization=res;
      });
      this.languageDisplay = "English"
      this.language = "en"
      this.translateService.use(this.language);
      this.setLanguageDisplay(this.language);
      const currentYear = new Date().getFullYear();
      this.minDate = new Date(currentYear - 200, 0, 1);
  }
  ngOnInit(): void {

    this.favIcon.href = 'assets/icons/lucina_icon.png';
    this.title.setTitle("Recruiting Service");
 
    this.foreignValidator()

      // In a real app: dispatch action to load the details here.
      
      this.dataservice.getEDPrescreenForm().subscribe(res=>{
        this.cur_db_values=res;
        console.log(this.cur_db_values);
//        this.getProcess(res);
        this.validateformcontrols();
      },
      err=>{
        console.log("inside error handler");
        this.errorhandler.handle(err);
      }
      );
  }

  checkCase(){
    this.showCheckCaseMessage=false;
    let emailId=this.form.get("returning_user").value;
    if(emailId=="" || emailId==null ){
      alert("Please enter email address.");
      return;
    }
    this.dataservice.checkExistingPrescreeningDonorCase(emailId).subscribe(res=>{
        this.showCheckCaseMessage=true;
        console.log(res);
        let resp:any=res;
        this.checkCaseStatus=resp.status;
        this.checkCaseMessage=resp.message;
        if(resp.status=="SUCCESS")
          this.appload = true;
    });
  }

  fetchCase(){
    let emailId=this.form.get("returning_user").value;
    let phone=this.form.get("enter_digits").value;
    this.showFetchMessage=true;
    this.showCheckCaseMessage=true;
    this.dataservice.pullExistringPrescreeningDonorCase(emailId,phone).subscribe((resp:any)=>{
        console.log(resp);
        if(resp.status=="SUCCESS"){
          this.showFetchMessage=false;
          this.cur_db_values=resp.data;
          this.first=false;
          this.validateformcontrols();
          this.buildImageGallery();
        }
    });
  }

  returnuser: boolean;
  returnUser() {
    this.returnuser = true;
  }

  showReturnButton = true;
  backButton(){
    this.first = true;
    if (this.returnuser = true) {
      this.showReturnButton = false;
    }
  }

  onCustomButtonAfterHook(event: ButtonEvent) {
    // use after hook to get click on buttons
    // for custom buttons, you have to check event with your logic
    console.log('onCustomButtonAfterHook ', event);
    if (!event || !event.button) {
      return;
    } else if (event.button.type==ButtonType.CLOSE){
      if(this.photoimages.length!=this.photodata.images.length){
        this.buildImageGallery();
      }
    } else if(event.button.type==ButtonType.DELETE && event.button.title=='Delete Image')
    {
      const new_img_ary=[];
      this.photoimages.forEach(img=>{
        if(img.id!==event.image.id){
          new_img_ary.push(img);
        }
      });
      this.photoimages=new_img_ary;
      this.dataservice.deletePrescreeningDonorImage(this.cur_db_values.caseId,event.image.id).subscribe(res=>{
        this.photodata=res;
        if(this.photoimages.length==0){
          this.buildImageGallery();
         // this.fourthFormGroup.controls['photoes'].setValue("");
        } else if(this.photodata.images.length>2){
        //  this.fourthFormGroup.controls['photoes'].setValue(this.photodata.images.length);
        } else {
          //this.fourthFormGroup.controls['photoes'].setValue("");
        }
        //this.validateForm4(this.fourthFormGroup);
      });
    } else if(event.button.type==ButtonType.CUSTOM && event.button.title=='Download Image'){
        //this.downloadImage(event.image);
        console.log("download image not yet implemented");
    }
    // Invoked after both a click on a button and its related action.
  }

  showAddPhotoDialog(){
    if(!(this.galleryready || this.nophotos)) {
      alert("Gallery loading, please wait.");
      return;
    }
    let imageCount=0;
    if(this.photoimages){
      imageCount=this.photoimages.length;
    }
    if(imageCount>=this.PHOTO_LIMIT){
      alert("Photo count exceed Limit");
      return;
    }
    const dialogRef = this.dialog.open(UploadimagepopupComponent, {
      width: '900px',disableClose:true,data:{
        type:"ED",
        //f2data:this.current_page_db_values,
        casedata:this.cur_db_values,
        count:imageCount,
        tenantName:this.organization.tenantName
      }
    });
    
    dialogRef.afterClosed().subscribe(
      data =>{
        console.log("Dialog output:", data);
        //this.photodata=data;
        if(data){
          //this.buildImageGallery(this.edform2def.caseId);
          this.buildImageGallery();
        }
      });   
  }

  displayVideo(seq){
    if(!this.showVideo){
      this.videourl=this.dataservice.getPrescreeningDonorVideoURL(this.cur_db_values.caseId,seq);
      this.showVideo=true;
     // window.open(this.videourl, '_blank');
    } else {
      this.showVideo=false;
    }
  }
  displayVideoTab(seq){
    if(!this.showVideo){
      this.videourl=this.dataservice.getPrescreeningDonorVideoURL(this.cur_db_values.caseId,seq);
      //this.showVideo=true;
      window.open(this.videourl, '_blank');
    } else {
      this.showVideo=false;
    }
  }
  deleteVideo(seq){
    this.dataservice.deletePrescreeningDonorVideo(this.cur_db_values.caseId,seq).subscribe(res=>{
        console.log(res);
        let resp:any=res;
     if(resp.videos){
      this.videos=resp.videos;
      this.showVideo=false;
      if(this.videos.length>2){
      //  this.fourthFormGroup.controls['videos'].setValue(this.videos.length);
      } else {
      //  this.fourthFormGroup.controls['videos'].setValue("");
      }
     } else {
       this.videos=[];
       this.showVideo=false;
      // this.fourthFormGroup.controls['videos'].setValue("");
     }
     this.showVideo=false;
    });
  }
  buildVideoDisplay(caseId){
    this.showVideo=false;
    this.videos=[];
  //  this.initGalleryConfig();
    this.dataservice.getPrescreeningDonorVideoData(caseId).subscribe(res=>{
     console.log("get video data...");
     console.log(res);
     let resp:any=res;
     if(resp.videos){
      this.videos=resp.videos;
     // this.fifthFormGroup.controls['videos'].setValue(this.videos.length);
     } else {
       this.videos=[];
     //  this.fifthFormGroup.controls['videos'].setValue("");
     }
    });;
  }
  showAddVideoDialog(){
    const dialogRef = this.dialog.open(UploadvideopopupComponent, {
      width: '900px',disableClose:true,data:{
        type:"ED",
        casedata:this.cur_db_values
      }
    });
    
    dialogRef.afterClosed().subscribe(
      data =>{
        console.log("Dialog output:", data);
        //this.photodata=data;
        if(data){
         // here we need update video data;
         this.buildVideoDisplay(this.cur_db_values.caseId);
        }
      });   
  }

  
  async ngAfterViewInit(){
    // 07/23/2022 Add new referral tracking code
    let newReferralURL = environment.REFERRAL_DONOR_TRACKING_LIBRARY_URL;
    await this.loadScript(newReferralURL);
    //await this.loadScript("https://portal.simplifyreferrals.com/assets/js/api.js");
    this.add_donor_referral_library_script();
    // 12/20/2023 Add Egg Donor Application SignUp conversion page
    if (document.getElementById('rp-gadsgtag-donor-id')) { 
      gtag('event', 'conversion', {'send_to': environment.GA_DONOR_SINGUP_EVENT});
    }      
  }

  private loadScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.head.appendChild(scriptElement);
    })
  }

  changeLanguage(lang: string) {
    // Change selected language
      this.language = lang;
      this.setLanguageDisplay(lang);
      this.translateService.use(this.language);
      this.setLanguageDisplay(this.language);
  }

  setLanguageDisplay(lang: string) {
    if (lang == "en")
      this.languageDisplay = this.translateService.instant("ENGLISH");
    else if (lang == 'zh-cn')
      this.languageDisplay = this.translateService.instant("SIMPLIFIED_CHINESE");
    else if (lang == 'zh-tw')
      this.languageDisplay = this.translateService.instant("TRADITIONAL_CHINESE");
    else if (lang == 'es')
      this.languageDisplay = this.translateService.instant("SPANISH");
    else if (lang == 'it')
      this.languageDisplay = this.translateService.instant("ITALIAN");
    else if (lang == 'ja')
      this.languageDisplay = this.translateService.instant("JAPANESE");
  }

  getProcess(data:any){
    this.form_progress.n_required=data.requiredFieldsCount;
    this.form_progress.n_completed=data.requiredFieldsCompletedCount;
    this.form_progress.percent=Math.round(100*this.form_progress.n_completed/this.form_progress.n_required);
    this.form_progress.status=(this.form_progress.percent==100);
    this.form_progress.formstatus=data.status;
    console.log(this.form_progress);
  }

  _dt2str(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
  }

  buildSaveJson(){
    //step1 first set user values
    //this.cur_db_values.emailId=this.dataservice.rsmcserivce.currentUser.emailId;
    //this.cur_db_values.userId=this.dataservice.rsmcserivce.currentUser.username;
    //step2 set remaining form control values
    this.cur_db_values.pages.forEach(pp=>{
      console.log(pp);
      pp.questions.forEach(qq=>{
          let vv=this.form.get(qq.questionControlId).value;
          if(vv!=null){
             if(vv instanceof Date){
                qq.answer=this._dt2str(vv)
              } else {
                qq.answer=vv+"";
              }
          }
          qq.questionItemsJson=""; 
      });
    });
    this.cur_db_values.emailId=this.form.get("email").value;
    return this.cur_db_values;
  }
 
  getDBValues(){
    const vv:any ={};
    if(this.cur_db_values && this.cur_db_values.pages){
      this.cur_db_values.pages.forEach(pp=>{
          console.log(pp);
          pp.questions.forEach(qq=>{
            pp.questions.forEach(qq=>{
              if(qq.answer=="false"){
                vv[qq.questionControlId]=false;
              } else if(qq.answer=='true'){
                vv[qq.questionControlId]=true;
              } else{
                vv[qq.questionControlId]=qq.answer;
              }
          });
          });
      });
    }
    return this.processDateValue(vv);
  }

  validateformcontrols(){
      let formkeys=Object.getOwnPropertyNames(this.form.value);
      let matched=true;
      let dbkeys=Object.getOwnPropertyNames(this.getDBValues());
      dbkeys.forEach(key=>{
        if(!(formkeys.includes(key))){
          console.log("un matched db key:",key);
          matched=false;
        }
      });
      
      formkeys.forEach(key =>{
        if(!(dbkeys.includes(key))){
          console.log("un matched form key:",key);
          matched=false;
        }
      });
      console.log("matched:",matched);
      console.log("set db values")
      let vv=this.getDBValues();
      let r_url=this.sessionservice.get("rp_ReferralURL");
      if(vv["refere_url"]==null ||vv["refere_url"]==""){
        vv["refere_url"]=r_url;
      }
      console.log("set form values");
      console.log(vv);
      this.form.setValue(this.addMissingValue(vv));
      this.lastsavedvalues=vv;

      if(this.cur_db_values.photoDocId){
        vv["photo_id"]=this.cur_db_values.photoDocId;
      }
      // default return user and digits
      vv['returning_user']="";
      vv['enter_digits']="";
  }

  
  autoSaveData(){
    
    if(this.form_progress.formstatus=='prescan-completed'){
      console.log("form submitt, turn off auto save");
      this.clearautosave();
      return;
    }
    let now=new Date();
    
    if(this.lastsaveTS){
      let diff=now.getTime()-this.lastsaveTS.getTime();
      if(diff<1000*25){
        console.log(" just saved skipping...",new Date());
      } else {
        console.log(" auto save data",new Date());
        console.log(this.form.value);
        if(this.compareDifference(this.form.value,this.lastsavedvalues)){
          this.saveData();
        }
        this.lastsaveTS=now;
      }
    } 
    else {
      console.log(" auto save data",new Date());
      //console.log(this.form.value);
      if(this.compareDifference(this.form.value,this.lastsavedvalues)){
        this.saveData();
      }
      this.lastsaveTS=now;
    }
   
  }

  checkEmail(ctrl) { // here we have the 'passwords' group
    //const group=ctrl.parent;
    if (!ctrl.parent) {
      return null;
    }
    console.log(this.confirmEmailError);
    console.log("inside check email",ctrl);
    const confirmemail = ctrl.value;
    const email = ctrl.parent.get('email').value;
    console.log("email-->",email);
    console.log("confirm email--->",confirmemail);

    if (confirmemail != null && email !=null ) {
    if(confirmemail.toLowerCase()!=email.toLowerCase())
    {
        this.confirmEmailError=true;
    } else
    {
        this.confirmEmailError=false;
    }
    console.log(this.confirmEmailError);
    return email.toLowerCase() === confirmemail.toLowerCase() ? null : { matchOther: true } 
    
    } else {
        this.confirmEmailError=true;
    }
  }

  // checkConfirmEmail(ctrl) { // here we have the 'passwords' group
  //   //const group=ctrl.parent;
  //   if (!ctrl.parent) {
  //     return null;
  //   }
  //   console.log(this.confirmEmail2Error);
  //   console.log("inside check email",ctrl);
  //   const email = ctrl.value;
  //   const confirmemail = ctrl.parent.get('confirm_email').value;
  //   console.log("email-->",email);
  //   console.log("confirm email--->",confirmemail);
  //   if(email && email!=confirmemail)
  //   {
  //       this.confirmEmail2Error=true;
  //   } else
  //   {
  //       this.confirmEmail2Error=false;
  //   }
  //   console.log(this.confirmEmail2Error);
  //   return confirmemail === email ? null : { matchOther: true }     
  // }

  initFormControl(){
    this.form = new FormGroup ({
      returning_user: new FormControl('', [Validators.email, Validators.maxLength(50)]),
      enter_digits: new FormControl('', Validators.maxLength(50)),
      firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
      telephone_number: new FormControl('', Validators.maxLength(50)),
      foreign_telephone_number: new FormControl('', Validators.maxLength(50)),
      program: new FormControl(''),
      streetAdd: new FormControl('', Validators.maxLength(5000)),
      address_2: new FormControl('', Validators.maxLength(500)),
      city: new FormControl('', Validators.maxLength(500)),
      state: new FormControl(''),
      // newly added 03/2023
      parentage: new FormControl(""),
      ovaries: new FormControl(""),
      used: new FormControl(""),
      racial_description: new FormControl(""),
      // end of change 03/2023
      // streetAdd: new FormControl('', Validators.required),
      // address_2: new FormControl(''),
      // city: new FormControl('', Validators.required),
      // state: new FormControl('', [Validators.required]),
      // outside_USA: new FormControl(''),
      // zip_code: new FormControl('', Validators.required),
      // country: new FormControl('', Validators.required),
      zip_code: new FormControl('', Validators.maxLength(50)),
      country: new FormControl(''),
      foreignAddress: new FormControl('', Validators.maxLength(5000)),
      birthDate: new FormControl(new Date(), Validators.required),
      // country_birth: new FormControl('', Validators.required),
      height_feet: new FormControl('', [Validators.required, Validators.maxLength(1),  Validators.min(0), Validators.pattern("^[0-9]*$")]),
      height_in: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.min(0), Validators.max(12)]),
      weight: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.min(0)]),
      eye_color: new FormControl('', [Validators.required]),
      hair_color: new FormControl('', [Validators.required]),
      bloodType: new FormControl('', [Validators.required]),
      highest_level_education : new FormControl('', [Validators.required]),
      Black: new FormControl(false),
      // Asian: new FormControl(false),
      Chinese: new FormControl(false),
      Japanese: new FormControl(false),
      Other_Asian: new FormControl(false),
      Caucasian: new FormControl(false),
      Latino: new FormControl(false),
      American_Indian_Alaska_Native: new FormControl(false),
      Native_Hawaiian_Pacific_Islander: new FormControl(false),
      // Middle_Eastern: new FormControl(false),
      // racial_description: new FormControl('', Validators.maxLength(5000)),
      tribe: new FormControl(0, [Validators.required]),
      // parentage: new FormControl(0),
      bioChildren: new FormControl(0, [Validators.required]),
      pregnant: new FormControl(0, [Validators.required]),
      // list_ages_children: new FormControl(''),
      breastfeeding: new FormControl(0, [Validators.required]),
      // ovaries: new FormControl(0),
      //tobacco: new FormControl(0, [Validators.required]),
      // used: new FormControl(0),
      //medications: new FormControl(0, [Validators.required]),
      // alcoholism: new FormControl(0, [Validators.required]),
      felony: new FormControl(0, [Validators.required]),
      covid: new FormControl(0),
      covid_explain: new FormControl('', Validators.maxLength(50)),
      hear_about_us: new FormControl('', [Validators.required]),
      provide_names: new FormControl('', Validators.maxLength(5000)),
      egg_donor_before: new FormControl(0),
      body_type: new FormControl(0),
      hair_type: new FormControl(0),
      complexion: new FormControl(0),
      dominant_hand: new FormControl(0),
      vision: new FormControl(0),
      orthodontic_work: new FormControl(0),
      baldness: new FormControl(0),
      photoes: new FormControl('', [Validators.required]),
      videos: new FormControl(''),
      refere_url: new FormControl(''),
      // explain_about_us: new FormControl(''),
      // check: new FormControl(false),
      agree: new FormControl(0, [Validators.required]),
      alias: new FormControl(''),
      casenotes: new FormControl(''),
      confirm_email: new FormControl('', [Validators.required, Validators.email, this.checkEmail.bind(this), Validators.maxLength(50)])
    },{validators:this.validatTab1.bind(this)});
  }
  
  saveTab1(){
    const newvv=this.buildSaveJson();
    console.log(newvv);
    this.dataservice.saveEDPresceeningForm(newvv).subscribe((res:any)=>{
        console.log(res);
        let resp:any=res;
        if(resp.status=="FAIL"){
            this.recordexists=true;
            //this.message=resp.message;
            this.message = "Applicant with email " + resp.data.emailId + " has submitted this form already. You can click the button below to check the status of the second part of the application."
            this.inviteurl=resp.data.invitationUrl;
        } else {
          this.first=false;
          this.cur_db_values=res.data;
          this.buildImageGallery();
        }
        
    });
    let vv=this.getDBValues();
    if(vv["returning_user"] === undefined) {
      vv["returning_user"]=null;
      vv["enter_digits"]=null;
      vv["photoes"]=0;
      vv["videos"]=0;
    }
      this.form.setValue(this.addMissingValue(vv));
      this.lastsavedvalues=vv;

    if (document.getElementById('rp-fbfbq-id')) {
        fbq('track', 'Lead');
    }

    let str_appType = this.sessionservice.get('rp_AppType');
    if (str_appType == "DONOR") {   
      // 12/20/2023 Add Egg Donor Application SignUp conversion page
      if (document.getElementById('rp-gadsgtag-donor-id')) { 
          //let str_URL = window.location.href + this.router.url;
          //console.log ("URL_Path: " + str_URL);
          //gtag_report_conversion(str_URL);
          gtag('event', 'conversion', {'send_to': environment.GA_DONOR_SINGUP_EVENT});
          // 03/31/2024 Add Google ads (Adwords) Tracking codes for Egg Donors
          gtag('event', 'conversion', {'send_to': environment.GA_DONOR_NEXT_BUTTON});
      }      
    } else if (str_appType == "GC")  {
      if (document.getElementById('rp-gadsgtag-id')) { 
          gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});
      }
    }

    this.send_RR_RP_Data();
  }

  send_RR_RP_Data(){
    let temp_Telephone_Number = "";
    if(this.form.controls.country.value=="USA"){    
      temp_Telephone_Number =  this.form.controls.telephone_number.value;
    } else if (this.form.controls.country.value=='Outside of USA') {
      temp_Telephone_Number =  this.form.controls.foreign_telephone_number.value;
    }    

    const str_appType = this.sessionservice.get('rp_AppType');
    if (str_appType == "DONOR") {    
      if (!document.getElementById('rrdatacollection-id')) {
          const rrScript = document.createElement('script');
          rrScript.setAttribute("id", "rrdatacollection-id");
          rrScript.innerHTML = `
            window.rrSpace = (
              rrSettingsConversion = {
                debug: "false",
                parameters: {
                  firstname: "${this.form.controls.firstName.value}",
                  lastname: "${this.form.controls.lastName.value}",
                  email: "${this.form.controls.email.value}",
                  phone: "${temp_Telephone_Number}",
                  referralcode: "${this.sessionservice.get('rp_ReferralCode')}",
            
                  conversionstatus: "PROGRAMRULE",
                  customtext1name: "Phase 4: Matched",
                  customtext2name: "Donor Retrieval Completed",
                  customoption1name: "Preliminary Acceptance",
                  customoption2name: "Program Acceptance"
                }
              }
            );
            (function (f, r, n, d, b, y) { b = f.createElement(r), y = f.getElementsByTagName(r)[0]; b.async = 1; b.src = n; b.id = 'RR_DIVID'; y.parentNode.insertBefore(b, y); })(document, 'script', '//physicianssurrogacy.referralrock.com/webpixel/beta/universalv03.js');
          `;
          document.body.appendChild(rrScript);      

          console.log ("send_RR_RP_Data");
      } else {
        console.log ("RR_RP_Data already exists")
      }
    }else if (str_appType == "GC") {  
      console.log ("Not Applied to Surrogate")
    }
  }

  add_donor_referral_library_script() {
    if (!document.getElementById('rrdatacollection1-id')) {
        // 07/23/2022 Add new Referral Tracking Code      
          // const gcReferralScript = document.createElement('script');
          // gcReferralScript.async = true;
          // gcReferralScript.type='text/javascript';
          // gcReferralScript.src = environment.REFERRAL_GC_TRACKING_LIBRARY_URL;
          //   //'https://portal.simplifyreferrals.com/assets/js/api.js';
          // document.head.appendChild(gcReferralScript);        
          
          const rrScript1 = document.createElement('script');
          rrScript1.setAttribute("id", "rrdatacollection1-id");
          rrScript1.innerHTML = `
            jQuery(document).ready(function($){   
              window.addEventListener("ref_form_submit", function(e) {
                var ret_val = e.detail;
                console.log("jquery_function");
                console.log(ret_val);
              }, false);
        
              register_referral({
                        "trigger":"#next_button",	
                        "key":"${environment.REFERRAL_DONOR_TRACKING_KEY}",	
                        "ref_type":"referral",	
                        "name":"#firstName_field",	
                        "phone":"#telephone_number_field",	
                        "email":"#email_field",	
                        "dob":"#birth_date_field"
                    });
            });  
          `;

          document.body.appendChild(rrScript1);   
          
          console.log ("send_RR_RP_Data -- Version 2");
    } else {
          console.log ("RR_RP_Data V2 already exists");
    }               
  }

  goForm2(){
    if(this.inviteurl){
      window.open(this.inviteurl,"_blank");
    }
  }
  validatTab1(formgroup:FormGroup){ 
    this.tab1valid=false;
    if(this.first){
      if(formgroup){ 
        this.tab1valid=false;
        if(formgroup.controls.firstName.valid&&formgroup.controls.lastName.valid&&formgroup.controls.email.valid&&formgroup.controls.confirm_email.valid&&!this.confirmEmailError){
          if(formgroup.controls.country.valid&&formgroup.controls.birthDate.valid){
            if(formgroup.controls.country.value=="USA"){
              if(formgroup.controls.telephone_number.valid){
                this.tab1valid=true;
              }
            } else if(formgroup.controls.country.value=='Outside of USA'){
              if(formgroup.controls.foreign_telephone_number.valid){
                this.tab1valid=true;
              }
            }
          }
        }
      }
    }
  }
  checkEmailMatch() {
    console.log("MATCHCCCCCCHHHHH")
    const email = this.form.get('email')
    const confirm = this.form.get('confirm_email')

    if (email.value !=null && confirm.value != null) {

    if (email.value.toLowerCase() === confirm.value.toLowerCase()) {
      // this.emailMatch=true;
      this.confirmEmailError = false
      console.log("TRUEEEEEEE")
      this.form.get('confirm_email').setErrors(null);
    } else {
      // this.emailMatch=false;
      this.confirmEmailError = true
      // this.form.get('email')
      this.form.get('confirm_email').setErrors({ 'Email Address not Matching.': true });
      console.log("FALAAAASEEEE")
    }

    } else {
      this.confirmEmailError=true;
  }
      this.validatTab1(this.form)
 
  }

  checkRace() {
    // console.log("CHECKRACECALLED")
    const black = this.form.get('Black')
    // const asian = this.form.get('Asian')
    const chinese = this.form.get('Chinese')
    const japanese = this.form.get('Japanese')
    const otherasian = this.form.get('Other_Asian')
    const caucasian = this.form.get('Caucasian')
    const latino = this.form.get('Latino')
    const native_american = this.form.get('American_Indian_Alaska_Native')
    const pacific_islander = this.form.get('Native_Hawaiian_Pacific_Islander')

    if (black.value || chinese.value || japanese.value || otherasian.value || caucasian.value || latino.value || native_american.value || pacific_islander.value) {
      // console.log("YAAAAAAAAAS")
      this.raceChecked=true
    } else {
      // console.log("NOOOOOOOOOOOO")
      this.raceChecked=false
    }
  }

  foreignValidator() {

    console.log("FOREIGNVALIDATOR RUNNING")

    const foreignAddress = this.form.get('foreignAddress')
    const address = this.form.get('streetAdd')
    const city = this.form.get('city')
    const state = this.form.get('state')
    const zip = this.form.get('zip_code')
    const phone = this.form.get('telephone_number')
    const foreignPhone = this.form.get('foreign_telephone_number')

    this.form.get('country').valueChanges.subscribe(country => {
      if (country == "USA") {
        console.log("USA")
        foreignAddress.setValidators(null);
        address.setValidators([Validators.required]);
    //    address2.setValidators([Validators.required]);
        city.setValidators([Validators.required]);
        state.setValidators([Validators.required]);
        zip.setValidators([Validators.required]);
        
        foreignPhone.setValidators(null);
        phone.setValidators([Validators.required]);

      } else if(country=='Outside of USA'){
        console.log("outside")
        foreignAddress.setValidators([Validators.required]);
        address.setValidators(null);
        city.setValidators(null);
        state.setValidators(null);
        zip.setValidators(null);

        phone.setValidators(null);
        foreignPhone.setValidators([Validators.required]);
      } else {
        address.setValidators(null);
        city.setValidators(null);
        state.setValidators(null);
        zip.setValidators(null);
        foreignAddress.setValidators(null);
        foreignPhone.setValidators(null);
        phone.setValidators(null);
      }

      foreignAddress.updateValueAndValidity()
      address.updateValueAndValidity()
      city.updateValueAndValidity()
      state.updateValueAndValidity()
      zip.updateValueAndValidity()
      foreignPhone.updateValueAndValidity()
      phone.updateValueAndValidity()
    }) 

  }
  

  compareDifference(newvalues,oldvalues){
    let data_changed=false;
    console.log(newvalues);
    console.log(oldvalues);
    for(const pkey in oldvalues){
      if(newvalues[pkey]!==oldvalues[pkey]){
        console.log("data changed found!! -->",pkey);
        console.log("old value:",oldvalues[pkey]);
        console.log("new value:",newvalues[pkey]);
        data_changed=true;
        break;
      }
    }

    console.log("Data Changed:",data_changed);
    return data_changed;
  }

  onSubmit() {
    if (this.form.valid) {
      console.log("Form Submitted!", this.form.value);
      /* this.Submit(this.service.form.value).subscribe((result) => {
        console.warn("Result is here", result);
      }) */
    }
  }
  


  clearautosave(){
    if(this.autosavecontrol){
      clearInterval(this.autosavecontrol);
    }
  }

  ngOnDestroy() {
    console.log("clear interval");
    this.clearautosave();
  }

  addMissingValue(values){
    if(values["returning_user"] === undefined) {
      values["returning_user"]=null;
      values["enter_digits"]=null;
    }
    if(values["photoes"]==undefined){
      values["photoes"]=0;
    }
    if(values["videos"]==undefined){
      values["videos"]=0;
    }
    return values;
  }
  saveData(){
    const newvv=this.buildSaveJson();
    console.log(newvv);
       this.dataservice.saveEDPresceeningForm(newvv).subscribe(res=>{
        console.log(res);
        this.getProcess((<any>res).data);
        this.cur_db_values=(<any>res).data;
      });
      let vv=this.getDBValues();
      this.form.setValue(this.addMissingValue(vv));
      this.lastsavedvalues=vv;
    this.lastsaveTS=new Date();
  }

  finalize(){
    const newvv=this.buildSaveJson();
    this.processSubmit=true;
    console.log(newvv);

    // 03/31/2024 Add pre-qualification tracking
 
      if (document.getElementById('rp-fbfbq-id')) {
        fbq('track', 'CompleteRegistration');
      }

      let str_appType = this.sessionservice.get('rp_AppType');
      if (str_appType == "DONOR") {   
        if (document.getElementById('rp-gadsgtag-donor-id')) {
            console.log("gtag-pre-qualification tracking");
            gtag('event', 'conversion', {'send_to': environment.GA_DONOR_PREQUALIFICATION_TRACKING});
        }      
      } else if (str_appType == "GC")  {
        // if (document.getElementById('rp-gadsgtag-id')) { 
        //     gtag('event', 'conversion', {'send_to': environment.GADS_GC_TRACKING_NEXT_EVENT});
        // }
      }
      
       this.dataservice.finalizedEDPrescreening(newvv).subscribe((res:any)=>{
        console.log(res);
       // this.getProcess((<any>res).data);
        this.dataservice.pushEDformvalue(res.data);
        this.processSubmit=false;
        const dialogRef = this.dialog.open(EDApprovalViewComponent, {
          panelClass: 'custom-dialog-container', disableClose:true
        });
        /* this.router.navigate(["/"+this.organization.tenantName+"/edapproval"]); */
      },
      err=>{
        this.errorhandler.handle(err);
        this.processSubmit=false;
      }
      );
    
  }

  getDateFields(){
    return ['birthDate'];
  }
  processDateValue(vv){
    this.getDateFields().forEach(key=>{
      if(vv[key]!=null && vv[key]!=""){
        vv[key]=new Date(vv[key]);
      }

    });
    return vv;
  }

  Bmi() {
    var f = self.document.forms[0];
    let w = f.wt.value;
    let u = f.hti.value;
    let v = f.htf.value;
    var i =  f.htf.value * 12 + f.hti.value*1.0;
    let bmi: any = (w/(i*i))*703;
    let f_bmi = Math.floor(bmi);
    let diff  = bmi - f_bmi;
    diff = diff * 10;
    diff = Math.round(diff);
    if (diff == 10)
    {
      f_bmi += 1;
      diff   = 0;
    }
    bmi = f_bmi + "." + diff;
    if(isNaN(bmi)){
      bmi="";
  }
    return bmi;
  }

  initGalleryConfig(){
    this.galley_config= {
      strategy: PlainGalleryStrategy.ROW,
      layout: new LineLayout({ width: '60px', height: '60px' }, { length: 5, wrap: true }, 'center')
    };
    this.customButtonsConfig = {
      visible: true,
      strategy: ButtonsStrategy.CUSTOM,
      buttons: [ {
        className: 'inside fullscreen-image',
        type: ButtonType.FULLSCREEN,
        fontSize: '30px',
        ariaLabel: 'custom close aria label',
        title: 'full screen'
       },
        {
          className: 'inside download-image',
          type: ButtonType.CUSTOM,
          fontSize: '30px',
          ariaLabel: 'custom close aria label',
          title: 'Download Image'
        },
        {
          className: 'inside delete-image',
          type: ButtonType.DELETE,
          fontSize: '30px',
          ariaLabel: 'custom close aria label',
          title: 'Delete Image'
        },
        {
          className: 'close-image',
          type: ButtonType.CLOSE,
          fontSize: '20px',
          ariaLabel: 'custom close aria label',
          title: 'Close'
        }]
      // Invoked after a click on a button, but before that the related
      // action is applied.
    }
    
  }
  // gallery functions
  buildImageGallery(){
    this.galleryready=false;
    this.photoimages=[];
    this.bufferimages=[];
    this.initGalleryConfig();
    this.dataservice.getPrescreeningDonorImageData(this.cur_db_values.caseId).subscribe(res=>{
      console.log(res);
      this.photodata=res;
      if(this.photodata.images && this.photodata.images.length>0){
        console.log("loading image content for gallery");
        this.loadImageContent();

      } else {
        console.log("no image found loaded");
        this.galleryready=true;
        this.nophotos=true;
      }
      //this.edform2.controls['photos'].setValue(this.photodata.images.length)
    });
  }

  loadImageContent(){
    // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/image/{seqNo}
    this.galleryready=false;
    this.nophotos=false;
    this.photoimages=[];
    this.bufferimages=[];
    const tenant=this.dataservice.authservice.getOrgName();
    const caseId=this.cur_db_values.caseId;
    if(this.photodata.images){
      
      this.photodata.images.forEach(img=>{
        ///api/v1/Prescanning/tenant/{tenantName}/form/eggdonor/case/{caseId}/image/{seqNo}
        let imgapiurl=this.dataservice.authservice.apiBase+"Prescanning/tenant/"+tenant+"/form/eggdonor/case/"+caseId+"/image/"+img.seqNo;
        //let thumbapiurl=imgapiurl+"/thumbnail";
       // console.log(imgapiurl);
        this.dataservice._getImageBlob(imgapiurl).subscribe(resp=>{
          const file = new Blob([<any>resp], {type: 'image/jpeg'});
          let _imageurl = URL.createObjectURL(file);
          let image_url=this.santilizer.bypassSecurityTrustResourceUrl(_imageurl);
          /*
          console.log("----->")
          console.log(imageurl)
          this.photoimages.push(new Image(img.seqNo,{img:imgapiurl},{img:thumbapiurl}));
          */
       
            this._addGalleryImage(img.seqNo,image_url,image_url,img.fileName);
         
        });
      });
    } else{
      this.galleryready=true;
    }
  }

  _addGalleryImage(id,img_url,thumb_img_url,fn){
    
    this.bufferimages.push(new Image(id,{img:img_url,title:fn,description:fn,downloadFileName:fn},{img:thumb_img_url,title:fn,description:fn}));
  //  console.log("add image --->",this.photoimages);
  //  console.log("current size: ===>",this.photoimages.length,"   ",this.photodata.images.length);
    if(this.bufferimages.length==this.photodata.images.length){
      // reorder
      this.photodata.images.forEach(img=>{
        this.bufferimages.forEach(img2=>{
            if(img2.id==img.seqNo){
              this.photoimages.push(img2);
              // this.selectPhoto = true;
              // console.log("Select Photo: " + this.selectPhoto);
              // console.log("img: " + img.seqNo);
              // console.log("img2: " + img2.id);
            }
        });
      });
      this.galleryready=true;
    }
  }
  

}
