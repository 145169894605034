export class RPUser {
    userId: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    authToken: string;
    phone: string;
    emailId:string;
    accountType:string;  
    orgUnitId: string;
    orgName:string;
    elorgId: string;
}