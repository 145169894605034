import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService, AlertService } from '@app/core/services';
import { RPErrorHandler } from '@app/services/dataservice.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private service: AuthService,
        private alertService: AlertService,
        private errorhandler:RPErrorHandler
    ) { 

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
       // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // here we need to validate the orgnization
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
      //  this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.service.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                   // this.router.navigate([this.returnUrl]);
                  
                   console.log(data);
                   if(data.accountType=='donor'){
                       console.log('route to donor prescreening page');
                       this.router.navigate(["/"+this.service.orgValue.tenantName+"/donor"]);
                   } else if(data.accountType=='surrogate') {
                      console.log('route to donor surrogate page');
                      this.router.navigate(["/"+this.service.orgValue.tenantName+"/gc"]);
                   }
                },
                error => {
                //    this.alertService.error(error);
                //    console.log(error);
                    this.errorhandler.handle(error);
                    this.loading = false;
                });
    }
}