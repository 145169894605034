<form style="text-align: center;"  [formGroup]="regForm" (ngSubmit)="register()">
<div class="col-md-8 offset-md-3  mt-5 ">
    <mat-card class="z-depth center" >
        <br>
      <div *ngIf="orgnization.accountType=='donor'">
        <p style="text-align: center;"><b>Welcome to <font color=Green>{{orgnization.tenantName.toUpperCase()}}</font> - Egg Donation</b></p>
      </div>
      <div *ngIf="orgnization.accountType=='surrogate'">
        <p style="text-align: center;"><b>Welcome to <font color=Green>{{orgnization.tenantName.toUpperCase()}}</font> - Surrogate</b></p>
      </div>
      <p style="text-align: center;">To see if you qualify for our program, please register for an account. Once registered you will be prompted to login.</p>
      <mat-card-content>
        
          <mat-form-field style="width:300px !important">
            <input matInput formControlName="firstName" placeholder="First Name" required>
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field style="width:300px !important">
            <input matInput formControlName="lastName" placeholder="Last Name" required>
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field style="width:300px !important">
            <input matInput formControlName="email" placeholder="Email" required>
            <mat-error>Invalid email address.</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field style="width:300px !important">
            <input matInput formControlName="password" type="password" placeholder="Password" required>
            <mat-error *ngIf="regForm.controls['password'].errors?.minlength">Minimum 8 characters needed.</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field style="width:300px !important">
            <input matInput appConfirmp="password" formControlName="confirmPassword" type="password" placeholder="Confirm Password" required>
          </mat-form-field>
          <br>
        <mat-error *ngIf="confirmedPasswordError">Password and Confirm Password does not match.</mat-error> 
         <mat-error *ngIf="registerfailed" style="font-size: 8pt;">{{failedmessage}}</mat-error>
         <br>
         <button mat-raised-button color="primary" [disabled]="!regForm.valid" type="submit">Register</button>
          <br>
          <p>Already registered?  <a routerLink="../login" class="btn btn-link">Login</a></p>
      
      </mat-card-content>
    </mat-card>
</div>
</form>