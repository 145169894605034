import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './core/guard';

import { AppRoutingModule } from './app-routing.module';
import { ErrorInterceptor } from './core/guard';
import { AppComponent } from './app.component';
import { AlertComponent } from './core/components';
import { HomeComponent } from './home';;
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CommonModule } from '@angular/common';
import { LoginComponent, RegisterComponent } from './account';
import { MaterialModule } from './core/material/material.module';
import { EggdonationComponent } from './eggdonation/form1/eggdonation.component';
import { SpinnerContainer } from './components/spinner-container/spinner-container.component';
import { GestationalcarrierComponent } from './gestationalcarrier/form1/gestationalcarrier.component';
import { EDApprovalViewComponent } from './eggdonation/edapprovalview/approvalview.component';
import { NgxMaskModule } from 'ngx-mask';
import { GCApprovalViewComponent } from './gestationalcarrier/approvalview/gcapprovalview.component';

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { UploadDocPopupComponent } from './gestationalcarrier/uploaddocpopup/uploaddocpopup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import "hammerjs"
import 'mousetrap';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import { AuthorizationFormDialog } from './gestationalcarrier/authorizationform/ps-authorizationform.component';
import { AlbumComponent } from './gestationalcarrier/album/album.component';
import { UploadImagePopupComponent } from './gestationalcarrier/uploadimagepopup/uploadimagepopup.component';
import {MatDialogModule, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DragDropDirective } from './gestationalcarrier/uploadimagepopup/drag-drop.directive';
import { MatStepperModule } from '@angular/material/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadimagepopupComponent } from './eggdonation/uploadimagepopup/uploadimagepopup.component';
import { UploadvideopopupComponent } from './eggdonation/uploadvideopopup/uploadvideopopup.component';
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

@NgModule({
    imports: [
        GalleryModule.forRoot(),
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        CommonModule,
        MaterialModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatStepperModule,
        FlexLayoutModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: createTranslateLoader,
              deps: [HttpClient]
            }
          }) ],
    declarations: [
        AppComponent,
        AlertComponent,
        LoginComponent,
        RegisterComponent,
        HomeComponent,
        EggdonationComponent,
        SpinnerContainer,
        GestationalcarrierComponent,
        EDApprovalViewComponent,
        GCApprovalViewComponent,
        UploadDocPopupComponent,
        AuthorizationFormDialog,
        AlbumComponent,
        UploadImagePopupComponent,
        DragDropDirective,
        UploadimagepopupComponent,
        UploadvideopopupComponent ],
    providers: [
      { provide: MAT_DIALOG_DATA, useValue: {} },
      { provide: MatDialogRef, useValue: {} },
      Title
     //   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
    //    fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };