<div class="container">
  <h2><font color="secondary">{{ "GENERAL.UPLOAD_VIDEO" | translate }}</font></h2>
  <hr>
      <table width=98%>
        <tr>
          <td width=60% height=405 valign=top>
              <div class="uploadfilecontainer" *ngIf='(!uploading) && files.length==0' (click)="fileInput.click()" appDragDrop (onFileDropped)="dragAndDropFile($event)">
                  <label class="heading-label">{{ "GENERAL.DRAG_UPLOAD_VIDEO" | translate }}</label>
                  <input hidden type="file" accept="video/*" #fileInput (change)="uploadInputFile($event.target.files)">
              </div>
              <div *ngIf="uploading" >
                <div *ngFor="let file of files;let i=index" class="progress-section">
                <mat-progress-bar
                  color="primary"
                  [mode]="determinate"
                  [value]="progressInfo[file.name]"
                 [bufferValue]="bufferValue">
                </mat-progress-bar>
                  <span class="progressText">{{ "GENERAL.VIDEO" | translate }} {{ file.index }} -- {{progressInfo[file.name]}}% </span>
              </div>
              <div *ngIf='!complete'>
                <mat-spinner diameter='20'></mat-spinner>
                <span><font size=+1 color=green>{{uploadMessage}}</font></span> 
            </div>
              </div>
          </td>
          <td width=40% valign=top>
            <mat-dialog-content>
              <table width=99%  class="files-list" *ngFor="let file of files;let i=index">
                <tr>
                  <td>{{ file.name }}</td>
                  <td width=30><button class="delete-file" (click)="deleteAttachment(i)">
                    <img src="/assets/images/Recycle_Bin_Full.png">
                </button></td>
                </tr>
              </table>
            </mat-dialog-content>           
          </td>
      </tr>
      <tr style="text-align: center;">
          <td>
              <div class="uploadbtn">
                      <button [disabled]="files.length == 0" mat-raised-button color="accent"  (click)="uploadVideo()">
                        {{ "GENERAL.UPLOAD" | translate }}
                      </button>
              </div>
          </td>
          <td>
            <div class="uploadbtn">
            <button mat-raised-button  (click)="closeme()" color="warn" type="button">{{ "GENERAL.CANCEL" | translate }}</button>
            </div>
          </td>
      </tr>
    </table>
</div>
