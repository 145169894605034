import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '@app/services/dataservice.service';
import {PlainGalleryConfig, AccessibilityConfig, Image, ButtonEvent,ImageEvent,ButtonsConfig,ButtonsStrategy,ButtonType,  PlainGalleryStrategy, LineLayout } from '@ks89/angular-modal-gallery';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadImagePopupComponent } from '../uploadimagepopup/uploadimagepopup.component';
import { subscribeOn } from 'rxjs/operators';
@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  photoimages=[];
  bufferimages=[];
  photodata:any={};
  galleryready=false;
  // selectPhoto=false;
  currentIndex=0;
  PHOTO_LIMIT=20;
  constructor(
    public dialogRef: MatDialogRef<AlbumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private santilizer:DomSanitizer,
    private dataservice:DataService,
    public dialog: MatDialog
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    console.log(this.data);
    this.buildGallery();
  }

  buildGallery(){
    this.buildImageGallery();
  }


  onChangeImage(event){
    console.log(event);
    this.currentIndex=event.result-1;
  }
  
  buildImageGallery(){
    this.galleryready=false;
    this.photoimages=[];
   // this.bufferimages=[];
    this.dataservice.getImageData(this.data.tenantName,this.data.caseId).subscribe(res=>{
      console.log(res);
      this.photodata=res;
      if(this.photodata.images && this.photodata.images.length>0){
        console.log("loading image content for gallery");
        this.loadImageContent();

      } else {
        console.log("no image found loaded");
        this.galleryready=true;
      }
      //this.edform2.controls['photos'].setValue(this.photodata.images.length)
    });
  }

  loadImageContent(){
    // /api/v1/Form1/tenant/{tenantName}/form/surrogate/case/{caseId}/image/{seqNo}
    this.galleryready=false;
    this.photoimages=[];
    this.bufferimages=[];
    const tenant=this.data.tenantName;
    const caseId=this.data.caseId;
    if(this.photodata.images){
      
      this.photodata.images.forEach(img=>{
        let imgapiurl=this.dataservice.authservice.apiPS+"Form1/tenant/"+tenant+"/form/surrogate/case/"+caseId+"/image/"+img.seqNo;
        //let thumbapiurl=imgapiurl+"/thumbnail";
       // console.log(imgapiurl);
        this.dataservice._getImageBlob(imgapiurl).subscribe(resp=>{
          const file = new Blob([<any>resp], {type: 'image/jpeg'});
          let _imageurl = URL.createObjectURL(file);
          let image_url=this.santilizer.bypassSecurityTrustResourceUrl(_imageurl);
          /*
          console.log("----->")
          console.log(imageurl)
          this.photoimages.push(new Image(img.seqNo,{img:imgapiurl},{img:thumbapiurl}));
          */
       
            this._addGalleryImage(img.seqNo,image_url,image_url,img.fileName);
         
        });
      });
    } else{
      this.galleryready=true;
    }
  }

  _addGalleryImage(id,img_url,thumb_img_url,fn){
    
    this.bufferimages.push(new Image(id,{img:img_url,title:fn,description:fn,downloadFileName:fn},{img:thumb_img_url,title:fn,description:fn}));
  //  console.log("add image --->",this.photoimages);
  //  console.log("current size: ===>",this.photoimages.length,"   ",this.photodata.images.length);
    if(this.bufferimages.length==this.photodata.images.length){
      // reorder
      this.photodata.images.forEach(img=>{
        this.bufferimages.forEach(img2=>{
            if(img2.id==img.seqNo){
              this.photoimages.push(img2);
              // this.selectPhoto = true;
              // console.log("Select Photo: " + this.selectPhoto);
              // console.log("img: " + img.seqNo);
              // console.log("img2: " + img2.id);
            }
        });
      });
      this.galleryready=true;
    }
  }
  

  deletePhoto(img){
    console.log("admin remove photoes");
    console.log(img);
    // if(this.photoimages.length<=3){
    //   alert("Need to keep at least 3 photos.");
    //   return;
    // }
    this.dataservice.removePhoto(this.data.organization.tenantName,this.photodata.caseId,img.seqNo).subscribe(res=>{
      console.log(res);
      this.photodata=res;
      this.loadImageContent();
    });
  }

  addPhoto(){
    let imgcount=0;
    if(this.photoimages){
      imgcount=this.photoimages.length;
    }
    if(imgcount>=this.PHOTO_LIMIT){
      window.alert("Can't upload more than "+this.PHOTO_LIMIT+" photos.");
      return;
    }
    let uploadphoto_dialog=this.dialog.open(UploadImagePopupComponent,
      {width: '800px',
       data: {count:imgcount,caseId:this.data.caseId,emailId:this.data.emailId,tenantName:this.data.tenantName}
      });
      uploadphoto_dialog.afterClosed().subscribe(res=>{
        if(res!=null){
          this.photodata=res;
          this.loadImageContent();
        }
      });
  }

  closeMe(){
    this.dialogRef.close({caseId:this.data.caseId,photocount:this.photoimages.length})
  }
}
