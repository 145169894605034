import { Component, Inject, PLATFORM_ID } from '@angular/core';

import { AuthService } from './core/services';
import { RPUser } from './core/models';
import { TranslateService } from "@ngx-translate/core";
import { isPlatformServer } from '@angular/common';
import { SessionService} from "./core/services";

import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
declare let gtag:Function;
declare let fbq:Function;

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: RPUser;
    organization:any;

    constructor(private accountService: AuthService, private session: SessionService, 
        private translateService: TranslateService, @Inject(PLATFORM_ID) private platformId, private router: Router) {
        this.accountService.user.subscribe(x => this.user = x);
        this.accountService.org.subscribe(x=>this.organization=x);
        this.translateService.setDefaultLang("en");

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translateService.use("en");

        this.retrieveReferralURL();

        this.detectDonorGCAppType();

        this.retrieveReferralCode();   
        
        this.addGAScript();

        this.addGAdsScript();

        this.addFacebookScript();

        router.events.subscribe((y: NavigationEnd) => {
            if(y instanceof NavigationEnd){
                console.log(y.url);
                console.log(y.urlAfterRedirects);       
                let str_appType = this.session.get('rp_AppType');
                if (str_appType == "DONOR") {   
                  if (document.getElementById('rp-gagtag-id')) {                       
                    gtag('config',environment.GA_DONOR_TRACKING_ID,{'page_path' : y.url});
                  }

                  if (document.getElementById('rp-fbfbq-id')) {
                    fbq('track', 'PageView');
                  }
                } else if (str_appType == "GC") {
                  // 06/23/2022 Remove Google Analytics 
                  // if (document.getElementById('rp-gagtag-id')) {                                         
                  //   gtag('config',environment.GA_GC_TRACKING_ID, {'page_path' : y.url});
                  // }

                  if (document.getElementById('rp-gadsgtag-id')) {                                         
                    gtag('config',environment.GADS_GC_TRACKING_ID, {'page_path' : y.url});
                  }

                  if (document.getElementById('rp-fbfbq-id')) {                  
                    fbq('track', 'PageView');
                  }
                }  
            }
          })
    }

    logout() {
        this.accountService.logout();
    }

    retrieveReferralURL(){
      let strReferralURL = "";
      this.session.set('rp_ReferralURL', "");
      const strURL = window.location.href;
      strReferralURL=this.extractUrlValue('page', strURL.toLowerCase());    
      if (strReferralURL != null) {
        this.session.set('rp_ReferralURL', strReferralURL);
      } else {
        if (!isPlatformServer(this.platformId)) {
          strReferralURL = document.referrer;
          this.session.set('rp_ReferralURL', strReferralURL);   
        }      
      }
      console.log ("rp_ReferralURL: " +  this.session.get('rp_ReferralURL'));           
    }

    detectDonorGCAppType(){
      this.session.set('rp_AppType', "UNKNOWN");

      let strSiteURL = window.location.href;
      let donorSite = strSiteURL.match(/\/[\w\d-]+\/donor/g);
      let gcSite = strSiteURL.match(/\/[\w\d-]+\/gc/g);
      let gcApprovalSite = strSiteURL.match(/\/[\w\d-]+\/gcapproval/g);

      if (donorSite) {
        this.session.set('rp_AppType', 'DONOR'); 
      }else if (gcSite && !gcApprovalSite){
        this.session.set('rp_AppType', 'GC');        
      }

      console.log("rp_AppType:" + this.session.get('rp_AppType'));
    }

    extractUrlValue(key:string, url:string)
    {
        if (url == null)
            url = window.location.href;
        var match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    }

    retrieveReferralCode(){
      this.session.set('rp_ReferralCode', "");

      const strURL = window.location.href;
      let strReferralCode=this.extractUrlValue('REFERRALCODE', strURL.toUpperCase());    
      if (strReferralCode != null) {
        this.session.set('rp_ReferralCode', strReferralCode);
      } else {
        // 06/23/2022 Support new Referral Tracking for GC
        // 07/23/2022 Support new Referral Tracking for both GC and Donor
        //let str_appType = this.session.get('rp_AppType');
        //if (str_appType == "GC") {
          //const strURL1 = window.location.href;
          let strReferralCode1=this.extractUrlValue('REF_CODE', strURL.toUpperCase());                
          if (strReferralCode1 != null) {
            this.session.set('rp_ReferralCode', strReferralCode1);
          }
        //}
      }
      console.log ("rp_ReferralCode: " +  this.session.get('rp_ReferralCode'));      
    }

    addGAScript(){
      let str_appType = this.session.get('rp_AppType');
      if (str_appType == "DONOR") {
        if (!document.getElementById('rp-gagtag-id')) {
            const gtagScript = document.createElement('script');
            gtagScript.async = true;
            gtagScript.type='text/javascript';
            gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_DONOR_TRACKING_ID;
            document.head.appendChild(gtagScript);
            const gaScript = document.createElement('script');
            gaScript.setAttribute("id", "rp-gagtag-id");
            gaScript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${environment.GA_DONOR_TRACKING_ID}', { send_page_view: false });
            `;
            document.head.appendChild(gaScript);

            console.log ("addGAScript");
        } else {
            console.log ("GAScript already exists");
        }
      } else if (str_appType == "GC" ){
        // 06/25/2022 Remove Google Analytics for GC

        // if (!document.getElementById('rp-gagtag-id')) {
        //     const gtagScript = document.createElement('script');
        //     gtagScript.async = true;
        //     gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_GC_TRACKING_ID;
        //     document.head.appendChild(gtagScript);

        //     const gaScript = document.createElement('script');
        //     gaScript.setAttribute("id", "rp-gagtag-id");
        //     gaScript.innerHTML = `
        //       window.dataLayer = window.dataLayer || [];
        //       function gtag() { dataLayer.push(arguments); }
        //       gtag('js', new Date());
        //       gtag('config', '${environment.GA_GC_TRACKING_ID}', { send_page_view: false });
        //     `;
        //     document.head.appendChild(gaScript);     
            
        //     console.log ("addGAScript");
        // } else {
        //     console.log ("GAScript already exists");          
        // }
      } 
    }

    addGAdsScript(){
      let str_appType = this.session.get('rp_AppType');
      if (str_appType == "DONOR") {
        if (!document.getElementById('rp-gadsgtag-donor-id')) {    
            // 12/20/2023 Add Egg Donor Application SignUp conversion page
            const gadsScriptDonor = document.createElement('script');
            gadsScriptDonor.setAttribute("id", "rp-gadsgtag-donor-id");
            gadsScriptDonor.innerHTML = `
              function gtag_report_conversion(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                gtag('event', 'conversion', {
                    'send_to': '${environment.GA_DONOR_SINGUP_EVENT}',
                    'event_callback': callback
                });
                return false;
              }            
            `;
            document.head.appendChild(gadsScriptDonor); 

            console.log ("addGAdsScript:Egg Donor Application SignUp conversion");

        }
      } else if (str_appType == "GC" ){
        if (!document.getElementById('rp-gadsgtag-id')) {        
            const gtagScript = document.createElement('script');
            gtagScript.async = true;
            gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GADS_GC_TRACKING_ID;
            document.head.appendChild(gtagScript);

            const gadsScript = document.createElement('script');
            gadsScript.setAttribute("id", "rp-gadsgtag-id");
            gadsScript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${environment.GADS_GC_TRACKING_ID}', { send_page_view: false });
            `;
            document.head.appendChild(gadsScript);
            
            // 05/01/2024 
            // PS Surrogates Tracking Code 2024 - everleagues - New AdWords Account & Domain
            // const gadsScript05012024 = document.createElement('script');
            // gadsScript05012024.setAttribute("id", "rp-gadsgtag2-id");
            // gadsScript05012024.innerHTML = `
            //   gtag('event', 'conversion', {'send_to': '${environment.GADS_GC_QUALIFY_LEAD_CONVERSION}'});
            // `;
            // document.head.appendChild(gadsScript05012024);
 
            // 06/23/2022 Add gtag_report_conversion function
            const gadsScript1 = document.createElement('script');
            gadsScript1.setAttribute("id", "rp-gadsgtag1-id");
            gadsScript1.innerHTML = `
              function gtag_report_conversion(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                gtag('event', 'conversion', {
                    'send_to': '${environment.GADS_GC_TRACKING_NEXT_EVENT}',
                    'event_callback': callback
                });
                return false;
              }            
            `;
            document.head.appendChild(gadsScript1); 

            console.log ("addGAdsScript");
        } else {
            console.log ("GAdsScript already exists");
        }
      } 
    }

    addFacebookScript(){
      let str_appType = this.session.get('rp_AppType');
      if (str_appType == "DONOR") {
        if (!document.getElementById('rp-fbfbq-id')) {
            const fbScript = document.createElement('script');
            fbScript.setAttribute("id", "rp-fbfbq-id");
            fbScript.innerHTML = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;
              n.push=n;
              n.loaded=!0;
              n.version='2.0';
              n.queue=[];
              t=b.createElement(e);
              t.async=!0;
              t.src=v;
              s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
              fbq('init',  '${environment.FB_DONOR_TRACKING_ID}');
            `;
            document.head.appendChild(fbScript);

            const fbNonScript = document.createElement('noscript');
            fbNonScript.innerHTML = `
              <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${environment.FB_DONOR_TRACKING_ID}&ev=PageView&noscript=1"/>
            `;
            document.head.appendChild(fbNonScript);        

            console.log ("addFacebookScript");
        } else {
            console.log ("FacebookScript already exists");
        }
      } else if (str_appType == "GC") {
        if (!document.getElementById('rp-fbfbq-id')) {        
            const fbScript = document.createElement('script');
            fbScript.setAttribute("id", "rp-fbfbq-id");
            fbScript.innerHTML = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;
              n.push=n;
              n.loaded=!0;
              n.version='2.0';
              n.queue=[];
              t=b.createElement(e);
              t.async=!0;
              t.src=v;
              s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${environment.FB_GC_TRACKING_ID}');
            `;
            document.head.appendChild(fbScript);

            const fbNonScript = document.createElement('noscript');
            fbNonScript.innerHTML = `
              <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${environment.FB_GC_TRACKING_ID}&ev=PageView&noscript=1"/>
            `;
            document.head.appendChild(fbNonScript);    
            
            console.log ("addFacebookScript");
        } else {
            console.log ("FacebookScript already exists");
        }
      }
    }
}