<div class="container" style="background:#ffffff;width:98%">
<mat-dialog-content>
  <table width=96% id="authform">
  <tr><td colspan=4 align=center><h3><b>Medical Record Release Authorization Form</b></h3></td></tr>
  <tr>
    <td>Date:</td>
    <td>{{todaystr}} (Request will expired one year from this Date)</td>
  </tr>
  <tr>
    <td>Patient's Full Name:</td>
    <td>{{values.fullName}} </td>
  </tr>
  <tr>
    <td>Maiden Name/Ohter Name used:</td>
    <td>{{values.maidenName}} </td>
  </tr>
  <tr>
    <td>Date of Birth:</td>
    <td>{{values.birthDate}} </td>
  </tr>
  <br>
  <tr>
    <td colspan=2>I, {{values.fullName}}, the undersigned Patient, hereby authorize release my prenatal and delivery
      records to: <br><br>
      <span style="margin-left:80">
        <b>
        Dr. David Harari MD<br>
        3661 Valley Centre Dr. Suite 150, San Diego, CA 92130 <br>
        Telephone: (858) 436-7184 Fax:(866) 381-7729
        </b>
      </span>
    </td>
  </tr>
  <br>
  <tr>
    <td colspan=2><b><u>OBGYN</u></b><br>
      <div *ngFor='let ob of values.obgyn_list'>
        <table width=80%>
          <tr>
            <td> Name: </td> <td>{{ob.obgyn_name}}</td>
          </tr>
          <tr>
            <td> Address: </td> <td>{{ob.obgyn_address}}</td>
          </tr>
          <tr>
            <td> Telephone or Fax: </td> <td>{{ob.obgyn_phone}}</td>
          </tr>
          <tr>
            <td> Date of Treatment: </td> <td>{{ob.obgyn_date}}</td>
          </tr>
        </table>
        <br>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan=2><b><u>Pre-Natal/Delivery Doctor/Hospital</u></b><br>
      <div *ngFor='let ob of values.hospital_list'>
        <table width=80%>
          <tr>
            <td> Name: </td> <td>{{ob.dr_name}}</td>
          </tr>
          <tr>
            <td> Hospital: </td> <td>{{ob.hospital}}</td>
          </tr>
           <tr>
            <td> Date of Treatment: </td> <td>{{ob.hospital_date}}</td>
           </tr>
        </table>
        <br>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan=2>
        I understand that my medical record may also include information regarding my diagnosis/treatment related
        to psychiatric or psychological conditions, drug and or alcohol abuse, AIDS and/or HIV status, pregnacy 
        converns and conditions and delivery. I understand and agree that the information, if any, pertaining to 
        any such diagnosis/treatment described above may be released.

        I understand that the information used or disclosed may be subject to re-disclosure by the person or
        class of persons or facility receiving it and would then no long protected by federal privacy regulations.<br>
      
        <br>
        <b>By signing below, I acknowledge that I have read and understand this authorization.</b>
    </td>
  </tr>
  <br>
  <tr>
    <td colspan=2>
      Patient's Signature:
    </td>
  </tr>
</table>
<img [src]='signatureImg' #sigimgref> <br>
<table id="authdate">
  <tr>
    <td>Date:</td> <td>{{todaystr}}</td>
  </tr>
</table>
<div mat-dialog-actions class="button-row" align=center>
  <button mat-raised-button color="warn" class="close_button" type="button"  (click)='generatePDF()'>PDF <mat-icon>picture_as_pdf</mat-icon></button>
  <button mat-raised-button color="primary" class="close_button" type="button"  (click)='savePDF()'>SAVE <mat-icon>save</mat-icon></button>
</div>
</mat-dialog-content>
</div>


