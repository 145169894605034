import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '@app/services/dataservice.service';

@Component({
  selector: 'app-uploadvideopopup',
  templateUrl: './uploadvideopopup.component.html',
  styleUrls: ['./uploadvideopopup.component.scss']
})
export class UploadvideopopupComponent implements OnInit {
  files: any = [];
  progressInfo ={};
  videolist: File;
  uploadedFileName: string;
  showProcessSpinner = false;
  isLinear: any;
  documentTitle = null;
  uploading=false;
  completestatus=false;
  completefiles=[];
  uploadMessage="";
  complete=false;
  bufferValue;
  determinate;
  //image_db_value:any;
  //historyref: HistoryComponent;
 // archiveComponentRef: ArchiveComponent;
 @ViewChild('fileInput') fileInput: ElementRef;
  constructor(private dataservice:DataService,
    public dialogRef: MatDialogRef<UploadvideopopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    console.log(this.data);
  }

  closeme() {
    this.dialogRef.close();
  }

  uploadInputFile(event) {
    if(this.files.length>0) return;
    console.log('upload input file');
    console.log(event);
    this.videolist = event;
    for (let index = 0; index < event.length && index<1; index++) {
      const element = event[index];
      if(this.checkFileSize(element)){
        if(!this.checkDuplicateFile(element) ){
          this.files.push(element);
        this.uploadedFileName = element.name;
        if (index === 0) {
            this.documentTitle = element.name;
          }
        }
      }
    }
  }

  dragAndDropFile(event) {
    if(this.files.length>0) return;
    console.log('drag and drop file');
    console.log(event);
    this.videolist = event;
    for (let index = 0; index < event.length && index<1; index++) {
      const element = event[index];
      if(this.checkFileSize(element)){
        if(!this.checkDuplicateFile(element) ){
          this.files.push(element);
        this.uploadedFileName = element.name;
        if (index === 0) {
            this.documentTitle = element.name;
          }
        }
      }
    }
  }

  checkFileSize(ff:any){
    const sizelimit=20*1024*1024;
    if(ff.size>sizelimit){
      const sz=Math.round(ff.size/1024/1024*100)/100;
      alert(ff.name+" file size: " +sz +"MB exceeds the file size limit of 20 MB.");
      return false;
    }
    return true;
  }
  checkDuplicateFile(ff:any){
    console.log(ff);
    let matched=false;
    this.files.forEach(fe=>{
      console.log("1--->");
      console.log(fe);
      if((fe.name==ff.name) && fe.size==ff.size){
        matched=true;
      }
    });
    console.log(matched);
    return matched;
  }
  deleteAttachment(index) {
    console.log('delete attachment');
    console.log(index);
    this.files.splice(index, 1);
    this.videolist = this.files;
    this.documentTitle = null;
    console.log(this.videolist);
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        if (i === 0) {
          this.documentTitle = this.files[i].name;
        }
      }
    }
  }
  uploadCheck(fn){
    this.completefiles.push(fn);
    if(this.completefiles.length==this.files.length){
      console.log("upload complete");
      this.completestatus=true;
      //this.dialogRef.close(res);   
    }
  }
  uploadVideo(){
    if(this.data.type=="ED"){
      this.uploadEDVideo();
    } else{
      console.log("not supported");
    }
  }
  uploadEDVideo(){
    this.uploading= !this.uploading;
    this.completefiles=[];
    this.uploadMessage="uploading....";
    this.dataservice.uploadPresceeningDonorVideo(this.data.casedata.caseId,this.data.casedata.emailId,this.files).subscribe(ev=>{
      if (ev.type === HttpEventType.UploadProgress) {
        let percentage= Math.round(100 * ev.loaded / ev.total);
        console.log(percentage);
        console.log(ev);
        this.files.forEach(videofile=>{ 
          this.progressInfo[videofile.name]=percentage;
        });
       } else if (ev instanceof HttpResponse) {
        let res:any=ev.body;
        console.log(res);
        this.files.forEach(videofile=>{
          this.uploadCheck(videofile.name);
          this.uploadMessage="Saving Complete ...."+videofile.name;
        });
        this.dialogRef.close(res);
       }
      });
    
  }
 
}
