import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class OrgGuard implements CanActivate {
    constructor(
        private router: Router,
        private service: AuthService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const orgName=route.paramMap.get("orgName");
        // first check if orgid match current old org id
        const curorg=this.service.orgValue;
        if(curorg){
            if(curorg.tenantName==orgName){
                console.log("match local cache");
                return true;
            } 
        } 
            console.log("query through api");
            const orgObj:any = await this.service.queryOrg(orgName).toPromise();
            console.log(orgObj);
            if(orgObj.status=="SUCCESS" && orgObj.data)
            {
                return true;
            } else{
                this.router.navigateByUrl("/");
                return false;
            }
        
       
    }
}