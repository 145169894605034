<div class="container" style="width: 100%; height: 100%;">
  <div *ngIf="(!acknowledge) && (casedata.status=='form1-approved' || casedata.status=='empty')">
    <mat-dialog-content style="padding: 0px;">
      <form [formGroup]="form">
        <table width=100% height=100%>
          <div class="card-top">
            <tr>
              <td align=center><h3 style="width: 80%; text-align: left;">{{"GC.APPROVAL.TITLE" | translate}}</h3></td>
            </tr>
            <tr>
              <!-- <td align="center">
                <p style="width: 80%; text-align: left; font-weight: 500;">{{"GC.APPROVAL.CONGRATULATIONS" | translate}}{{"GC.APPROVAL.QUESTIONS" | translate}} <a href="https://physicianssurrogacy.com/" target="_blank">www.PhysiciansSurrogacy.com</a>. <br>{{"GC.APPROVAL.QUESTIONS_2" | translate}} <u>858-951-1868</u>, <br>{{"GC.APPROVAL.QUESTIONS_3" | translate}} <u>Intake@physiciansurrogacy.com</u></p>
              </td> -->
              <td align="center">
                <p style="width: 80%; text-align: left; font-weight: 500;">{{"GC.APPROVAL.CONGRATULATIONS" | translate}}<br>{{"GC.APPROVAL.QUESTIONS" | translate}}<font color=#8a61a8><b>858-465-5423</b></font>{{"GC.APPROVAL.QUESTIONS_2" | translate}}</p>
              </td>
            </tr>
          </div>
          <div class="card-bottom">
            <tr>
              <td align="center">
                <p style="width: 80%; text-align: left;"><b>{{"GC.APPROVAL.CONFIRM_BELOW" | translate}}</b></p>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="email" maxlength="50" placeholder="Enter email address" required [disabled]='true'>
                  <mat-error *ngIf="form.controls['email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td align="center">
                <button class="email-button" mat-raised-button color="primary" type="submit" [disabled]="(!form.valid)" (click)='sendInvite()'>{{ "GENERAL.SEND_EMAIL" | translate }} <mat-icon>email</mat-icon></button>
              </td>
            </tr>
          </div>
        </table>
      </form>
    </mat-dialog-content>
  </div>
  <div class="postSend2" *ngIf="acknowledge">
      <!-- <button mat-raised-button class="start_button" color="warn" type="submit" [disabled]="((inviteurl=='') || (inviteurl==null))" (click)='continueForm2()'><mat-icon>account_box</mat-icon>&nbsp;{{ "GENERAL.START_APPLICATION" | translate }}</button> -->
      <!-- <button mat-raised-button class="start_button" type="submit" [disabled]="((inviteurl=='') || (inviteurl==null))" (click)='continueForm2()'><mat-icon>account_box</mat-icon>&nbsp;{{ "GENERAL.START_APPLICATION" | translate }}</button>
        <br><br> -->
        <!-- <br><br>
        {{ "GENERAL.RECEIVE_EMAIL_1" | translate }}<font color=blue> service@everleagues.com</font>  {{ "GENERAL.RECEIVE_EMAIL_2" | translate }}
        <br><br>
        <font color=red>{{ "GENERAL.CHECK_SPAM" | translate }}</font>  
        <br><br> -->
        <div style="font-size: 17px; text-align: center;">
          <font color=#8a61a8><b>{{ "GC.APPROVAL.CONFIRM" | translate }}</b></font>
          <br>
          {{ "GC.APPROVAL.CONTACT" | translate }}<font color=#8a61a8><b>858-465-5423</b></font>{{ "GC.APPROVAL.EMAIL" | translate }}<font color=#8a61a8><b>Intake@physicianssurrogacy.com</b></font>
        </div>
        <!-- <div mat-dialog-actions class="button-row" align=center>
          <button mat-raised-button class="close_button" color="primary" type="button" (click)="onClose()">{{ "GENERAL.CLOSE" | translate }} <mat-icon>cancel</mat-icon></button>
        </div> -->
  </div>
  <!-- <div class="postSend" *ngIf="(!acknowledge) &&(casedata.status=='partial-denied' && (!final))">
    <mat-dialog-content style="font-size: 17px;">
      <div align=center>
        <span *ngIf='reasons'>
          <span style="font-size: 19px; color: red;"><b>{{"GC.DENY.CONFIRMATION.TITLE" | translate }}</b></span>
        </span>
      </div> -->
      <!-- <br>
      <ul *ngIf='reasons'>
        <li *ngFor='let reason of reasons'>{{reason.questionId}}</li>
      </ul> -->
      <!-- <br>
      <div align=center>{{"GC.DENY.CONFIRMATION.BODY" | translate }}<p></p><p *ngFor='let reason of reasons'>{{reason.questionId}} </p></div>
    </mat-dialog-content>
    <br>
    <div mat-dialog-actions class="button-row" align=center>
      <button mat-raised-button class="close_button" color="warn" type="button" (click)="onReview()">{{"GC.DENY.CONFIRMATION.REVIEW" | translate }}<mat-icon>preview</mat-icon></button>
      <button mat-raised-button class="close_button" color="primary" type="button" (click)="finalizedeny()">{{"GC.DENY.CONFIRMATION.SUBMIT" | translate }}<mat-icon>task_alt</mat-icon></button>
    </div>
  </div> -->
  <div class="postSend" *ngIf="(!acknowledge) &&((casedata.status=='partial-denied' || casedata.status=='denied') && final)">
    <div class="deny-popup">
      <mat-dialog-content>
        {{ "GENERAL.DEAR" | translate }} <font color=#8a61a8><b>{{firstName}} {{lastName}}</b></font>,
        <br><br>
        {{ "GC.DENY.INTRO" | translate }}
        <br><br>
        {{ "GC.DENY.INTEREST" | translate }} {{ "GC.DENY.APPRECIATE" | translate }}
        <br><br>
        {{ "GC.DENY.SIGNATURE" | translate }}
        <br><br>
        <ul>
          <li>Age</li>
          <li>Body Mass Index</li>
          <li>Smoking, drugs, or taking antidepressants</li>
          <li>Medical concerns due to complications in pregnancy</li>
          <li>State in which you reside is not surrogacy law friendly</li>
        </ul>
        If you have any further questions, please contact our team at <font color=#8a61a8><b>858-952-0019</b></font>
        <br>
        <div style="text-align: center;">
          <img width="60%" src="../../../assets/images/ps.png">
        </div>
      </mat-dialog-content>
    </div>
    <!-- <div mat-dialog-actions class="button-row" align=center>
      <button mat-raised-button class="close_button" color="primary" type="button" (click)="onClose()">{{ "GENERAL.CLOSE" | translate }} <mat-icon>cancel</mat-icon></button>
    </div> -->
  </div>
  <div class="postSend" *ngIf="(!acknowledge) &&(casedata.status=='FAIL')">
    <div class="deny-popup">
      <mat-dialog-content>
        {{casedata.message}}
        <br>
        <!-- If you have any further questions, please contact our team at <font color=#8a61a8><b>858-465-5423</b></font>
        <br> -->
        <div style="text-align: center;">
          <img width="75%" src="../../../assets/images/ps.png">
        </div>
      </mat-dialog-content>
      <!-- <button mat-raised-button class="close_button" color="primary" type="button" (click)="closeMe()">Close<mat-icon>close</mat-icon></button> -->
    </div>
    <!-- <div mat-dialog-actions class="button-row" align=center>
      <button mat-raised-button class="close_button" color="warn" type="button" (click)="onClose()">{{ "GENERAL.CLOSE" | translate }} <mat-icon>cancel</mat-icon></button>
    </div> -->
  </div>
</div>
