<table width=98%>
  <tr>
    <td width=68%>
      <div *ngIf="galleryready&&photoimages.length>0">
        <!-- <ks-carousel [id]="100" [images]="photoimages"
        [carouselConfig]="{objectFit:'contain'}"
        [previewConfig]="{visible: true,size: {width: 'auto', height: '36px'}}"
        >
        </ks-carousel> -->
        <!-- <ks-carousel [id]="116" [images]="photoimages"
          [carouselConfig]="{maxWidth: '500px', maxHeight: '500px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}" -->
        <ks-carousel [id]="116" [images]="photoimages" (show)="onChangeImage($event)"
          [carouselConfig]="{maxWidth: '600px', maxHeight: '600px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
          [previewConfig]="{visible: true, number: 14, width: 'auto', maxHeight: '40px'}">
        </ks-carousel>
      </div>
    </td>
    <td valign=top>
      <!-- <table width=99%> -->
      <table style="height: 450px; width: 325px; display: table-caption; overflow-y: auto;">
        <!-- <table style="height: 450px; width: 300px; display: table-caption; overflow-y: auto;">
          <tr *ngFor="let img of photodata.images">
            <td width=20></td>
            <td><font color=green size=-1>{{img.seqNo}}</font></td>
            <td><font *ngIf="!selectPhoto" color=green size=-1>{{img.fileName}}</font></td>
            <td><font *ngIf="selectPhoto" color=green style="background-color: lightgray;" size=-1>{{img.fileName}}</font></td>
            <td>
              <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
            </td>
          </tr>
        </table> -->
        <tr *ngFor="let img of photodata.images;let ii=index">
          <td width=20></td>
          <td *ngIf="ii==currentIndex"><font color=red>[ {{img.seqNo}} ]</font></td>
          <td *ngIf="ii!=currentIndex"><font color=green size=-1>{{img.seqNo}}</font></td>
          <td><font color=green size=-1>{{img.fileName}}</font></td>
          <td>
            <button mat-icon-button color="primary" (click)="deletePhoto(img)"><mat-icon>delete</mat-icon></button>
          </td>
        </tr>
    
        
      </table>
        <tr>
          <td colspan=2><br><button mat-raised-button color="primary" [disabled]='!galleryready' (click)="addPhoto()">Add Photo</button></td>
          <td colspan=2><br><button mat-raised-button color="warn" (click)="closeMe()">Close</button></td>
        </tr>
    </td>
  </tr>
</table>

<div *ngIf="galleryready&&photoimages.length==0">
  [No Photo]
</div>
<div *ngIf="!galleryready">
  <mat-spinner  diameter='20'></mat-spinner>
  <span> Loading Images....</span> 
</div>