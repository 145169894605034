<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
    <div class="navbar-nav">
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
        <span flex></span>
        <div class="nav-item nav-link" align="end" ><font color=green>{{user.firstName}} {{user.lastName}}</font> @ {{organization.tenantName}}</div>
    </div>
</nav>

<!-- main app container -->
<!--div class="app-container" [ngClass]="{ 'bg-light': user }">
    <alert></alert>
    <router-outlet></router-outlet>
</div -->


    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-10 offset-sm-1"> -->
            <div class="col-sm-10" style="margin: auto !important">
                <alert></alert>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
