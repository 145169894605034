import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AuthGuard, OrgGuard } from './core/guard';
import { LoginComponent } from './account/login.component';
import { RegisterComponent } from './account/register.component';
import { EggdonationComponent } from './eggdonation/form1/eggdonation.component';
import { GestationalcarrierComponent } from './gestationalcarrier/form1/gestationalcarrier.component';
import { EDApprovalViewComponent } from './eggdonation/edapprovalview/approvalview.component';
import { GCApprovalViewComponent } from './gestationalcarrier/approvalview/gcapprovalview.component';




const routes: Routes = [
  //  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: ':orgName/login',component:LoginComponent ,canActivate:[OrgGuard] },
    { path: ':orgName/register',component:RegisterComponent ,canActivate:[OrgGuard] },
    { path: ':orgName/donor',component:EggdonationComponent ,canActivate:[OrgGuard]},
    { path: ':orgName/edapproval',component:EDApprovalViewComponent ,canActivate:[OrgGuard]},
    { path: ':orgName/gcapproval',component:GCApprovalViewComponent ,canActivate:[OrgGuard]},
    { path: ':orgName/gc',component:GestationalcarrierComponent ,canActivate:[OrgGuard] },
    { path: '', redirectTo: '/lucina/donor', pathMatch: 'full'  },
    { path: 'welcome', component: HomeComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }