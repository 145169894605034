<br>
<div class="container" style="background:#ffffff;width:100%" >
  <br><br>
  <!-- <div class="logo_container">
    <img class="logo_top" src="../../../assets/images/lucina.png">
  </div> -->
  <br><br>
  <h1><b>Pre-Qualification</b></h1>
<br>
<table width=98%>
  <tr>
    <!-- <td><h1><b>{{organization.tenantName.toUpperCase()}} Egg Donation Pre-Qualification</b></h1> -->
    <td width=20% valign=center>
      <div *ngIf="form_progress.formstatus=='prescan-completed'">
        <font color='#6666ff'> <b>{{"GENERAL.FORM_SUBMITTED" | translate}}</b></font>
      </div>
    </td>
    <td valign=top align=center width=100>

      <div class="twitter-like" *ngIf='false'>
        <mat-spinner class="gcbackground" 
            mode="determinate" 
          value="100" diameter="32" strokeWidth="6" >
        </mat-spinner>
        <mat-spinner class="gcprogress" diameter="32"  mode="determinate" strokeWidth="6"  
        value={{form_progress.percent}}></mat-spinner>
        </div>
  </td>
  <td width=120 valign=top  *ngIf='false'>
    <font color=green> <b>
      {{form_progress.percent}}%
    </b> required fields completed</font>
  </td>
  <td width=30></td>
  </tr>
</table>

<span class="language-select" [matMenuTriggerFor]="languageSelect">{{ "SELECT_LANGUAGE" | translate }}: <span class="current_langauge">{{languageDisplay}}</span></span>
<mat-menu #languageSelect="matMenu" class="mat-menu">
  <button type="button" mat-menu-item (click)="changeLanguage('en')"
    [ngStyle]="{'font-weight' : language === 'en' ? '900' : '400' }">{{ "ENGLISH" | translate }}</button>
  <button type="button" mat-menu-item (click)="changeLanguage('zh-cn')"
    [ngStyle]="{'font-weight' : language === 'zh-cn' ? '900' : '400' }">{{ "SIMPLIFIED_CHINESE" | translate
    }}</button>
  <button type="button" mat-menu-item (click)="changeLanguage('zh-tw')"
    [ngStyle]="{'font-weight' : language === 'zh-tw' ? '900' : '400' }">{{ "TRADITIONAL_CHINESE" | translate
    }}</button>
  <!-- <button type="button" mat-menu-item (click)="changeLanguage('es')"
    [ngStyle]="{'font-weight' : language === 'es' ? '900' : '400' }">{{ "SPANISH" | translate }}</button>
  <button type="button" mat-menu-item (click)="changeLanguage('it')"
    [ngStyle]="{'font-weight' : language === 'it' ? '900' : '400' }">{{ "ITALIAN" | translate }}</button>
  <button type="button" mat-menu-item (click)="changeLanguage('ja')"
    [ngStyle]="{'font-weight' : language === 'ja' ? '900' : '400' }">{{ "JAPANESE" | translate }}</button> -->
</mat-menu>

<span *ngIf="lastsaveTS"><font size=-1 color=green><i>last saved at {{lastsaveTS}}</i></font></span>
<table>
    <!-- <td><tr><mat-icon style="display: inline-flex; vertical-align: top;">information</mat-icon></tr></td> -->
    <!-- <td><tr><p>Egg donation involves a screening process. Not all potential egg donors are selected. Not all selected egg donors receive the monetary amounts or compensation advertised. As with any medical procedure, there may be risks associated with human egg donation. Before an egg donor agrees to begin the egg donation process, and signs a legally binding contract, she is required to receive specific information on the known risks of egg donation. Consultation with your doctor prior to entering into a donor contract is advised.</p></tr></td> -->
</table>
<br>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div style="text-align: left" *ngIf="first">
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
      <div fxFlex="50">
        <div *ngIf="showReturnButton">
          <button style="background-color: #8961a7 !important;" onclick="style.display = 'none'" mat-raised-button class="next_button" type="button" (click)="returnUser()">Returning user?</button>
        </div>
        <div *ngIf="returnuser">
          <mat-label><b>Enter your email: </b></mat-label><br>
          <mat-form-field style="margin-left: 0px;" appearance="outline">
            <input matInput formControlName="returning_user" maxlength="50" placeholder="pat@example.com" (keydown.enter)="checkCase()">
            <button type="button" mat-icon-button matSuffix (click)="checkCase()">
              <mat-icon style="color: #8a61a8; margin-top: 15px;">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="50" class="phone" *ngIf="appload">
        <div class="digit_box">
          <mat-label><b>Please enter the last 4 digits of your phone number: </b></mat-label><br>
          <mat-form-field style="margin-left: 0px;" appearance="outline">
            <input matInput formControlName="enter_digits" maxlength="50" (keydown.enter)="fetchCase()">
            <button type="button" mat-icon-button matSuffix (click)="fetchCase()">
              <mat-icon style="color: #8a61a8; margin-top: 15px;">arrow_forward_ios</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
      <div fxFlex>
        <div *ngIf="showCheckCaseMessage" class="message">
          <span *ngIf="checkCaseStatus=='SUCCESS'" style="color: green;"><b>{{checkCaseMessage}}</b></span>
          <span *ngIf="checkCaseStatus=='FAIL'" style="color: red;"><b>{{checkCaseMessage}}</b></span>
        </div>
        <div *ngIf="showFetchMessage" class="message">
          <span style="color: red;"><b>{{fetchMessage}}</b></span>
        </div>
      </div>
    </div>
  </div>
  <br>
  <table style="display: inline-block; margin: auto 0 !important; width:100% !important;">
    <div class="button-row" *ngIf="!first">
      <!-- <button mat-raised-button class="bottom_buttons" color="warn" type="save_progress" (click)='saveData()' [disabled]="form_progress.formstatus=='prescan-completed'">SAVE PROGRESS <mat-icon>save</mat-icon></button> -->
      <button mat-raised-button class="back_button" type="button" color="warn" (click)="backButton()"><mat-icon>arrow_back_ios</mat-icon>{{ "GENERAL.BACK" | translate }}</button>
    </div>
    <div *ngIf="first">
    <tr>
      <td>
        <mat-label><b>1. {{ "GENERAL.FIRST_NAME" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput id="firstName_field" formControlName="firstName" maxlength="50" required>
          <mat-error *ngIf="form.controls.firstName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['firstName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-label><b>2. {{ "GENERAL.LAST_NAME" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="lastName" maxlength="50" required>
          <mat-error *ngIf="form.controls.lastName.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['lastName'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-label><b>3. {{ "GENERAL.EMAIL" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput id="email_field" formControlName="email" maxlength="50" placeholder="pat@example.com" (change)="checkEmailMatch()" required>
          <mat-error *ngIf="form.controls.email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
          <!-- <mat-error *ngIf="emailMatch">hi</mat-error> -->
        </mat-form-field>
      </td>
      <td>
        <mat-label><b>4. {{ "GENERAL.EMAIL_CONFIRM" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="confirm_email" maxlength="50"  (change)="checkEmailMatch()" required>
          <mat-error *ngIf="form.controls.confirm_email.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="confirmEmailError" >{{ "GENERAL.EMAIL_ERROR_MATCH" | translate }}</mat-error>
        </mat-form-field>
        
      </td>
    </tr>
    <tr>
      <td>
        <mat-label><b>5. {{ "GENERAL.COUNTRY" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="country" [(ngModel)]="selectedCountry" required>
            <mat-radio-button (click)="foreignPhone=''" class="add-right-padding" value="USA" (change)="this.foreignValidator()">{{ "GENERAL.USA" | translate }}</mat-radio-button>
            <mat-radio-button (click)="usPhone=''" class="add-right-padding" value="Outside of USA" (change)="this.foreignValidator()">{{ "GENERAL.USA_OUTSIDE" | translate }}</mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>
    <br>
    <tr *ngIf="selectedCountry=='USA'">
      <mat-label><b>6. {{ "GENERAL.PHONE_CELL" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <input matInput id="telephone_number_field" mask="(000) 000-0000"  placeholder="Ex. (000) 000-0000" formControlName="telephone_number" maxlength="50">
        <mat-error *ngIf="form.controls.telephone_number.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error *ngIf="form.controls['telephone_number'].invalid">{{ "GENERAL.PHONE_INVALID" | translate }}</mat-error>
      </mat-form-field>
    </tr>
    <tr *ngIf="selectedCountry=='Outside of USA'">
      <mat-label><b>6. {{ "GENERAL.PHONE_CELL" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <input matInput id="telephone_number_field" formControlName="foreign_telephone_number" maxlength="50">
        <mat-error *ngIf="form.controls.foreign_telephone_number.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error *ngIf="form.controls['foreign_telephone_number'].invalid">{{ "GENERAL.PHONE_INVALID" | translate }}</mat-error>
      </mat-form-field>
    </tr>
    <tr>
      <td>
      <mat-label><b>7. {{ "GENERAL.DATE_OF_BIRTH" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <input formControlName="birthDate"  id="birth_date_field" placeholder="mm/dd/yyyy" matInput [matDatepicker]="picker" [min]="minDate" [max]="today" required>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="form.controls['birthDate'].invalid">{{ "GENERAL.INVALID_DATE" | translate }}</mat-error>
        <!-- <mat-error *ngIf="form.controls['birthDate'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error> -->
      </mat-form-field>
      </td>
      <td style="display: none">
      <mat-label><b>8. Which program are you interested in? </b></mat-label>
      <!-- <font color=red>*</font><br> -->
      <mat-form-field appearance="outline">
        <mat-select formControlName="program">
          <mat-option value="Traditional Egg Donation Program">Traditional Egg Donation Program</mat-option>
          <!-- <mat-option value="Egg-sharing Program">Egg-sharing Program</mat-option>
          <mat-option value="Undecided">Undecided</mat-option> -->
        </mat-select>
        <mat-error *ngIf="form.controls['program'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
     </td>
      <!-- <td>
        <mat-label><b>8. Country of birth </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-label>Country of birth</mat-label>
          <input matInput formControlName="country_birth" maxlength="500" required>
          <mat-error *ngIf="form.controls['country_birth'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td> -->
    </tr>
  </div>
  <div *ngIf="!first">
    <tr>
      <td *ngIf="selectedCountry=='USA'">
        <mat-label><b>9. {{ "GENERAL.ADDRESS" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="streetAdd" maxlength="5000">
          <mat-error *ngIf="form.controls.streetAdd.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['streetAdd'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
      <td *ngIf="selectedCountry=='USA'">
        <mat-label><b>{{ "GENERAL.ADDRESS_2" | translate }} </b></mat-label><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="address_2" maxlength="500" >
          <mat-error *ngIf="form.controls.address_2.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td *ngIf="selectedCountry=='USA'">
      <mat-label><b>{{ "GENERAL.CITY" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="city" maxlength="500">
        <mat-error *ngIf="form.controls.city.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error *ngIf="form.controls['city'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
      </td>
      <td *ngIf="selectedCountry=='USA'">
        <mat-label><b>{{ "GENERAL.STATE" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="state">
            <mat-option value="Alabama">Alabama</mat-option>
            <mat-option value="Alaska">Alaska</mat-option>
            <mat-option value="Arizona">Arizona</mat-option>
            <mat-option value="Arkansas">Arkansas</mat-option>
            <mat-option value="California">California</mat-option>
            <mat-option value="Colorado">Colorado</mat-option>
            <mat-option value="Connecticut">Connecticut</mat-option>
            <mat-option value="Delaware">Delaware</mat-option>
            <mat-option value="Florida">Florida</mat-option>
            <mat-option value="Georgia">Georgia</mat-option>
            <mat-option value="Hawaii">Hawaii</mat-option>
            <mat-option value="Idaho">Idaho</mat-option>
            <mat-option value="Illinois">Illinois</mat-option>
            <mat-option value="Indiana">Indiana</mat-option>
            <mat-option value="Iowa">Iowa</mat-option>
            <mat-option value="Kansas">Kansas</mat-option>
            <mat-option value="Kentucky">Kentucky</mat-option>
            <mat-option value="Louisiana">Louisiana</mat-option>
            <mat-option value="Maine">Maine</mat-option>
            <mat-option value="Maryland">Maryland</mat-option>
            <mat-option value="Massachusetts">Massachusetts</mat-option>
            <mat-option value="Michigan">Michigan</mat-option>
            <mat-option value="Minnesota">Minnesota</mat-option>
            <mat-option value="Mississippi">Mississippi</mat-option>
            <mat-option value="Missouri">Missouri</mat-option>
            <mat-option value="Montana">Montana</mat-option>
            <mat-option value="Nebraska">Nebraska</mat-option>
            <mat-option value="Nevada">Nevada</mat-option>
            <mat-option value="New Hampshire">New Hampshire</mat-option>
            <mat-option value="New Jersey">New Jersey</mat-option>
            <mat-option value="New Mexico">New Mexico</mat-option>
            <mat-option value="New York">New York</mat-option>
            <mat-option value="North Carolina">North Carolina</mat-option>
            <mat-option value="North Dakota">North Dakota</mat-option>
            <mat-option value="Ohio">Ohio</mat-option>
            <mat-option value="Oklahoma">Oklahoma</mat-option>
            <mat-option value="Oregon">Oregon</mat-option>
            <mat-option value="Pennsylvania">Pennsylvania</mat-option>
            <mat-option value="Rhode Island">Rhode Island</mat-option>
            <mat-option value="South Carolina">South Carolina</mat-option>
            <mat-option value="South Dakota">South Dakota</mat-option>
            <mat-option value="Tennessee">Tennessee</mat-option>
            <mat-option value="Texas">Texas</mat-option>
            <mat-option value="Utah">Utah</mat-option>
            <mat-option value="Vermont">Vermont</mat-option>
            <mat-option value="Virginia">Virginia</mat-option>
            <mat-option value="Washington">Washington</mat-option>
            <mat-option value="West Virginia">West Virginia</mat-option>
            <mat-option value="Wisconsin">Wisconsin</mat-option>
            <mat-option value="Wyoming">Wyoming</mat-option>
            <mat-option value="N/A">N/A</mat-option>
            <mat-option value="Overseas, please specify">{{ "ED.OVERSEAS" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td *ngIf="selectedCountry=='USA'">
        <mat-label><b>{{ "GENERAL.ZIP" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="zip_code" maxlength="50">
          <mat-error *ngIf="form.controls.zip_code.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['zip_code'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td *ngIf="selectedCountry=='Outside of USA'">
        <mat-label><b>9. {{ "GENERAL.ADDRESS" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="foreignAddress" maxlength="5000"></textarea>
          <mat-error *ngIf="form.controls.foreignAddress.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
          <mat-error *ngIf="form.controls['foreignAddress'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
          <!-- <mat-error *ngIf="form.controls['foreignAddress'].invalid">{{ "GENERAL.REQUIRED" | translate }}</mat-error> -->
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
          <mat-label><b>10. {{ "GENERAL.HEIGHT" | translate }} </b></mat-label><font color=red>*</font><br>
          <mat-form-field class="height" appearance="outline">
            <input matInput name="htf" type="number" min="1" maxlength="50" formControlName="height_feet" required>
            <span matSuffix> {{ "GENERAL.FEET" | translate }}</span>
            <!-- <mat-error *ngIf="form.controls['height_feet'].errors?.pattern">{{ "GENERAL.INVALID_VALUE" | translate }}</mat-error> -->
            <mat-error *ngIf="form.controls.height_feet.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error *ngIf="form.controls['height_feet'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
            <mat-error *ngIf="form.controls.height_feet.hasError('min') || form.controls.height_feet.hasError('pattern')">Enter a positive number</mat-error>
          </mat-form-field>
      <!-- </td>
      <td> -->
          <mat-form-field class="height" appearance="outline">
            <input matInput name="hti" type="number" min="1" maxlength="50" formControlName="height_in" required>
            <span matSuffix> {{ "GENERAL.INCHES" | translate }}</span>
            <mat-error *ngIf="form.controls.height_in.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
            <mat-error *ngIf="form.controls['height_in'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
            <mat-error *ngIf="form.controls.height_in.hasError('min')">Enter a positive number</mat-error>
            <mat-error *ngIf="form.controls.height_in.hasError('max')">Enter a number between 0 and 12</mat-error>
          </mat-form-field>
      </td>
      <td>
      <mat-label><b>11. {{ "GENERAL.WEIGHT" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field class="weight" appearance="outline">
        <input matInput name="wt" type="number" formControlName="weight" maxlength="50" required>
        <span matSuffix> {{ "GENERAL.POUNDS" | translate }}</span>
        <mat-error *ngIf="form.controls.weight.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        <mat-error *ngIf="form.controls['weight'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        <mat-error *ngIf="form.controls.weight.hasError('min')">Enter a positive number</mat-error>
      </mat-form-field>
      <mat-form-field class="weight" appearance="outline">
        <mat-label>{{Bmi()}}</mat-label>
        <input matInput type="number" readonly>
        <span *ngIf="language != 'zh-cn' && language != 'zh-tw'" matSuffix>{{ "GENERAL.BMI" | translate }}</span>
        <span style="font-size: 14px;" *ngIf="language == 'zh-cn' || language == 'zh-tw'" matSuffix>{{ "GENERAL.BMI" | translate }}</span>
      </mat-form-field>
    </td>
    </tr>
    <tr>
      <td>
        <mat-label><b>12. {{ "ED.EYE_COLOR.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="eye_color" required>
            <mat-option value="Blue">{{ "ED.EYE_COLOR.BLUE" | translate }}</mat-option>
            <mat-option value="Light Blue">{{ "ED.EYE_COLOR.BLUE_LIGHT" | translate }}</mat-option>
            <mat-option value="Dark Blue">{{ "ED.EYE_COLOR.BLUE_DARK" | translate }}</mat-option>
            <mat-option value="Brown">{{ "ED.EYE_COLOR.BROWN" | translate }}</mat-option>
            <mat-option value="Light Brown">{{ "ED.EYE_COLOR.BROWN_LIGHT" | translate }}</mat-option>
            <mat-option value="Dark Brown">{{ "ED.EYE_COLOR.BROWN_DARK" | translate }}</mat-option>
            <mat-option value="Green">{{ "ED.EYE_COLOR.GREEN" | translate }}</mat-option>
            <mat-option value="Hazel">{{ "ED.EYE_COLOR.HAZEL" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['eye_color'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-label><b>13. {{ "ED.HAIR_COLOR.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="hair_color" required>
            <mat-option value="Black">{{ "ED.HAIR_COLOR.BLACK" | translate }}</mat-option>
            <mat-option value="Blonde">{{ "ED.HAIR_COLOR.BLONDE" | translate }}</mat-option>
            <mat-option value="Light Blonde">{{ "ED.HAIR_COLOR.BLONDE_LIGHT" | translate }}</mat-option>
            <mat-option value="Dark Blonde">{{ "ED.HAIR_COLOR.BLONDE_DARK" | translate }}</mat-option>
            <mat-option value="Strawberry Blonde">{{ "ED.HAIR_COLOR.BLONDE_STRAWBERRY" | translate }}</mat-option>
            <mat-option value="Brown">{{ "ED.HAIR_COLOR.BROWN" | translate }}</mat-option>
            <mat-option value="Light Brown">{{ "ED.HAIR_COLOR.BROWN_LIGHT" | translate }}</mat-option>
            <mat-option value="Dark Brown">{{ "ED.HAIR_COLOR.BROWN_DARK" | translate }}</mat-option>
            <mat-option value="Red">{{ "ED.HAIR_COLOR.RED" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['hair_color'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
      <mat-label><b>14. {{ "ED.BLOOD_TYPE.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="bloodType" required>
          <mat-option value="A">{{ "ED.BLOOD_TYPE.A" | translate }}</mat-option>
          <mat-option value="B">{{ "ED.BLOOD_TYPE.B" | translate }}</mat-option>
          <mat-option value="AB">{{ "ED.BLOOD_TYPE.AB" | translate }}</mat-option>
          <mat-option value="O">{{ "ED.BLOOD_TYPE.O" | translate }}</mat-option>
          <mat-option value="Unknown">{{ "ED.BLOOD_TYPE.UNKNOWN" | translate }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['bloodType'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
    </td>
      <td>
        <mat-label><b>15. {{ "ED.EDUCATION_LEVEL.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
        <mat-form-field appearance="outline">
          <mat-select formControlName="highest_level_education" required>
            <mat-option value="High school not completed">{{ "ED.EDUCATION_LEVEL.HIGHSCHOOL_NOT_COMPLETED" | translate }}</mat-option>
            <mat-option value="High school completed">{{ "ED.EDUCATION_LEVEL.HIGHSCHOOL_COMPLETED" | translate }}</mat-option>
            <mat-option value="College enrolled or equivalent">{{ "ED.EDUCATION_LEVEL.COLLEGE_ENROLLED" | translate }}</mat-option>
            <mat-option value="College in progress or equivalent">{{ "ED.EDUCATION_LEVEL.COLLEGE_PROGRESS" | translate }}</mat-option>
            <mat-option value="College completed or equivalent">{{ "ED.EDUCATION_LEVEL.COLLEGE_COMPLETED" | translate }}</mat-option>
            <mat-option value="Graduate school enrolled or equivalent">{{ "ED.EDUCATION_LEVEL.GRADUATEDSCHOOL_ENROLLED" | translate }}</mat-option>
            <mat-option value="Graduate school in progress or equivalent">{{ "ED.EDUCATION_LEVEL.GRADUATEDSCHOOL_PROGRESS" | translate }}</mat-option>
            <mat-option value="Graduate school completed or equivalent">{{ "ED.EDUCATION_LEVEL.GRADUATEDSCHOOL_COMPLETED" | translate }}</mat-option>
            <mat-option value="PhD enrolled or equivalent">{{ "ED.EDUCATION_LEVEL.PHD_ENROLLED" | translate }}</mat-option>
            <mat-option value="PhD in progress or equivalent">{{ "ED.EDUCATION_LEVEL.PHD_PROGRESS" | translate }}</mat-option>
            <mat-option value="PhD completed or equivalent">{{ "ED.EDUCATION_LEVEL.PHD_COMPLETED" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['highest_level_education'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>16. {{ "ED.RACE.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-checkbox formControlName="Chinese" (change)="checkRace()">{{ "ED.RACE.CHINESE" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="Japanese" (change)="checkRace()">{{ "ED.RACE.JAPANESE" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="Other_Asian" (change)="checkRace()">{{ "ED.RACE.OTHER_ASIAN" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="American_Indian_Alaska_Native" (change)="checkRace()">{{ "ED.RACE.NATIVE_AMERICAN" | translate }}</mat-checkbox><br>
        <!-- <mat-checkbox formControlName="Asian" (change)="checkRace()">{{ "ED.RACE.ASIAN" | translate }}</mat-checkbox><br> -->
        <mat-checkbox formControlName="Black" (change)="checkRace()">{{ "ED.RACE.BLACK" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="Latino" (change)="checkRace()">{{ "ED.RACE.LATINO" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="Native_Hawaiian_Pacific_Islander" (change)="checkRace()">{{ "ED.RACE.PACIFIC_ISLANDER" | translate }}</mat-checkbox><br>
        <mat-checkbox formControlName="Caucasian" (change)="checkRace()">{{ "ED.RACE.CAUCASIAN" | translate }}</mat-checkbox><br>
      </div>
    </tr>
    <br>
    <!-- <tr>
      <mat-label><b>16. {{ "ED.RACIAL_DESCRIPTION.TITLE" | translate }} </b></mat-label><br>
      <mat-form-field appearance="outline">
        <textarea matInput formControlName="racial_description" maxlength="5000" placeholder="{{ 'ED.RACIAL_DESCRIPTION.PLACEHOLDER' | translate }}"></textarea>
        <mat-error *ngIf="form.controls.racial_description.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
      </mat-form-field>
    </tr>
    <br> -->
    <tr>
      <mat-label><b>17. Body type </b></mat-label><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="body_type">
          <mat-option value="Slim">Slim</mat-option>
          <mat-option value="Average">Average</mat-option>
          <mat-option value="Petite">Petite</mat-option>
          <mat-option value="Curvy">Curvy</mat-option>
          <mat-option value="Athletic">Athletic</mat-option>
        </mat-select>
      </mat-form-field>
    </tr>
    <tr>
      <mat-label><b>18. Hair type </b></mat-label><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="hair_type">
          <mat-option value="Straight">Straight</mat-option>
          <mat-option value="Wavy">Wavy</mat-option>
          <mat-option value="Curly">Curly</mat-option>
          <mat-option value="Fine">Fine</mat-option>
          <mat-option value="Thick">Thick</mat-option>
        </mat-select>
      </mat-form-field>
    </tr>
    <tr>
      <mat-label><b>19. Complexion </b></mat-label><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="complexion">
          <mat-option value="Fair">Fair</mat-option>
          <mat-option value="Medium">Medium</mat-option>
          <mat-option value="Olive">Olive</mat-option>
          <mat-option value="Dark">Dark</mat-option>
        </mat-select>
      </mat-form-field>
    </tr>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>20. Dominant hand </b></mat-label><br><br>
        <mat-radio-group formControlName="dominant_hand">
          <mat-radio-button class="add-right-padding" value="Left">Left</mat-radio-button>
          <mat-radio-button class="add-right-padding" value="Right">Right</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>21. Vision </b></mat-label><br><br>
        <mat-radio-group formControlName="vision">
          <mat-radio-button class="add-right-padding" value="Excellent">Excellent</mat-radio-button><br><br>
          <mat-radio-button class="add-right-padding" value="Good">Good</mat-radio-button><br><br>
          <mat-radio-button class="add-right-padding" value="Fair">Fair</mat-radio-button><br><br>
          <mat-radio-button class="add-right-padding" value="Poor">Poor</mat-radio-button><br>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>22. Orthodontic (dental) work done </b></mat-label><br><br>
        <mat-radio-group formControlName="orthodontic_work">
          <mat-radio-button class="add-right-padding" value="Yes">Yes</mat-radio-button>
          <mat-radio-button class="add-right-padding" value="No">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>23. Baldness </b></mat-label><br><br>
        <mat-radio-group formControlName="baldness">
          <mat-radio-button class="add-right-padding" value="Yes">Yes</mat-radio-button>
          <mat-radio-button class="add-right-padding" value="No">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>24. {{ "ED.TRIBE.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="tribe" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>25. {{ "ED.PARENTAGE.TITLE" | translate }} </b></mat-label><br><br>
        <mat-radio-group formControlName="parentage">
            <mat-radio-button class="add-right-padding" value="Natural child of parents">{{ "ED.PARENTAGE.NATURAL_CHILD" | translate }}</mat-radio-button><br>
            <mat-radio-button class="add-right-padding" value="Adopted">{{ "ED.PARENTAGE.ADOPTED" | translate }}</mat-radio-button><br>
            <mat-radio-button class="add-right-padding" value="Donor conceived">{{ "ED.PARENTAGE.DONOR_CONCEIVED" | translate }}</mat-radio-button><br>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>26. {{ "ED.OVARIES.TITLE" | translate }} </b></mat-label><br><br>
        <mat-radio-group formControlName="ovaries">
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>25. Have you ever been a participant before? </b></mat-label><br><br>
        <mat-radio-group formControlName="egg_donor_before">
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>26. {{ "GENERAL.PREGNANT" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="pregnant" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>27. {{ "GENERAL.BREASTFEEDING" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="breastfeeding" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <td>
      <!-- <div class="add-bottom-padding"> -->
        <mat-label><b>28. {{ "ED.BIOLOGICAL_CHILDREN.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="bioChildren" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      <!-- </div> -->
    </td>

    </tr>
    <br>
    <!-- <tr>
      <mat-label><b>22. If yes, please list ages of children</b></mat-label><br><br>
      <mat-form-field appearance="outline">
        <mat-label>If yes, please list ages of children</mat-label>
        <textarea matInput formControlName="list_ages_children" maxlength="500"></textarea>
      </mat-form-field>
    </tr> -->
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>23. {{ "ED.TOBACCO.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="tobacco" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>31. {{ "ED.PAST_TWO_YEARS.TITLE" | translate }} </b></mat-label><br><br>
        <mat-radio-group formControlName="used">
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>25. {{ "ED.MEDICATIONS.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="medications" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <!-- <tr>
      <div class="add-bottom-padding">
        <mat-label><b>25. {{ "ED.ALCOHOLISM.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="alcoholism" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br> -->
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>29. {{ "ED.FELONY_YOU.TITLE" | translate }} </b></mat-label><font color=red>*</font><br><br>
        <mat-radio-group formControlName="felony" required>
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <br>
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>30. {{ "ED.COVID.TITLE" | translate }} </b></mat-label><br><br>
        <mat-radio-group formControlName="covid" [(ngModel)]="covid">
            <mat-radio-button class="add-right-padding" value="Yes">{{ "GENERAL.YES" | translate }}</mat-radio-button>
            <mat-radio-button class="add-right-padding" value="No">{{ "GENERAL.NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </tr>
    <tr *ngIf="covid=='Yes'">
      <div class="add-bottom-padding">
        <mat-label><b>{{ "ED.COVID_EXPLAIN.TITLE" | translate }} </b></mat-label><br>
        <mat-form-field appearance="outline">
          <input matInput formControlName="covid_explain" maxlength="50">
          <mat-error *ngIf="form.controls.covid_explain.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </tr>
    <br>
    <tr>
      <mat-label><b>31. {{ "ED.HEAR.TITLE" | translate }} </b></mat-label><font color=red>*</font><br>
      <mat-form-field appearance="outline">
        <mat-select formControlName="hear_about_us" [(ngModel)]="selectedHear" required>
          <mat-option value="Campus Newspaper">{{ "ED.HEAR.CAMPUS_NEWSPAPER" | translate }}</mat-option>
          <mat-option value="Car Ad">{{ "ED.HEAR.CAR_AD" | translate }}</mat-option>
          <mat-option value="Clubhouse">{{ "ED.HEAR.CLUBHOUSE" | translate }}</mat-option>
          <mat-option value="Donor Referral (Provide Name)">{{ "ED.HEAR.DONOR_REFERRAL" | translate }}</mat-option>
          <mat-option value="Facebook">{{ "ED.HEAR.FACEBOOK" | translate }}</mat-option>
          <mat-option value="Flyer on Campus">{{ "ED.HEAR.FLYER" | translate }}</mat-option>
          <mat-option value="Google Ad">{{ "ED.HEAR.GOOGLE_AD" | translate }}</mat-option>
          <mat-option value="Image Ad on a Website">{{ "ED.HEAR.IMAGE_AD" | translate }}</mat-option>
          <mat-option value="Instagram">{{ "ED.HEAR.INSTAGRAM" | translate }}</mat-option>
          <mat-option value="Internet Research">{{ "ED.HEAR.INTERNET_RESEARCH" | translate }}</mat-option>
          <mat-option value="IVF Clinic, if so, which one?">{{ "ED.HEAR.IVF_CLINIC" | translate }}</mat-option>
          <mat-option value="Personal Referral (Provide Name)">{{ "ED.HEAR.PERSONAL_REFERRAL" | translate }}</mat-option>
          <mat-option value="Podcast">{{ "ED.HEAR.PODCAST" | translate }}</mat-option>
          <mat-option value="Recruiter (Provide Name)">{{ "ED.HEAR.RECRUITER" | translate }}</mat-option>
          <mat-option value="TikTok">{{ "ED.HEAR.TIKTOK" | translate }}</mat-option>
          <mat-option value="Tumblr">{{ "ED.HEAR.TUMBLR" | translate }}</mat-option>
          <mat-option value="TV Ad">{{ "ED.HEAR.TV" | translate }}</mat-option>
          <mat-option value="WeChat">{{ "ED.HEAR.WECHAT" | translate }}</mat-option>
          <mat-option value="Other (Specify)">{{ "ED.HEAR.OTHER" | translate }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['hear_about_us'].errors?.required">{{ "GENERAL.REQUIRED" | translate }}</mat-error>
      </mat-form-field>
    </tr>
    <tr *ngIf="selectedHear=='Donor Referral (Provide Name)' || selectedHear=='Personal Referral (Provide Name)' || selectedHear=='Recruiter (Provide Name)' || selectedHear=='IVF Clinic, if so, which one?' || selectedHear=='Other (Specify)'">
        <mat-label><b>{{ "GENERAL.PROVIDE_NAMES" | translate }} </b></mat-label><br>
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="provide_names" maxlength="5000"></textarea>
          <mat-error *ngIf="form.controls.provide_names.hasError('maxlength')">{{ "GENERAL.MAX_LENGTH" | translate }}</mat-error>
        </mat-form-field>
    </tr>
    <br>
    <tr>
      <mat-label><b>32. {{ "ED.PHOTOS_VIDEO.NEED_PHOTOS_VIDEOS" | translate }} {{ "ED.PHOTOS_VIDEO.LOVE_PRESENT" | translate }} {{ "ED.PHOTOS_VIDEO.MORE" | translate }}</b></mat-label><font color=red>*</font><br><br>
    </tr>
    <!-- <ul class="desc">
      <li>{{ "ED.PHOTOS_VIDEO.NEED_PHOTOS_VIDEOS" | translate }}</li>
      <li>{{ "ED.PHOTOS_VIDEO.LOVE_PRESENT" | translate }}</li>
      <li>{{ "ED.PHOTOS_VIDEO.MORE" | translate }}</li>
    </ul> -->
    <div style="text-align: center;">
      <table width=90%>
        <tr align=center>
          <td width=13%> </td>
          <td align=center>
          <div *ngIf="buildGallery">
              <mat-spinner  diameter='20'></mat-spinner>
              <span> {{ "ED.PHOTOS_VIDEO.LOADING_IMAGE" | translate }} </span> 
          </div>
          <div *ngIf="nophotos">
            <span ><font color=green> {{ "ED.PHOTOS_VIDEO.NO_PHOTOS" | translate }} </font></span> 
         </div>
         <div *ngIf="galleryready" >
          <ks-modal-gallery [id]="100" [plainGalleryConfig]="galley_config" 
          [modalImages]="photoimages" [buttonsConfig]="customButtonsConfig" 
          [slideConfig]="{infinite: true, sidePreviews: {show: false}}"
          [enableCloseOutside]="false"
          [previewConfig]="{visible: true, number:4}"
          (buttonAfterHook)="onCustomButtonAfterHook($event)"></ks-modal-gallery>
         </div>
          </td>
        </tr>
        <tr>
          <td width=10%> </td>
          <td align=center>
            <img src="/assets/images/upload_photo.png" width="250px" height="130px" class="cursor" (click)="showAddPhotoDialog()">
          </td>
        </tr>
      </table>
      <div style="visibility:hidden;height:1pt"> <input matInput formControlName="photoes" maxlength="10" required></div> 
      <mat-label><font color=red><b>{{ "ED.PHOTOS_VIDEO.PLEASE_UPLOAD_3PHOTOS" | translate }}</b></font></mat-label><br><br>
      <!-- <img width="70%" src="/assets/images/photo_accept.jpg" />
      <img width="70%" src="/assets/images/photo_unaccept.png" /> -->
    </div>
    <br>
    <tr>
      <mat-label><b>33. {{ "ED.PHOTOS_VIDEO.SHORT_SELFIE" | translate }} {{ "ED.PHOTOS_VIDEO.START" | translate }} {{ "ED.PHOTOS_VIDEO.NOTE" | translate }}</b></mat-label><br><br>
    </tr>
    <!-- <ul>
      <li>{{ "ED.PHOTOS_VIDEO.SHORT_SELFIE" | translate }}</li>
      <li>{{ "ED.PHOTOS_VIDEO.START" | translate }}</li>
      <li>{{ "ED.PHOTOS_VIDEO.NOTE" | translate }}</li>
    </ul> -->
    <div style="text-align: center;">
      <img *ngIf='videos.length==0' src="assets/images/upload_video.png" width="250px" height="130px" class="cursor" (click)="showAddVideoDialog()">
      <ul class='pdfdoc' *ngIf='videos.length>0'>
        <li *ngFor="let vv of videos">
          <span class="badge">{{vv.seqNo}}</span>
          <span class="textname">{{vv.fileName}} - {{vv.size}} kb</span>
          <span>
            <mat-icon class='docicon' (click)="displayVideoTab(vv.seqNo)">featured_video</mat-icon>
            <mat-icon class='docicon' (click)="displayVideo(vv.seqNo)">visibility</mat-icon>
            <mat-icon class='docicon' (click)="deleteVideo(vv.seqNo)">delete</mat-icon>
          </span>
        </li>
      </ul>
      <video width="60%" controls autoplay *ngIf="showVideo">
        <source [src]="videourl"/>
      </video>
      <br>
      <mat-label><font color=red><b>{{ "ED.PHOTOS_VIDEO.PLEASE_LIMIT" | translate }}</b></font></mat-label><br><br>
      <input style="display: none;" matInput formControlName="videos">
    </div>
    <!-- <tr>
      <mat-label><b>36. Please explain</b></mat-label><br>
      <mat-form-field appearance="outline">
        <mat-label>Please explain</mat-label>
        <textarea matInput formControlName="explain_about_us" maxlength="5000"></textarea>
      </mat-form-field>
    </tr> -->
    <!-- <tr>
      <div>
        <mat-checkbox id="check" formControlName="check"></mat-checkbox>&nbsp;&nbsp;<mat-label><b>{{ "ED.CHECK_BOX.TITLE" | translate }}</b><font color=red>*</font></mat-label> 
      </div>
    </tr> -->
    <tr>
      <div class="add-bottom-padding">
        <mat-label><b>{{ "ED.AGREE_BOX.TITLE" | translate }} </b><font color=red>*</font></mat-label><br><br>
        <!-- <b>to this below. <font color=red>*</font><br><br></b> -->
        <mat-radio-group formControlName="agree" required>
            <mat-radio-button class="add-right-padding" value="Agree">{{ "ED.AGREE_BOX.AGREE" | translate }}</mat-radio-button>
            <!-- <mat-radio-button class="add-right-padding" value="Not Agree">{{ "ED.AGREE_BOX.DISAGREE" | translate }}</mat-radio-button> -->
            <!-- mat-radio-button class="add-right-padding" value="Not Agree">{{ "ED.AGREE_BOX.DISAGREE" | translate }}</mat-radio-button -->
        </mat-radio-group>
      </div>
    </tr>
    <br>
    </div>
    </table>

  <div class="button-row" *ngIf="first">
    <!-- <button mat-raised-button class="bottom_buttons" color="warn" type="save_progress" (click)='saveData()' [disabled]="form_progress.formstatus=='prescan-completed'">SAVE PROGRESS <mat-icon>save</mat-icon></button> -->
    <button *ngIf="!recordexists" mat-raised-button id="next_button" color="primary" class="next_button" type="button" (click)='saveTab1()' [(disabled)]='!tab1valid'>{{ "GENERAL.NEXT" | translate }} <mat-icon>arrow_forward_ios</mat-icon></button>
  <div *ngIf="recordexists">
    <font size=+1 color=green>{{message}}</font> <br>
    <button mat-raised-button color="primary" class="next_button" type="button" (click)='goForm2()'>{{"GENERAL.CONTINUE_APPLICATION" | translate}}</button>
  </div>
  </div>

  <div *ngIf="!first">
  <div class="button-row" *ngIf="!processSubmit">
    <!-- <button mat-raised-button class="bottom_buttons" color="warn" type="save_progress" (click)='saveData()' [disabled]="form_progress.formstatus=='prescan-completed'">SAVE PROGRESS <mat-icon>save</mat-icon></button> -->
    <button mat-raised-button class="bottom_buttons" color="primary" type="submit" [disabled]="(!form.valid || !raceChecked || photoimages.length < 2 )" (click)='finalize()'>{{ "GENERAL.SUBMIT" | translate }} <mat-icon>task_alt</mat-icon></button>
  </div>
  <div *ngIf="processSubmit" >
    <mat-spinner diameter='20'></mat-spinner>
    <span><font size=+1 color=green>{{ "GENERAL.PROCESSING" | translate }}</font></span> 
  </div>
  </div>
</form>
</div>
<br>