<br>
<div class="container" style="background:#ffffff;width:98%" >
    <div *ngIf="(!acknowledge)&&(!submitnow)&&(casedata.status=='prescanning-approved' || casedata.status=='empty')">
      <br>
      <form [formGroup]="form">
      <table width=98%>
        <tr>
          <td align=center><h1><b>{{"ED.APPROVAL.TITLE" | translate}}</b></h1></td>
        </tr>
        <tr>
          <td>
            <div style="padding: 12px 36px; font-size: 18px">
              <!-- Sharing more about yourself will significantly increase your chances of being selected for a no-wait banking cycle! Would you like to share more? -->
              Would you like to share more about yourself or you want to submit as it is?
            <!-- <font color=darkgreen>
              {{"ED.APPROVAL.CONGRATULATIONS" | translate}}
              <br><br>
              {{"GENERAL.CONFIRM_EMAIL" | translate}}
            </font> -->
            </div>
            <br>
          </td>
        </tr>
      <!-- <tr>
        <td>
          <br>
          <mat-label style="margin-left:20pt"><b> {{ "GENERAL.EMAIL" | translate }} </b></mat-label><font color=red>*</font><br>
          <mat-form-field appearance="outline">
            <input matInput formControlName="email" maxlength="50" placeholder="pat@example.com" required [disabled]='true'>
            <mat-error *ngIf="form.controls['email'].invalid">{{ "GENERAL.EMAIL_ERROR_FORMAT" | translate }}</mat-error>
          </mat-form-field>
        </td>
      </tr> -->
      <tr>
        <td align=center>
          <!-- <button mat-raised-button color="warn" type="submit" [disabled]="(!form.valid || submitted)" (click)='sendInvite()'>{{ "GENERAL.SEND_EMAIL" | translate }} <mat-icon>email</mat-icon></button> -->
          <button mat-raised-button color="primary" type="submit" style="margin-right: 10px;" (click)='continueForm2()'>Share More</button>
          <button mat-raised-button style="background-color: #55b954; color: white" type="submit" (click)='submitNow()'>Submit As It Is</button>
          <br>
        </td>
      </tr>
      </table>
    </form>
    <br>
    </div>
  <div class="postSend3" *ngIf="submitnow">
    <font color=#8a61a8>
      <p>Your submission is successful.</p>

      <p>Congratulations, you are pre-approved! Only 10% of applicants can get pre-approval and get on this exciting journey.</p>

      <p>For the next step, please call 123-123-1234 for a 10-minute interview; alternatively, our coordinator will reach out to you via call/text within the next 24 hours.</p>
      <!-- Thank you for your submission and your application is currently under review by Lucina. Within 72 hours, if you are approved for Banking cycle, a Lucina Coordinator will reach out to you for an Interview. If you are approved for the matching cycle, you will receive an email regarding the next steps. -->
    </font>
  </div>
  <div class="postSend2" *ngIf="acknowledge">
        <button mat-raised-button class="start_button" color="warn" type="submit" [disabled]="((inviteurl=='') || (inviteurl==null))" (click)='continueForm2()'><mat-icon>account_box</mat-icon>&nbsp;{{ "GENERAL.START_APPLICATION" | translate }}</button>
        <!-- <button mat-raised-button class="start_button" type="submit" [disabled]="((inviteurl=='') || (inviteurl==null))" (click)='continueForm2()'><mat-icon>account_box</mat-icon>&nbsp;{{ "GENERAL.START_APPLICATION" | translate }}</button>
        <br><br> -->
        <br><br>
          {{ "GENERAL.RECEIVE_EMAIL_1" | translate }}<font color=blue> service@everleagues.com</font> {{ "GENERAL.RECEIVE_EMAIL_2" | translate }}
          <br><br>
          <font color=red>{{ "GENERAL.CHECK_SPAM" | translate }}</font>  
          <br><br>
          <div style="font-size: 17px;">
            {{ "ED.APPROVAL.FINISH" | translate }} {{ "ED.APPROVAL.JOURNEY" | translate }} {{ "ED.APPROVAL.CONTACT" | translate }}
          </div>
        <br>
        <!-- <div mat-dialog-actions class="button-row" align=center>
          <button mat-raised-button class="close_button" color="warn" type="button" (click)="onClose()">{{ "GENERAL.CLOSE" | translate }} <mat-icon>cancel</mat-icon></button>
        </div> -->
  </div>
  <div class="postSend" *ngIf="(!acknowledge) &&(casedata.status=='prescanning-denied' && (!final))">
    <mat-dialog-content style="font-size: 17px;">
      <div align=center>
        <span *ngIf='reasons'>
          <span style="font-size: 19px; color: red;"><b>{{"ED.DENY.CONFIRMATION.TITLE" | translate }}</b></span><span *ngFor='let reason of reasons'>{{reason.questionId}}, </span>
        </span>
      </div>
      <!-- <br>
      <ul *ngIf='reasons'>
        <li *ngFor='let reason of reasons'>{{reason.questionId}}</li>
      </ul> -->
      <br>
      <div align=center>{{"ED.DENY.CONFIRMATION.BODY" | translate }}</div>
    </mat-dialog-content>
    <br>
    <div mat-dialog-actions class="button-row" align=center>
      <button mat-raised-button class="close_button" color="warn" type="button" (click)="onReview()">{{"ED.DENY.CONFIRMATION.REVIEW" | translate }}<mat-icon>preview</mat-icon></button>
      <button mat-raised-button class="close_button" color="primary" type="button" (click)="finalizeDeny()">{{"ED.DENY.CONFIRMATION.SUBMIT" | translate }}<mat-icon>task_alt</mat-icon></button>
    </div>
  </div>
  <div class="postSend" *ngIf="(!acknowledge) &&(casedata.status=='prescanning-denied' && final)">

    <mat-dialog-content>
      {{ "GENERAL.DEAR" | translate }} {{firstName}}, 
      <br><br>
  
      {{ "ED.DENY.INTRO" | translate }}
      <br><br>
      
      <!-- {{ "ED.DENY.INTEREST" | translate }} <a href="https://lucinaeggbank.com/lucina-donor-referral-program/"> {{ "GENERAL.HERE" | translate }}</a>
      <br><br> -->
    
      {{ "ED.DENY.APPRECIATE" | translate }}
      <br><br> 
  
      {{ "ED.DENY.SIGNATURE" | translate }}
      <br><br>
      <!-- <img src="../../../assets/images/LucinaLogoWhite.png" style="width: 90%;"> -->
    </mat-dialog-content>
    <br>
    <!-- <div mat-dialog-actions class="button-row" align=center>
      <button mat-raised-button class="close_button" color="warn" type="button" (click)="onClose()">{{ "GENERAL.CLOSE" | translate }} <mat-icon>cancel</mat-icon></button>
    </div> -->
  </div>
</div>
